@charset "UTF-8";
@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.woff2?s4dsoynxzz") format("woff2"), url("../fonts/icomoon.ttf?s4dsoynxzz") format("truetype"), url("../fonts/icomoon.woff?s4dsoynxzz") format("woff"), url("../fonts/icomoon.svg?s4dsoynxzz#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

.icomoon-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-email:before {
  content: "";
  color: #6d6d6d; }

.icon-fb:before {
  content: "";
  color: #fff; }

.icon-home-2:before {
  content: ""; }

.icon-print:before {
  content: ""; }

.icon-whatsapp2:before {
  content: "";
  color: #6d6d6d; }

.icon-x:before {
  content: "";
  color: #fff; }

.icon-twitter-x:before {
  content: ""; }

.icon-printer1:before {
  content: ""; }

.icon-home:before {
  content: ""; }

.icon-instagram1:before {
  content: ""; }

.icon-snapchat-ghost:before {
  content: ""; }

.icon-youtube:before {
  content: ""; }

.icon-camera:before {
  content: ""; }

.icon-whatsapp:before {
  content: ""; }

.icon-sphere:before {
  content: ""; }

.icon-telegram:before {
  content: ""; }

.icon-printer:before {
  content: ""; }

.icon-linkedin:before {
  content: ""; }

.icon-google-plus:before {
  content: ""; }

.icon-pinterest:before {
  content: ""; }

.icon-instagram:before {
  content: ""; }

.icon-whatsapp1:before {
  content: ""; }

.icon-dislike:before {
  content: ""; }

.icon-like:before {
  content: ""; }

.icon-envelope:before {
  content: ""; }

.icon-comment:before {
  content: ""; }

.icon-uk-flag:before {
  content: ""; }

.icon-us-flag:before {
  content: ""; }

.icon-google:before {
  content: ""; }

.icon-twitter:before {
  content: ""; }

.icon-facebook:before {
  content: ""; }

.icon-arrow-down2:before {
  content: ""; }

.icon-arrow-left2:before {
  content: ""; }

.icon-arrow-right2:before {
  content: ""; }

.icon-arrow-up2:before {
  content: ""; }

.icon-arrow-down:before {
  content: ""; }

.icon-arrow-left:before {
  content: ""; }

.icon-arrow-up:before {
  content: ""; }

.icon-arrow-right:before {
  content: ""; }

.icon-play:before {
  content: ""; }

.icon-close:before {
  content: ""; }

.icon-user:before {
  content: ""; }

.icon-hamburger:before {
  content: ""; }

.icon-search:before {
  content: ""; }

.icon-sound-wave:before {
  content: ""; }

@font-face {
  font-family: "Independent Serif";
  src: url("../fonts/Independent-Serif-Regular.woff2") format("woff2"), url("../fonts/Independent-Serif-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Independent Serif";
  src: url("../fonts/Independent-Serif-Regular-Italic.woff2") format("woff2"), url("../fonts/Independent-Serif-Regular-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: "Independent Serif";
  src: url("../fonts/Independent-Serif-Medium.woff2") format("woff2"), url("../fonts/Independent-Serif-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "Independent Serif";
  src: url("../fonts/Independent-Serif-Medium-Italic.woff2") format("woff2"), url("../fonts/Independent-Serif-Medium-Italic.woff") format("woff");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: "Independent Serif";
  src: url("../fonts/Independent-Serif-Bold.woff2") format("woff2"), url("../fonts/Independent-Serif-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "Independent Serif";
  src: url("../fonts/Independent-Serif-Bold-Italic.woff2") format("woff2"), url("../fonts/Independent-Serif-Bold-Italic.woff") format("woff");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: "Independent Serif";
  src: url("../fonts/Independent-Serif-Light.woff2") format("woff2"), url("../fonts/Independent-Serif-Light.woff") format("woff");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: "Independent Serif";
  src: url("../fonts/Independent-Serif-Light-Italic.woff2") format("woff2"), url("../fonts/Independent-Serif-Light-Italic.woff") format("woff");
  font-style: italic;
  font-weight: 400; }

@font-face {
  font-family: "Independent Sans";
  src: url("../fonts/Independent-Sans-Regular.woff2") format("woff2"), url("../fonts/Independent-Sans-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Independent Sans";
  src: url("../fonts/Independent-Sans-Light.woff2") format("woff2"), url("../fonts/Independent-Sans-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Independent Sans";
  src: url("../fonts/Independent-Sans-Medium.woff2") format("woff2"), url("../fonts/Independent-Sans-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "Independent Sans";
  src: url("../fonts/Independent-Sans-Bold.woff2") format("woff2"), url("../fonts/Independent-Sans-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "Independent Sans";
  src: url("../fonts/Independent-Sans-Black.woff2") format("woff2"), url("../fonts/Independent-Sans-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal; }

/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=48em&large=64em&xlarge=75em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #ffffff;
  font-family: "Independent Serif", Tahoma, Helvetica, Arial, sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #281E1E;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: pointer; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.row {
  max-width: 61.5rem;
  margin-right: auto;
  margin-left: auto; }
  .row::before, .row::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .row::after {
    clear: both; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem; }
    @media print, screen and (min-width: 48em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    @media print, screen and (min-width: 64em) {
      .row .row {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.gutter-small > .column, .row.gutter-small > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem; }
  .row.gutter-medium > .column, .row.gutter-medium > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }

.column, .columns {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 48em) {
    .column, .columns {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  .column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
    float: right; }
  .column.end:last-child:last-child, .end.columns:last-child:last-child {
    float: left; }

.column.row.row, .row.row.columns {
  float: none; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  width: 8.33333%; }

.small-push-1 {
  position: relative;
  left: 8.33333%; }

.small-pull-1 {
  position: relative;
  left: -8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  width: 16.66667%; }

.small-push-2 {
  position: relative;
  left: 16.66667%; }

.small-pull-2 {
  position: relative;
  left: -16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  width: 25%; }

.small-push-3 {
  position: relative;
  left: 25%; }

.small-pull-3 {
  position: relative;
  left: -25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  width: 33.33333%; }

.small-push-4 {
  position: relative;
  left: 33.33333%; }

.small-pull-4 {
  position: relative;
  left: -33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  width: 41.66667%; }

.small-push-5 {
  position: relative;
  left: 41.66667%; }

.small-pull-5 {
  position: relative;
  left: -41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  width: 50%; }

.small-push-6 {
  position: relative;
  left: 50%; }

.small-pull-6 {
  position: relative;
  left: -50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  width: 58.33333%; }

.small-push-7 {
  position: relative;
  left: 58.33333%; }

.small-pull-7 {
  position: relative;
  left: -58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  width: 66.66667%; }

.small-push-8 {
  position: relative;
  left: 66.66667%; }

.small-pull-8 {
  position: relative;
  left: -66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  width: 75%; }

.small-push-9 {
  position: relative;
  left: 75%; }

.small-pull-9 {
  position: relative;
  left: -75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  width: 83.33333%; }

.small-push-10 {
  position: relative;
  left: 83.33333%; }

.small-pull-10 {
  position: relative;
  left: -83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  width: 91.66667%; }

.small-push-11 {
  position: relative;
  left: 91.66667%; }

.small-pull-11 {
  position: relative;
  left: -91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-up-1 > .column, .small-up-1 > .columns {
  float: left;
  width: 100%; }
  .small-up-1 > .column:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-1 > .column:nth-of-type(1n+1), .small-up-1 > .columns:nth-of-type(1n+1) {
    clear: both; }
  .small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
    float: left; }

.small-up-2 > .column, .small-up-2 > .columns {
  float: left;
  width: 50%; }
  .small-up-2 > .column:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > .columns:nth-of-type(2n+1) {
    clear: both; }
  .small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
    float: left; }

.small-up-3 > .column, .small-up-3 > .columns {
  float: left;
  width: 33.33333%; }
  .small-up-3 > .column:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
    clear: both; }
  .small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
    float: left; }

.small-up-4 > .column, .small-up-4 > .columns {
  float: left;
  width: 25%; }
  .small-up-4 > .column:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
    clear: both; }
  .small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
    float: left; }

.small-up-5 > .column, .small-up-5 > .columns {
  float: left;
  width: 20%; }
  .small-up-5 > .column:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
    clear: both; }
  .small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
    float: left; }

.small-up-6 > .column, .small-up-6 > .columns {
  float: left;
  width: 16.66667%; }
  .small-up-6 > .column:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
    clear: both; }
  .small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
    float: left; }

.small-up-7 > .column, .small-up-7 > .columns {
  float: left;
  width: 14.28571%; }
  .small-up-7 > .column:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
    clear: both; }
  .small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
    float: left; }

.small-up-8 > .column, .small-up-8 > .columns {
  float: left;
  width: 12.5%; }
  .small-up-8 > .column:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
    clear: both; }
  .small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
    float: left; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-collapse .row {
  margin-right: 0;
  margin-left: 0; }

.expanded.row .small-collapse.row {
  margin-right: 0;
  margin-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem; }

.small-centered {
  margin-right: auto;
  margin-left: auto; }
  .small-centered, .small-centered:last-child:not(:first-child) {
    float: none;
    clear: both; }

.small-uncentered,
.small-push-0,
.small-pull-0 {
  position: static;
  float: left;
  margin-right: 0;
  margin-left: 0; }

@media print, screen and (min-width: 48em) {
  .medium-1 {
    width: 8.33333%; }
  .medium-push-1 {
    position: relative;
    left: 8.33333%; }
  .medium-pull-1 {
    position: relative;
    left: -8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    width: 16.66667%; }
  .medium-push-2 {
    position: relative;
    left: 16.66667%; }
  .medium-pull-2 {
    position: relative;
    left: -16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    width: 25%; }
  .medium-push-3 {
    position: relative;
    left: 25%; }
  .medium-pull-3 {
    position: relative;
    left: -25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    width: 33.33333%; }
  .medium-push-4 {
    position: relative;
    left: 33.33333%; }
  .medium-pull-4 {
    position: relative;
    left: -33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    width: 41.66667%; }
  .medium-push-5 {
    position: relative;
    left: 41.66667%; }
  .medium-pull-5 {
    position: relative;
    left: -41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    width: 50%; }
  .medium-push-6 {
    position: relative;
    left: 50%; }
  .medium-pull-6 {
    position: relative;
    left: -50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    width: 58.33333%; }
  .medium-push-7 {
    position: relative;
    left: 58.33333%; }
  .medium-pull-7 {
    position: relative;
    left: -58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    width: 66.66667%; }
  .medium-push-8 {
    position: relative;
    left: 66.66667%; }
  .medium-pull-8 {
    position: relative;
    left: -66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    width: 75%; }
  .medium-push-9 {
    position: relative;
    left: 75%; }
  .medium-pull-9 {
    position: relative;
    left: -75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    width: 83.33333%; }
  .medium-push-10 {
    position: relative;
    left: 83.33333%; }
  .medium-pull-10 {
    position: relative;
    left: -83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    width: 91.66667%; }
  .medium-push-11 {
    position: relative;
    left: 91.66667%; }
  .medium-pull-11 {
    position: relative;
    left: -91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    float: left;
    width: 100%; }
    .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
      float: left; }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    float: left;
    width: 50%; }
    .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
      float: left; }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
      float: left; }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    float: left;
    width: 25%; }
    .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
      float: left; }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    float: left;
    width: 20%; }
    .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
      float: left; }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
      float: left; }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
      float: left; }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
      float: left; }
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .medium-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .medium-centered {
    margin-right: auto;
    margin-left: auto; }
    .medium-centered, .medium-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .medium-uncentered,
  .medium-push-0,
  .medium-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-1 {
    width: 8.33333%; }
  .large-push-1 {
    position: relative;
    left: 8.33333%; }
  .large-pull-1 {
    position: relative;
    left: -8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    width: 16.66667%; }
  .large-push-2 {
    position: relative;
    left: 16.66667%; }
  .large-pull-2 {
    position: relative;
    left: -16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    width: 25%; }
  .large-push-3 {
    position: relative;
    left: 25%; }
  .large-pull-3 {
    position: relative;
    left: -25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    width: 33.33333%; }
  .large-push-4 {
    position: relative;
    left: 33.33333%; }
  .large-pull-4 {
    position: relative;
    left: -33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    width: 41.66667%; }
  .large-push-5 {
    position: relative;
    left: 41.66667%; }
  .large-pull-5 {
    position: relative;
    left: -41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    width: 50%; }
  .large-push-6 {
    position: relative;
    left: 50%; }
  .large-pull-6 {
    position: relative;
    left: -50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    width: 58.33333%; }
  .large-push-7 {
    position: relative;
    left: 58.33333%; }
  .large-pull-7 {
    position: relative;
    left: -58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    width: 66.66667%; }
  .large-push-8 {
    position: relative;
    left: 66.66667%; }
  .large-pull-8 {
    position: relative;
    left: -66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    width: 75%; }
  .large-push-9 {
    position: relative;
    left: 75%; }
  .large-pull-9 {
    position: relative;
    left: -75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    width: 83.33333%; }
  .large-push-10 {
    position: relative;
    left: 83.33333%; }
  .large-pull-10 {
    position: relative;
    left: -83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    width: 91.66667%; }
  .large-push-11 {
    position: relative;
    left: 91.66667%; }
  .large-pull-11 {
    position: relative;
    left: -91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-up-1 > .column, .large-up-1 > .columns {
    float: left;
    width: 100%; }
    .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
      float: left; }
  .large-up-2 > .column, .large-up-2 > .columns {
    float: left;
    width: 50%; }
    .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
      float: left; }
  .large-up-3 > .column, .large-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
      float: left; }
  .large-up-4 > .column, .large-up-4 > .columns {
    float: left;
    width: 25%; }
    .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
      float: left; }
  .large-up-5 > .column, .large-up-5 > .columns {
    float: left;
    width: 20%; }
    .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
      float: left; }
  .large-up-6 > .column, .large-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
      float: left; }
  .large-up-7 > .column, .large-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
      float: left; }
  .large-up-8 > .column, .large-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
      float: left; }
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .large-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem; }
  .large-centered {
    margin-right: auto;
    margin-left: auto; }
    .large-centered, .large-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .large-uncentered,
  .large-push-0,
  .large-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

.column-block {
  margin-bottom: 1.25rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 48em) {
    .column-block {
      margin-bottom: 1.875rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

.grid-container {
  padding-right: 0.3125rem;
  padding-left: 0.3125rem;
  max-width: 61.5rem;
  margin: 0 auto; }
  @media print, screen and (min-width: 48em) {
    .grid-container {
      padding-right: 0.375rem;
      padding-left: 0.375rem; } }
  @media print, screen and (min-width: 64em) {
    .grid-container {
      padding-right: 0.5rem;
      padding-left: 0.5rem; } }
  .grid-container.fluid {
    padding-right: 0.3125rem;
    padding-left: 0.3125rem;
    max-width: 100%;
    margin: 0 auto; }
    @media print, screen and (min-width: 48em) {
      .grid-container.fluid {
        padding-right: 0.375rem;
        padding-left: 0.375rem; } }
    @media print, screen and (min-width: 64em) {
      .grid-container.fluid {
        padding-right: 0.5rem;
        padding-left: 0.5rem; } }
  .grid-container.full {
    padding-right: 0;
    padding-left: 0;
    max-width: 100%;
    margin: 0 auto; }

.grid-x {
  display: flex;
  flex-flow: row wrap; }

.cell {
  flex: 0 0 auto;
  min-height: 0px;
  min-width: 0px;
  width: 100%; }
  .cell.auto {
    flex: 1 1 0px; }
  .cell.shrink {
    flex: 0 0 auto; }

.grid-x > .auto {
  width: auto; }

.grid-x > .shrink {
  width: auto; }

.grid-x > .small-shrink, .grid-x > .small-full, .grid-x > .small-1, .grid-x > .small-2, .grid-x > .small-3, .grid-x > .small-4, .grid-x > .small-5, .grid-x > .small-6, .grid-x > .small-7, .grid-x > .small-8, .grid-x > .small-9, .grid-x > .small-10, .grid-x > .small-11, .grid-x > .small-12 {
  flex-basis: auto; }

@media print, screen and (min-width: 48em) {
  .grid-x > .medium-shrink, .grid-x > .medium-full, .grid-x > .medium-1, .grid-x > .medium-2, .grid-x > .medium-3, .grid-x > .medium-4, .grid-x > .medium-5, .grid-x > .medium-6, .grid-x > .medium-7, .grid-x > .medium-8, .grid-x > .medium-9, .grid-x > .medium-10, .grid-x > .medium-11, .grid-x > .medium-12 {
    flex-basis: auto; } }

@media print, screen and (min-width: 64em) {
  .grid-x > .large-shrink, .grid-x > .large-full, .grid-x > .large-1, .grid-x > .large-2, .grid-x > .large-3, .grid-x > .large-4, .grid-x > .large-5, .grid-x > .large-6, .grid-x > .large-7, .grid-x > .large-8, .grid-x > .large-9, .grid-x > .large-10, .grid-x > .large-11, .grid-x > .large-12 {
    flex-basis: auto; } }

.grid-x > .small-1 {
  width: 8.33333%; }

.grid-x > .small-2 {
  width: 16.66667%; }

.grid-x > .small-3 {
  width: 25%; }

.grid-x > .small-4 {
  width: 33.33333%; }

.grid-x > .small-5 {
  width: 41.66667%; }

.grid-x > .small-6 {
  width: 50%; }

.grid-x > .small-7 {
  width: 58.33333%; }

.grid-x > .small-8 {
  width: 66.66667%; }

.grid-x > .small-9 {
  width: 75%; }

.grid-x > .small-10 {
  width: 83.33333%; }

.grid-x > .small-11 {
  width: 91.66667%; }

.grid-x > .small-12 {
  width: 100%; }

@media print, screen and (min-width: 48em) {
  .grid-x > .medium-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .medium-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .medium-1 {
    width: 8.33333%; }
  .grid-x > .medium-2 {
    width: 16.66667%; }
  .grid-x > .medium-3 {
    width: 25%; }
  .grid-x > .medium-4 {
    width: 33.33333%; }
  .grid-x > .medium-5 {
    width: 41.66667%; }
  .grid-x > .medium-6 {
    width: 50%; }
  .grid-x > .medium-7 {
    width: 58.33333%; }
  .grid-x > .medium-8 {
    width: 66.66667%; }
  .grid-x > .medium-9 {
    width: 75%; }
  .grid-x > .medium-10 {
    width: 83.33333%; }
  .grid-x > .medium-11 {
    width: 91.66667%; }
  .grid-x > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .grid-x > .large-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .large-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .large-1 {
    width: 8.33333%; }
  .grid-x > .large-2 {
    width: 16.66667%; }
  .grid-x > .large-3 {
    width: 25%; }
  .grid-x > .large-4 {
    width: 33.33333%; }
  .grid-x > .large-5 {
    width: 41.66667%; }
  .grid-x > .large-6 {
    width: 50%; }
  .grid-x > .large-7 {
    width: 58.33333%; }
  .grid-x > .large-8 {
    width: 66.66667%; }
  .grid-x > .large-9 {
    width: 75%; }
  .grid-x > .large-10 {
    width: 83.33333%; }
  .grid-x > .large-11 {
    width: 91.66667%; }
  .grid-x > .large-12 {
    width: 100%; } }

.grid-margin-x:not(.grid-x) > .cell {
  width: auto; }

.grid-margin-y:not(.grid-y) > .cell {
  height: auto; }

.grid-margin-x {
  margin-left: -0.3125rem;
  margin-right: -0.3125rem; }
  @media print, screen and (min-width: 48em) {
    .grid-margin-x {
      margin-left: -0.375rem;
      margin-right: -0.375rem; } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-x {
      margin-left: -0.5rem;
      margin-right: -0.5rem; } }
  .grid-margin-x > .cell {
    width: calc(100% - 0.625rem);
    margin-left: 0.3125rem;
    margin-right: 0.3125rem; }
  @media print, screen and (min-width: 48em) {
    .grid-margin-x > .cell {
      width: calc(100% - 0.75rem);
      margin-left: 0.375rem;
      margin-right: 0.375rem; } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-x > .cell {
      width: calc(100% - 1rem);
      margin-left: 0.5rem;
      margin-right: 0.5rem; } }
  .grid-margin-x > .auto {
    width: auto; }
  .grid-margin-x > .shrink {
    width: auto; }
  .grid-margin-x > .small-1 {
    width: calc(8.33333% - 0.625rem); }
  .grid-margin-x > .small-2 {
    width: calc(16.66667% - 0.625rem); }
  .grid-margin-x > .small-3 {
    width: calc(25% - 0.625rem); }
  .grid-margin-x > .small-4 {
    width: calc(33.33333% - 0.625rem); }
  .grid-margin-x > .small-5 {
    width: calc(41.66667% - 0.625rem); }
  .grid-margin-x > .small-6 {
    width: calc(50% - 0.625rem); }
  .grid-margin-x > .small-7 {
    width: calc(58.33333% - 0.625rem); }
  .grid-margin-x > .small-8 {
    width: calc(66.66667% - 0.625rem); }
  .grid-margin-x > .small-9 {
    width: calc(75% - 0.625rem); }
  .grid-margin-x > .small-10 {
    width: calc(83.33333% - 0.625rem); }
  .grid-margin-x > .small-11 {
    width: calc(91.66667% - 0.625rem); }
  .grid-margin-x > .small-12 {
    width: calc(100% - 0.625rem); }
  @media print, screen and (min-width: 48em) {
    .grid-margin-x > .auto {
      width: auto; }
    .grid-margin-x > .shrink {
      width: auto; }
    .grid-margin-x > .small-1 {
      width: calc(8.33333% - 0.75rem); }
    .grid-margin-x > .small-2 {
      width: calc(16.66667% - 0.75rem); }
    .grid-margin-x > .small-3 {
      width: calc(25% - 0.75rem); }
    .grid-margin-x > .small-4 {
      width: calc(33.33333% - 0.75rem); }
    .grid-margin-x > .small-5 {
      width: calc(41.66667% - 0.75rem); }
    .grid-margin-x > .small-6 {
      width: calc(50% - 0.75rem); }
    .grid-margin-x > .small-7 {
      width: calc(58.33333% - 0.75rem); }
    .grid-margin-x > .small-8 {
      width: calc(66.66667% - 0.75rem); }
    .grid-margin-x > .small-9 {
      width: calc(75% - 0.75rem); }
    .grid-margin-x > .small-10 {
      width: calc(83.33333% - 0.75rem); }
    .grid-margin-x > .small-11 {
      width: calc(91.66667% - 0.75rem); }
    .grid-margin-x > .small-12 {
      width: calc(100% - 0.75rem); }
    .grid-margin-x > .medium-auto {
      width: auto; }
    .grid-margin-x > .medium-shrink {
      width: auto; }
    .grid-margin-x > .medium-1 {
      width: calc(8.33333% - 0.75rem); }
    .grid-margin-x > .medium-2 {
      width: calc(16.66667% - 0.75rem); }
    .grid-margin-x > .medium-3 {
      width: calc(25% - 0.75rem); }
    .grid-margin-x > .medium-4 {
      width: calc(33.33333% - 0.75rem); }
    .grid-margin-x > .medium-5 {
      width: calc(41.66667% - 0.75rem); }
    .grid-margin-x > .medium-6 {
      width: calc(50% - 0.75rem); }
    .grid-margin-x > .medium-7 {
      width: calc(58.33333% - 0.75rem); }
    .grid-margin-x > .medium-8 {
      width: calc(66.66667% - 0.75rem); }
    .grid-margin-x > .medium-9 {
      width: calc(75% - 0.75rem); }
    .grid-margin-x > .medium-10 {
      width: calc(83.33333% - 0.75rem); }
    .grid-margin-x > .medium-11 {
      width: calc(91.66667% - 0.75rem); }
    .grid-margin-x > .medium-12 {
      width: calc(100% - 0.75rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-x > .auto {
      width: auto; }
    .grid-margin-x > .shrink {
      width: auto; }
    .grid-margin-x > .small-1 {
      width: calc(8.33333% - 1rem); }
    .grid-margin-x > .small-2 {
      width: calc(16.66667% - 1rem); }
    .grid-margin-x > .small-3 {
      width: calc(25% - 1rem); }
    .grid-margin-x > .small-4 {
      width: calc(33.33333% - 1rem); }
    .grid-margin-x > .small-5 {
      width: calc(41.66667% - 1rem); }
    .grid-margin-x > .small-6 {
      width: calc(50% - 1rem); }
    .grid-margin-x > .small-7 {
      width: calc(58.33333% - 1rem); }
    .grid-margin-x > .small-8 {
      width: calc(66.66667% - 1rem); }
    .grid-margin-x > .small-9 {
      width: calc(75% - 1rem); }
    .grid-margin-x > .small-10 {
      width: calc(83.33333% - 1rem); }
    .grid-margin-x > .small-11 {
      width: calc(91.66667% - 1rem); }
    .grid-margin-x > .small-12 {
      width: calc(100% - 1rem); }
    .grid-margin-x > .medium-auto {
      width: auto; }
    .grid-margin-x > .medium-shrink {
      width: auto; }
    .grid-margin-x > .medium-1 {
      width: calc(8.33333% - 1rem); }
    .grid-margin-x > .medium-2 {
      width: calc(16.66667% - 1rem); }
    .grid-margin-x > .medium-3 {
      width: calc(25% - 1rem); }
    .grid-margin-x > .medium-4 {
      width: calc(33.33333% - 1rem); }
    .grid-margin-x > .medium-5 {
      width: calc(41.66667% - 1rem); }
    .grid-margin-x > .medium-6 {
      width: calc(50% - 1rem); }
    .grid-margin-x > .medium-7 {
      width: calc(58.33333% - 1rem); }
    .grid-margin-x > .medium-8 {
      width: calc(66.66667% - 1rem); }
    .grid-margin-x > .medium-9 {
      width: calc(75% - 1rem); }
    .grid-margin-x > .medium-10 {
      width: calc(83.33333% - 1rem); }
    .grid-margin-x > .medium-11 {
      width: calc(91.66667% - 1rem); }
    .grid-margin-x > .medium-12 {
      width: calc(100% - 1rem); }
    .grid-margin-x > .large-auto {
      width: auto; }
    .grid-margin-x > .large-shrink {
      width: auto; }
    .grid-margin-x > .large-1 {
      width: calc(8.33333% - 1rem); }
    .grid-margin-x > .large-2 {
      width: calc(16.66667% - 1rem); }
    .grid-margin-x > .large-3 {
      width: calc(25% - 1rem); }
    .grid-margin-x > .large-4 {
      width: calc(33.33333% - 1rem); }
    .grid-margin-x > .large-5 {
      width: calc(41.66667% - 1rem); }
    .grid-margin-x > .large-6 {
      width: calc(50% - 1rem); }
    .grid-margin-x > .large-7 {
      width: calc(58.33333% - 1rem); }
    .grid-margin-x > .large-8 {
      width: calc(66.66667% - 1rem); }
    .grid-margin-x > .large-9 {
      width: calc(75% - 1rem); }
    .grid-margin-x > .large-10 {
      width: calc(83.33333% - 1rem); }
    .grid-margin-x > .large-11 {
      width: calc(91.66667% - 1rem); }
    .grid-margin-x > .large-12 {
      width: calc(100% - 1rem); } }

.grid-padding-x .grid-padding-x {
  margin-right: -0.3125rem;
  margin-left: -0.3125rem; }
  @media print, screen and (min-width: 48em) {
    .grid-padding-x .grid-padding-x {
      margin-right: -0.375rem;
      margin-left: -0.375rem; } }
  @media print, screen and (min-width: 64em) {
    .grid-padding-x .grid-padding-x {
      margin-right: -0.5rem;
      margin-left: -0.5rem; } }

.grid-container:not(.full) > .grid-padding-x {
  margin-right: -0.3125rem;
  margin-left: -0.3125rem; }
  @media print, screen and (min-width: 48em) {
    .grid-container:not(.full) > .grid-padding-x {
      margin-right: -0.375rem;
      margin-left: -0.375rem; } }
  @media print, screen and (min-width: 64em) {
    .grid-container:not(.full) > .grid-padding-x {
      margin-right: -0.5rem;
      margin-left: -0.5rem; } }

.grid-padding-x > .cell {
  padding-right: 0.3125rem;
  padding-left: 0.3125rem; }
  @media print, screen and (min-width: 48em) {
    .grid-padding-x > .cell {
      padding-right: 0.375rem;
      padding-left: 0.375rem; } }
  @media print, screen and (min-width: 64em) {
    .grid-padding-x > .cell {
      padding-right: 0.5rem;
      padding-left: 0.5rem; } }

.small-up-1 > .cell {
  width: 100%; }

.small-up-2 > .cell {
  width: 50%; }

.small-up-3 > .cell {
  width: 33.33333%; }

.small-up-4 > .cell {
  width: 25%; }

.small-up-5 > .cell {
  width: 20%; }

.small-up-6 > .cell {
  width: 16.66667%; }

.small-up-7 > .cell {
  width: 14.28571%; }

.small-up-8 > .cell {
  width: 12.5%; }

@media print, screen and (min-width: 48em) {
  .medium-up-1 > .cell {
    width: 100%; }
  .medium-up-2 > .cell {
    width: 50%; }
  .medium-up-3 > .cell {
    width: 33.33333%; }
  .medium-up-4 > .cell {
    width: 25%; }
  .medium-up-5 > .cell {
    width: 20%; }
  .medium-up-6 > .cell {
    width: 16.66667%; }
  .medium-up-7 > .cell {
    width: 14.28571%; }
  .medium-up-8 > .cell {
    width: 12.5%; } }

@media print, screen and (min-width: 64em) {
  .large-up-1 > .cell {
    width: 100%; }
  .large-up-2 > .cell {
    width: 50%; }
  .large-up-3 > .cell {
    width: 33.33333%; }
  .large-up-4 > .cell {
    width: 25%; }
  .large-up-5 > .cell {
    width: 20%; }
  .large-up-6 > .cell {
    width: 16.66667%; }
  .large-up-7 > .cell {
    width: 14.28571%; }
  .large-up-8 > .cell {
    width: 12.5%; } }

.grid-margin-x.small-up-1 > .cell {
  width: calc(100% - 0.625rem); }

.grid-margin-x.small-up-2 > .cell {
  width: calc(50% - 0.625rem); }

.grid-margin-x.small-up-3 > .cell {
  width: calc(33.33333% - 0.625rem); }

.grid-margin-x.small-up-4 > .cell {
  width: calc(25% - 0.625rem); }

.grid-margin-x.small-up-5 > .cell {
  width: calc(20% - 0.625rem); }

.grid-margin-x.small-up-6 > .cell {
  width: calc(16.66667% - 0.625rem); }

.grid-margin-x.small-up-7 > .cell {
  width: calc(14.28571% - 0.625rem); }

.grid-margin-x.small-up-8 > .cell {
  width: calc(12.5% - 0.625rem); }

@media print, screen and (min-width: 48em) {
  .grid-margin-x.small-up-1 > .cell {
    width: calc(100% - 0.625rem); }
  .grid-margin-x.small-up-2 > .cell {
    width: calc(50% - 0.625rem); }
  .grid-margin-x.small-up-3 > .cell {
    width: calc(33.33333% - 0.625rem); }
  .grid-margin-x.small-up-4 > .cell {
    width: calc(25% - 0.625rem); }
  .grid-margin-x.small-up-5 > .cell {
    width: calc(20% - 0.625rem); }
  .grid-margin-x.small-up-6 > .cell {
    width: calc(16.66667% - 0.625rem); }
  .grid-margin-x.small-up-7 > .cell {
    width: calc(14.28571% - 0.625rem); }
  .grid-margin-x.small-up-8 > .cell {
    width: calc(12.5% - 0.625rem); }
  .grid-margin-x.medium-up-1 > .cell {
    width: calc(100% - 0.75rem); }
  .grid-margin-x.medium-up-2 > .cell {
    width: calc(50% - 0.75rem); }
  .grid-margin-x.medium-up-3 > .cell {
    width: calc(33.33333% - 0.75rem); }
  .grid-margin-x.medium-up-4 > .cell {
    width: calc(25% - 0.75rem); }
  .grid-margin-x.medium-up-5 > .cell {
    width: calc(20% - 0.75rem); }
  .grid-margin-x.medium-up-6 > .cell {
    width: calc(16.66667% - 0.75rem); }
  .grid-margin-x.medium-up-7 > .cell {
    width: calc(14.28571% - 0.75rem); }
  .grid-margin-x.medium-up-8 > .cell {
    width: calc(12.5% - 0.75rem); } }

@media print, screen and (min-width: 64em) {
  .grid-margin-x.small-up-1 > .cell {
    width: calc(100% - 0.625rem); }
  .grid-margin-x.medium-up-1 > .cell {
    width: calc(100% - 0.75rem); }
  .grid-margin-x.small-up-2 > .cell {
    width: calc(50% - 0.625rem); }
  .grid-margin-x.medium-up-2 > .cell {
    width: calc(50% - 0.75rem); }
  .grid-margin-x.small-up-3 > .cell {
    width: calc(33.33333% - 0.625rem); }
  .grid-margin-x.medium-up-3 > .cell {
    width: calc(33.33333% - 0.75rem); }
  .grid-margin-x.small-up-4 > .cell {
    width: calc(25% - 0.625rem); }
  .grid-margin-x.medium-up-4 > .cell {
    width: calc(25% - 0.75rem); }
  .grid-margin-x.small-up-5 > .cell {
    width: calc(20% - 0.625rem); }
  .grid-margin-x.medium-up-5 > .cell {
    width: calc(20% - 0.75rem); }
  .grid-margin-x.small-up-6 > .cell {
    width: calc(16.66667% - 0.625rem); }
  .grid-margin-x.medium-up-6 > .cell {
    width: calc(16.66667% - 0.75rem); }
  .grid-margin-x.small-up-7 > .cell {
    width: calc(14.28571% - 0.625rem); }
  .grid-margin-x.medium-up-7 > .cell {
    width: calc(14.28571% - 0.75rem); }
  .grid-margin-x.small-up-8 > .cell {
    width: calc(12.5% - 0.625rem); }
  .grid-margin-x.medium-up-8 > .cell {
    width: calc(12.5% - 0.75rem); }
  .grid-margin-x.large-up-1 > .cell {
    width: calc(100% - 1rem); }
  .grid-margin-x.large-up-2 > .cell {
    width: calc(50% - 1rem); }
  .grid-margin-x.large-up-3 > .cell {
    width: calc(33.33333% - 1rem); }
  .grid-margin-x.large-up-4 > .cell {
    width: calc(25% - 1rem); }
  .grid-margin-x.large-up-5 > .cell {
    width: calc(20% - 1rem); }
  .grid-margin-x.large-up-6 > .cell {
    width: calc(16.66667% - 1rem); }
  .grid-margin-x.large-up-7 > .cell {
    width: calc(14.28571% - 1rem); }
  .grid-margin-x.large-up-8 > .cell {
    width: calc(12.5% - 1rem); } }

.small-margin-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-margin-collapse > .cell {
    margin-right: 0;
    margin-left: 0; }
  .small-margin-collapse > .small-1 {
    width: 8.33333%; }
  .small-margin-collapse > .small-2 {
    width: 16.66667%; }
  .small-margin-collapse > .small-3 {
    width: 25%; }
  .small-margin-collapse > .small-4 {
    width: 33.33333%; }
  .small-margin-collapse > .small-5 {
    width: 41.66667%; }
  .small-margin-collapse > .small-6 {
    width: 50%; }
  .small-margin-collapse > .small-7 {
    width: 58.33333%; }
  .small-margin-collapse > .small-8 {
    width: 66.66667%; }
  .small-margin-collapse > .small-9 {
    width: 75%; }
  .small-margin-collapse > .small-10 {
    width: 83.33333%; }
  .small-margin-collapse > .small-11 {
    width: 91.66667%; }
  .small-margin-collapse > .small-12 {
    width: 100%; }
  @media print, screen and (min-width: 48em) {
    .small-margin-collapse > .medium-1 {
      width: 8.33333%; }
    .small-margin-collapse > .medium-2 {
      width: 16.66667%; }
    .small-margin-collapse > .medium-3 {
      width: 25%; }
    .small-margin-collapse > .medium-4 {
      width: 33.33333%; }
    .small-margin-collapse > .medium-5 {
      width: 41.66667%; }
    .small-margin-collapse > .medium-6 {
      width: 50%; }
    .small-margin-collapse > .medium-7 {
      width: 58.33333%; }
    .small-margin-collapse > .medium-8 {
      width: 66.66667%; }
    .small-margin-collapse > .medium-9 {
      width: 75%; }
    .small-margin-collapse > .medium-10 {
      width: 83.33333%; }
    .small-margin-collapse > .medium-11 {
      width: 91.66667%; }
    .small-margin-collapse > .medium-12 {
      width: 100%; } }
  @media print, screen and (min-width: 64em) {
    .small-margin-collapse > .large-1 {
      width: 8.33333%; }
    .small-margin-collapse > .large-2 {
      width: 16.66667%; }
    .small-margin-collapse > .large-3 {
      width: 25%; }
    .small-margin-collapse > .large-4 {
      width: 33.33333%; }
    .small-margin-collapse > .large-5 {
      width: 41.66667%; }
    .small-margin-collapse > .large-6 {
      width: 50%; }
    .small-margin-collapse > .large-7 {
      width: 58.33333%; }
    .small-margin-collapse > .large-8 {
      width: 66.66667%; }
    .small-margin-collapse > .large-9 {
      width: 75%; }
    .small-margin-collapse > .large-10 {
      width: 83.33333%; }
    .small-margin-collapse > .large-11 {
      width: 91.66667%; }
    .small-margin-collapse > .large-12 {
      width: 100%; } }

.small-padding-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0; }

@media print, screen and (min-width: 48em) {
  .medium-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 48em) {
  .medium-margin-collapse > .small-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .small-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .small-3 {
    width: 25%; }
  .medium-margin-collapse > .small-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .small-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .small-6 {
    width: 50%; }
  .medium-margin-collapse > .small-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .small-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .small-9 {
    width: 75%; }
  .medium-margin-collapse > .small-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .small-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .small-12 {
    width: 100%; } }

@media print, screen and (min-width: 48em) {
  .medium-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .medium-3 {
    width: 25%; }
  .medium-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .medium-6 {
    width: 50%; }
  .medium-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .medium-9 {
    width: 75%; }
  .medium-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .medium-margin-collapse > .large-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .large-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .large-3 {
    width: 25%; }
  .medium-margin-collapse > .large-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .large-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .large-6 {
    width: 50%; }
  .medium-margin-collapse > .large-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .large-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .large-9 {
    width: 75%; }
  .medium-margin-collapse > .large-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .large-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .large-12 {
    width: 100%; } }

@media print, screen and (min-width: 48em) {
  .medium-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .small-1 {
    width: 8.33333%; }
  .large-margin-collapse > .small-2 {
    width: 16.66667%; }
  .large-margin-collapse > .small-3 {
    width: 25%; }
  .large-margin-collapse > .small-4 {
    width: 33.33333%; }
  .large-margin-collapse > .small-5 {
    width: 41.66667%; }
  .large-margin-collapse > .small-6 {
    width: 50%; }
  .large-margin-collapse > .small-7 {
    width: 58.33333%; }
  .large-margin-collapse > .small-8 {
    width: 66.66667%; }
  .large-margin-collapse > .small-9 {
    width: 75%; }
  .large-margin-collapse > .small-10 {
    width: 83.33333%; }
  .large-margin-collapse > .small-11 {
    width: 91.66667%; }
  .large-margin-collapse > .small-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .large-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .large-margin-collapse > .medium-3 {
    width: 25%; }
  .large-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .large-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .large-margin-collapse > .medium-6 {
    width: 50%; }
  .large-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .large-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .large-margin-collapse > .medium-9 {
    width: 75%; }
  .large-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .large-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .large-margin-collapse > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .large-1 {
    width: 8.33333%; }
  .large-margin-collapse > .large-2 {
    width: 16.66667%; }
  .large-margin-collapse > .large-3 {
    width: 25%; }
  .large-margin-collapse > .large-4 {
    width: 33.33333%; }
  .large-margin-collapse > .large-5 {
    width: 41.66667%; }
  .large-margin-collapse > .large-6 {
    width: 50%; }
  .large-margin-collapse > .large-7 {
    width: 58.33333%; }
  .large-margin-collapse > .large-8 {
    width: 66.66667%; }
  .large-margin-collapse > .large-9 {
    width: 75%; }
  .large-margin-collapse > .large-10 {
    width: 83.33333%; }
  .large-margin-collapse > .large-11 {
    width: 91.66667%; }
  .large-margin-collapse > .large-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

.small-offset-0 {
  margin-left: 0%; }

.grid-margin-x > .small-offset-0 {
  margin-left: calc(0% + 0.3125rem); }

.small-offset-1 {
  margin-left: 8.33333%; }

.grid-margin-x > .small-offset-1 {
  margin-left: calc(8.33333% + 0.3125rem); }

.small-offset-2 {
  margin-left: 16.66667%; }

.grid-margin-x > .small-offset-2 {
  margin-left: calc(16.66667% + 0.3125rem); }

.small-offset-3 {
  margin-left: 25%; }

.grid-margin-x > .small-offset-3 {
  margin-left: calc(25% + 0.3125rem); }

.small-offset-4 {
  margin-left: 33.33333%; }

.grid-margin-x > .small-offset-4 {
  margin-left: calc(33.33333% + 0.3125rem); }

.small-offset-5 {
  margin-left: 41.66667%; }

.grid-margin-x > .small-offset-5 {
  margin-left: calc(41.66667% + 0.3125rem); }

.small-offset-6 {
  margin-left: 50%; }

.grid-margin-x > .small-offset-6 {
  margin-left: calc(50% + 0.3125rem); }

.small-offset-7 {
  margin-left: 58.33333%; }

.grid-margin-x > .small-offset-7 {
  margin-left: calc(58.33333% + 0.3125rem); }

.small-offset-8 {
  margin-left: 66.66667%; }

.grid-margin-x > .small-offset-8 {
  margin-left: calc(66.66667% + 0.3125rem); }

.small-offset-9 {
  margin-left: 75%; }

.grid-margin-x > .small-offset-9 {
  margin-left: calc(75% + 0.3125rem); }

.small-offset-10 {
  margin-left: 83.33333%; }

.grid-margin-x > .small-offset-10 {
  margin-left: calc(83.33333% + 0.3125rem); }

.small-offset-11 {
  margin-left: 91.66667%; }

.grid-margin-x > .small-offset-11 {
  margin-left: calc(91.66667% + 0.3125rem); }

@media print, screen and (min-width: 48em) {
  .medium-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .medium-offset-0 {
    margin-left: calc(0% + 0.375rem); }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .medium-offset-1 {
    margin-left: calc(8.33333% + 0.375rem); }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .medium-offset-2 {
    margin-left: calc(16.66667% + 0.375rem); }
  .medium-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .medium-offset-3 {
    margin-left: calc(25% + 0.375rem); }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .medium-offset-4 {
    margin-left: calc(33.33333% + 0.375rem); }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .medium-offset-5 {
    margin-left: calc(41.66667% + 0.375rem); }
  .medium-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .medium-offset-6 {
    margin-left: calc(50% + 0.375rem); }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .medium-offset-7 {
    margin-left: calc(58.33333% + 0.375rem); }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .medium-offset-8 {
    margin-left: calc(66.66667% + 0.375rem); }
  .medium-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .medium-offset-9 {
    margin-left: calc(75% + 0.375rem); }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .medium-offset-10 {
    margin-left: calc(83.33333% + 0.375rem); }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .medium-offset-11 {
    margin-left: calc(91.66667% + 0.375rem); } }

@media print, screen and (min-width: 64em) {
  .large-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .large-offset-0 {
    margin-left: calc(0% + 0.5rem); }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .large-offset-1 {
    margin-left: calc(8.33333% + 0.5rem); }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .large-offset-2 {
    margin-left: calc(16.66667% + 0.5rem); }
  .large-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .large-offset-3 {
    margin-left: calc(25% + 0.5rem); }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .large-offset-4 {
    margin-left: calc(33.33333% + 0.5rem); }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .large-offset-5 {
    margin-left: calc(41.66667% + 0.5rem); }
  .large-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .large-offset-6 {
    margin-left: calc(50% + 0.5rem); }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .large-offset-7 {
    margin-left: calc(58.33333% + 0.5rem); }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .large-offset-8 {
    margin-left: calc(66.66667% + 0.5rem); }
  .large-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .large-offset-9 {
    margin-left: calc(75% + 0.5rem); }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .large-offset-10 {
    margin-left: calc(83.33333% + 0.5rem); }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .large-offset-11 {
    margin-left: calc(91.66667% + 0.5rem); } }

.grid-y {
  display: flex;
  flex-flow: column nowrap; }
  .grid-y > .cell {
    width: auto; }
  .grid-y > .auto {
    height: auto; }
  .grid-y > .shrink {
    height: auto; }
  .grid-y > .small-shrink, .grid-y > .small-full, .grid-y > .small-1, .grid-y > .small-2, .grid-y > .small-3, .grid-y > .small-4, .grid-y > .small-5, .grid-y > .small-6, .grid-y > .small-7, .grid-y > .small-8, .grid-y > .small-9, .grid-y > .small-10, .grid-y > .small-11, .grid-y > .small-12 {
    flex-basis: auto; }
  @media print, screen and (min-width: 48em) {
    .grid-y > .medium-shrink, .grid-y > .medium-full, .grid-y > .medium-1, .grid-y > .medium-2, .grid-y > .medium-3, .grid-y > .medium-4, .grid-y > .medium-5, .grid-y > .medium-6, .grid-y > .medium-7, .grid-y > .medium-8, .grid-y > .medium-9, .grid-y > .medium-10, .grid-y > .medium-11, .grid-y > .medium-12 {
      flex-basis: auto; } }
  @media print, screen and (min-width: 64em) {
    .grid-y > .large-shrink, .grid-y > .large-full, .grid-y > .large-1, .grid-y > .large-2, .grid-y > .large-3, .grid-y > .large-4, .grid-y > .large-5, .grid-y > .large-6, .grid-y > .large-7, .grid-y > .large-8, .grid-y > .large-9, .grid-y > .large-10, .grid-y > .large-11, .grid-y > .large-12 {
      flex-basis: auto; } }
  .grid-y > .small-1 {
    height: 8.33333%; }
  .grid-y > .small-2 {
    height: 16.66667%; }
  .grid-y > .small-3 {
    height: 25%; }
  .grid-y > .small-4 {
    height: 33.33333%; }
  .grid-y > .small-5 {
    height: 41.66667%; }
  .grid-y > .small-6 {
    height: 50%; }
  .grid-y > .small-7 {
    height: 58.33333%; }
  .grid-y > .small-8 {
    height: 66.66667%; }
  .grid-y > .small-9 {
    height: 75%; }
  .grid-y > .small-10 {
    height: 83.33333%; }
  .grid-y > .small-11 {
    height: 91.66667%; }
  .grid-y > .small-12 {
    height: 100%; }
  @media print, screen and (min-width: 48em) {
    .grid-y > .medium-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .medium-shrink {
      height: auto; }
    .grid-y > .medium-1 {
      height: 8.33333%; }
    .grid-y > .medium-2 {
      height: 16.66667%; }
    .grid-y > .medium-3 {
      height: 25%; }
    .grid-y > .medium-4 {
      height: 33.33333%; }
    .grid-y > .medium-5 {
      height: 41.66667%; }
    .grid-y > .medium-6 {
      height: 50%; }
    .grid-y > .medium-7 {
      height: 58.33333%; }
    .grid-y > .medium-8 {
      height: 66.66667%; }
    .grid-y > .medium-9 {
      height: 75%; }
    .grid-y > .medium-10 {
      height: 83.33333%; }
    .grid-y > .medium-11 {
      height: 91.66667%; }
    .grid-y > .medium-12 {
      height: 100%; } }
  @media print, screen and (min-width: 64em) {
    .grid-y > .large-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .large-shrink {
      height: auto; }
    .grid-y > .large-1 {
      height: 8.33333%; }
    .grid-y > .large-2 {
      height: 16.66667%; }
    .grid-y > .large-3 {
      height: 25%; }
    .grid-y > .large-4 {
      height: 33.33333%; }
    .grid-y > .large-5 {
      height: 41.66667%; }
    .grid-y > .large-6 {
      height: 50%; }
    .grid-y > .large-7 {
      height: 58.33333%; }
    .grid-y > .large-8 {
      height: 66.66667%; }
    .grid-y > .large-9 {
      height: 75%; }
    .grid-y > .large-10 {
      height: 83.33333%; }
    .grid-y > .large-11 {
      height: 91.66667%; }
    .grid-y > .large-12 {
      height: 100%; } }

.grid-padding-y .grid-padding-y {
  margin-top: -0.3125rem;
  margin-bottom: -0.3125rem; }
  @media print, screen and (min-width: 48em) {
    .grid-padding-y .grid-padding-y {
      margin-top: -0.375rem;
      margin-bottom: -0.375rem; } }
  @media print, screen and (min-width: 64em) {
    .grid-padding-y .grid-padding-y {
      margin-top: -0.5rem;
      margin-bottom: -0.5rem; } }

.grid-padding-y > .cell {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem; }
  @media print, screen and (min-width: 48em) {
    .grid-padding-y > .cell {
      padding-top: 0.375rem;
      padding-bottom: 0.375rem; } }
  @media print, screen and (min-width: 64em) {
    .grid-padding-y > .cell {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem; } }

.grid-margin-y {
  margin-top: -0.3125rem;
  margin-bottom: -0.3125rem; }
  @media print, screen and (min-width: 48em) {
    .grid-margin-y {
      margin-top: -0.375rem;
      margin-bottom: -0.375rem; } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-y {
      margin-top: -0.5rem;
      margin-bottom: -0.5rem; } }
  .grid-margin-y > .cell {
    height: calc(100% - 0.625rem);
    margin-top: 0.3125rem;
    margin-bottom: 0.3125rem; }
  @media print, screen and (min-width: 48em) {
    .grid-margin-y > .cell {
      height: calc(100% - 0.75rem);
      margin-top: 0.375rem;
      margin-bottom: 0.375rem; } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-y > .cell {
      height: calc(100% - 1rem);
      margin-top: 0.5rem;
      margin-bottom: 0.5rem; } }
  .grid-margin-y > .auto {
    height: auto; }
  .grid-margin-y > .shrink {
    height: auto; }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 0.625rem); }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 0.625rem); }
  .grid-margin-y > .small-3 {
    height: calc(25% - 0.625rem); }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 0.625rem); }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 0.625rem); }
  .grid-margin-y > .small-6 {
    height: calc(50% - 0.625rem); }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 0.625rem); }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 0.625rem); }
  .grid-margin-y > .small-9 {
    height: calc(75% - 0.625rem); }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 0.625rem); }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 0.625rem); }
  .grid-margin-y > .small-12 {
    height: calc(100% - 0.625rem); }
  @media print, screen and (min-width: 48em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 0.75rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 0.75rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 0.75rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 0.75rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 0.75rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 0.75rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 0.75rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 0.75rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 0.75rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 0.75rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 0.75rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 0.75rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 0.75rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 0.75rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 0.75rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 0.75rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 0.75rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 0.75rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 0.75rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 0.75rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 0.75rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 0.75rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 0.75rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 0.75rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 1rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 1rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 1rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 1rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 1rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 1rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 1rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 1rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 1rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 1rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 1rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 1rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 1rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 1rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 1rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 1rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 1rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 1rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 1rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 1rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 1rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 1rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 1rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 1rem); }
    .grid-margin-y > .large-auto {
      height: auto; }
    .grid-margin-y > .large-shrink {
      height: auto; }
    .grid-margin-y > .large-1 {
      height: calc(8.33333% - 1rem); }
    .grid-margin-y > .large-2 {
      height: calc(16.66667% - 1rem); }
    .grid-margin-y > .large-3 {
      height: calc(25% - 1rem); }
    .grid-margin-y > .large-4 {
      height: calc(33.33333% - 1rem); }
    .grid-margin-y > .large-5 {
      height: calc(41.66667% - 1rem); }
    .grid-margin-y > .large-6 {
      height: calc(50% - 1rem); }
    .grid-margin-y > .large-7 {
      height: calc(58.33333% - 1rem); }
    .grid-margin-y > .large-8 {
      height: calc(66.66667% - 1rem); }
    .grid-margin-y > .large-9 {
      height: calc(75% - 1rem); }
    .grid-margin-y > .large-10 {
      height: calc(83.33333% - 1rem); }
    .grid-margin-y > .large-11 {
      height: calc(91.66667% - 1rem); }
    .grid-margin-y > .large-12 {
      height: calc(100% - 1rem); } }

.grid-frame {
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100vw; }

.cell .grid-frame {
  width: 100%; }

.cell-block {
  overflow-x: auto;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar; }

.cell-block-y {
  overflow-y: auto;
  max-height: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar; }

.cell-block-container {
  display: flex;
  flex-direction: column;
  max-height: 100%; }
  .cell-block-container > .grid-x {
    max-height: 100%;
    flex-wrap: nowrap; }

@media print, screen and (min-width: 48em) {
  .medium-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .medium-grid-frame {
    width: 100%; }
  .medium-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .medium-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .medium-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .medium-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

@media print, screen and (min-width: 64em) {
  .large-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .large-grid-frame {
    width: 100%; }
  .large-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .large-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .large-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .large-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

.grid-y.grid-frame {
  width: auto;
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 100vh; }

@media print, screen and (min-width: 48em) {
  .grid-y.medium-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

@media print, screen and (min-width: 64em) {
  .grid-y.large-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

.cell .grid-y.grid-frame {
  height: 100%; }

@media print, screen and (min-width: 48em) {
  .cell .grid-y.medium-grid-frame {
    height: 100%; } }

@media print, screen and (min-width: 64em) {
  .cell .grid-y.large-grid-frame {
    height: 100%; } }

.grid-margin-y {
  margin-top: -0.3125rem;
  margin-bottom: -0.3125rem; }
  @media print, screen and (min-width: 48em) {
    .grid-margin-y {
      margin-top: -0.375rem;
      margin-bottom: -0.375rem; } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-y {
      margin-top: -0.5rem;
      margin-bottom: -0.5rem; } }
  .grid-margin-y > .cell {
    height: calc(100% - 0.625rem);
    margin-top: 0.3125rem;
    margin-bottom: 0.3125rem; }
  @media print, screen and (min-width: 48em) {
    .grid-margin-y > .cell {
      height: calc(100% - 0.75rem);
      margin-top: 0.375rem;
      margin-bottom: 0.375rem; } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-y > .cell {
      height: calc(100% - 1rem);
      margin-top: 0.5rem;
      margin-bottom: 0.5rem; } }
  .grid-margin-y > .auto {
    height: auto; }
  .grid-margin-y > .shrink {
    height: auto; }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 0.625rem); }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 0.625rem); }
  .grid-margin-y > .small-3 {
    height: calc(25% - 0.625rem); }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 0.625rem); }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 0.625rem); }
  .grid-margin-y > .small-6 {
    height: calc(50% - 0.625rem); }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 0.625rem); }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 0.625rem); }
  .grid-margin-y > .small-9 {
    height: calc(75% - 0.625rem); }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 0.625rem); }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 0.625rem); }
  .grid-margin-y > .small-12 {
    height: calc(100% - 0.625rem); }
  @media print, screen and (min-width: 48em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 0.75rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 0.75rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 0.75rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 0.75rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 0.75rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 0.75rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 0.75rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 0.75rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 0.75rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 0.75rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 0.75rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 0.75rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 0.75rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 0.75rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 0.75rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 0.75rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 0.75rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 0.75rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 0.75rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 0.75rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 0.75rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 0.75rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 0.75rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 0.75rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 1rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 1rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 1rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 1rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 1rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 1rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 1rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 1rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 1rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 1rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 1rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 1rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 1rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 1rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 1rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 1rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 1rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 1rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 1rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 1rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 1rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 1rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 1rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 1rem); }
    .grid-margin-y > .large-auto {
      height: auto; }
    .grid-margin-y > .large-shrink {
      height: auto; }
    .grid-margin-y > .large-1 {
      height: calc(8.33333% - 1rem); }
    .grid-margin-y > .large-2 {
      height: calc(16.66667% - 1rem); }
    .grid-margin-y > .large-3 {
      height: calc(25% - 1rem); }
    .grid-margin-y > .large-4 {
      height: calc(33.33333% - 1rem); }
    .grid-margin-y > .large-5 {
      height: calc(41.66667% - 1rem); }
    .grid-margin-y > .large-6 {
      height: calc(50% - 1rem); }
    .grid-margin-y > .large-7 {
      height: calc(58.33333% - 1rem); }
    .grid-margin-y > .large-8 {
      height: calc(66.66667% - 1rem); }
    .grid-margin-y > .large-9 {
      height: calc(75% - 1rem); }
    .grid-margin-y > .large-10 {
      height: calc(83.33333% - 1rem); }
    .grid-margin-y > .large-11 {
      height: calc(91.66667% - 1rem); }
    .grid-margin-y > .large-12 {
      height: calc(100% - 1rem); } }

.grid-frame.grid-margin-y {
  height: calc(100vh + 0.625rem); }
  @media print, screen and (min-width: 48em) {
    .grid-frame.grid-margin-y {
      height: calc(100vh + 0.75rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-frame.grid-margin-y {
      height: calc(100vh + 1rem); } }

@media print, screen and (min-width: 48em) {
  .grid-margin-y.medium-grid-frame {
    height: calc(100vh + 0.75rem); } }

@media print, screen and (min-width: 64em) {
  .grid-margin-y.large-grid-frame {
    height: calc(100vh + 1rem); } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.4;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "Independent Serif", Tahoma, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  color: inherit;
  text-rendering: optimizeLegibility; }
  h1 small, .h1 small,
  h2 small, .h2 small,
  h3 small, .h3 small,
  h4 small, .h4 small,
  h5 small, .h5 small,
  h6 small, .h6 small {
    line-height: 0;
    color: #888888; }

h1, .h1 {
  font-size: 2rem;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.625rem; }

h2, .h2 {
  font-size: 1.75rem;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.625rem; }

h3, .h3 {
  font-size: 1.5rem;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.625rem; }

h4, .h4 {
  font-size: 1.25rem;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.625rem; }

h5, .h5 {
  font-size: 1.125rem;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.625rem; }

h6, .h6 {
  font-size: 1rem;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.625rem; }

@media print, screen and (min-width: 64em) {
  h1, .h1 {
    font-size: 2.375rem; }
  h2, .h2 {
    font-size: 2rem; }
  h3, .h3 {
    font-size: 1.625rem; }
  h4, .h4 {
    font-size: 1.375rem; }
  h5, .h5 {
    font-size: 1.0625rem; }
  h6, .h6 {
    font-size: 0.9375rem; } }

a {
  line-height: inherit;
  color: #ec1a2e;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: #940c19; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 61.5rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #888888;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.4; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #888888; }
  blockquote, blockquote p {
    line-height: 1.4;
    color: #333333; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #333333; }
  cite:before {
    content: "— "; }

abbr, abbr[title] {
  border-bottom: 1px dotted #000000;
  cursor: help;
  text-decoration: none; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #888888;
  background-color: #dadfe1;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #000000; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #dadfe1;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #000000; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #333333; }

.lead {
  font-size: 125%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 48em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #333333;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  font-family: inherit;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #ec1a2e;
  color: #ffffff; }
  [data-whatinput='mouse'] .button {
    outline: 0; }
  .button:hover, .button:focus {
    background-color: #ce1123;
    color: #ffffff; }
  .button.tiny {
    font-size: 0.6rem; }
  .button.small {
    font-size: 0.75rem; }
  .button.large {
    font-size: 1.25rem; }
  .button.expanded {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.primary {
    background-color: #ec1a2e;
    color: #000000; }
    .button.primary:hover, .button.primary:focus {
      background-color: #c21021;
      color: #000000; }
  .button.secondary {
    background-color: #281E1E;
    color: #ffffff; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: #201818;
      color: #ffffff; }
  .button.success {
    background-color: #3adb76;
    color: #000000; }
    .button.success:hover, .button.success:focus {
      background-color: #22bb5b;
      color: #000000; }
  .button.warning {
    background-color: #ffae00;
    color: #000000; }
    .button.warning:hover, .button.warning:focus {
      background-color: #cc8b00;
      color: #000000; }
  .button.alert {
    background-color: #cc4b37;
    color: #000000; }
    .button.alert:hover, .button.alert:focus {
      background-color: #a53b2a;
      color: #000000; }
  .button.disabled, .button[disabled] {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
      background-color: #ec1a2e;
      color: #ffffff; }
    .button.disabled.primary, .button[disabled].primary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
        background-color: #ec1a2e;
        color: #000000; }
    .button.disabled.secondary, .button[disabled].secondary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
        background-color: #281E1E;
        color: #ffffff; }
    .button.disabled.success, .button[disabled].success {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
        background-color: #3adb76;
        color: #000000; }
    .button.disabled.warning, .button[disabled].warning {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
        background-color: #ffae00;
        color: #000000; }
    .button.disabled.alert, .button[disabled].alert {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
        background-color: #cc4b37;
        color: #000000; }
  .button.hollow {
    border: 1px solid #ec1a2e;
    color: #ec1a2e; }
    .button.hollow, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.hollow.disabled, .button.hollow.disabled:hover, .button.hollow.disabled:focus, .button.hollow[disabled], .button.hollow[disabled]:hover, .button.hollow[disabled]:focus {
      background-color: transparent; }
    .button.hollow:hover, .button.hollow:focus {
      border-color: #790a15;
      color: #790a15; }
      .button.hollow:hover.disabled, .button.hollow:hover[disabled], .button.hollow:focus.disabled, .button.hollow:focus[disabled] {
        border: 1px solid #ec1a2e;
        color: #ec1a2e; }
    .button.hollow.primary {
      border: 1px solid #ec1a2e;
      color: #ec1a2e; }
      .button.hollow.primary:hover, .button.hollow.primary:focus {
        border-color: #790a15;
        color: #790a15; }
        .button.hollow.primary:hover.disabled, .button.hollow.primary:hover[disabled], .button.hollow.primary:focus.disabled, .button.hollow.primary:focus[disabled] {
          border: 1px solid #ec1a2e;
          color: #ec1a2e; }
    .button.hollow.secondary {
      border: 1px solid #281E1E;
      color: #281E1E; }
      .button.hollow.secondary:hover, .button.hollow.secondary:focus {
        border-color: #140f0f;
        color: #140f0f; }
        .button.hollow.secondary:hover.disabled, .button.hollow.secondary:hover[disabled], .button.hollow.secondary:focus.disabled, .button.hollow.secondary:focus[disabled] {
          border: 1px solid #281E1E;
          color: #281E1E; }
    .button.hollow.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.hollow.success:hover, .button.hollow.success:focus {
        border-color: #157539;
        color: #157539; }
        .button.hollow.success:hover.disabled, .button.hollow.success:hover[disabled], .button.hollow.success:focus.disabled, .button.hollow.success:focus[disabled] {
          border: 1px solid #3adb76;
          color: #3adb76; }
    .button.hollow.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.hollow.warning:hover, .button.hollow.warning:focus {
        border-color: #805700;
        color: #805700; }
        .button.hollow.warning:hover.disabled, .button.hollow.warning:hover[disabled], .button.hollow.warning:focus.disabled, .button.hollow.warning:focus[disabled] {
          border: 1px solid #ffae00;
          color: #ffae00; }
    .button.hollow.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.hollow.alert:hover, .button.hollow.alert:focus {
        border-color: #67251a;
        color: #67251a; }
        .button.hollow.alert:hover.disabled, .button.hollow.alert:hover[disabled], .button.hollow.alert:focus.disabled, .button.hollow.alert:focus[disabled] {
          border: 1px solid #cc4b37;
          color: #cc4b37; }
  .button.clear {
    border: 1px solid #ec1a2e;
    color: #ec1a2e; }
    .button.clear, .button.clear:hover, .button.clear:focus {
      background-color: transparent; }
    .button.clear.disabled, .button.clear.disabled:hover, .button.clear.disabled:focus, .button.clear[disabled], .button.clear[disabled]:hover, .button.clear[disabled]:focus {
      background-color: transparent; }
    .button.clear:hover, .button.clear:focus {
      border-color: #790a15;
      color: #790a15; }
      .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus.disabled, .button.clear:focus[disabled] {
        border: 1px solid #ec1a2e;
        color: #ec1a2e; }
    .button.clear, .button.clear.disabled, .button.clear[disabled], .button.clear:hover, .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus, .button.clear:focus.disabled, .button.clear:focus[disabled] {
      border-color: transparent; }
    .button.clear.primary {
      border: 1px solid #ec1a2e;
      color: #ec1a2e; }
      .button.clear.primary:hover, .button.clear.primary:focus {
        border-color: #790a15;
        color: #790a15; }
        .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
          border: 1px solid #ec1a2e;
          color: #ec1a2e; }
      .button.clear.primary, .button.clear.primary.disabled, .button.clear.primary[disabled], .button.clear.primary:hover, .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus, .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
        border-color: transparent; }
    .button.clear.secondary {
      border: 1px solid #281E1E;
      color: #281E1E; }
      .button.clear.secondary:hover, .button.clear.secondary:focus {
        border-color: #140f0f;
        color: #140f0f; }
        .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
          border: 1px solid #281E1E;
          color: #281E1E; }
      .button.clear.secondary, .button.clear.secondary.disabled, .button.clear.secondary[disabled], .button.clear.secondary:hover, .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus, .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
        border-color: transparent; }
    .button.clear.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.clear.success:hover, .button.clear.success:focus {
        border-color: #157539;
        color: #157539; }
        .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
          border: 1px solid #3adb76;
          color: #3adb76; }
      .button.clear.success, .button.clear.success.disabled, .button.clear.success[disabled], .button.clear.success:hover, .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus, .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
        border-color: transparent; }
    .button.clear.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.clear.warning:hover, .button.clear.warning:focus {
        border-color: #805700;
        color: #805700; }
        .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
          border: 1px solid #ffae00;
          color: #ffae00; }
      .button.clear.warning, .button.clear.warning.disabled, .button.clear.warning[disabled], .button.clear.warning:hover, .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus, .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
        border-color: transparent; }
    .button.clear.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.clear.alert:hover, .button.clear.alert:focus {
        border-color: #67251a;
        color: #67251a; }
        .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
          border: 1px solid #cc4b37;
          color: #cc4b37; }
      .button.clear.alert, .button.clear.alert.disabled, .button.clear.alert[disabled], .button.clear.alert:hover, .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus, .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
        border-color: transparent; }
  .button.dropdown::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #ffffff transparent transparent;
    position: relative;
    top: 0.4em;
    display: inline-block;
    float: right;
    margin-left: 1em; }
  .button.dropdown.hollow::after {
    border-top-color: #ec1a2e; }
  .button.dropdown.hollow.primary::after {
    border-top-color: #ec1a2e; }
  .button.dropdown.hollow.secondary::after {
    border-top-color: #281E1E; }
  .button.dropdown.hollow.success::after {
    border-top-color: #3adb76; }
  .button.dropdown.hollow.warning::after {
    border-top-color: #ffae00; }
  .button.dropdown.hollow.alert::after {
    border-top-color: #cc4b37; }
  .button.arrow-only::after {
    top: -0.1em;
    float: none;
    margin-left: 0; }

a.button:hover, a.button:focus {
  text-decoration: none; }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #888888;
  border-radius: 0;
  background-color: #ffffff;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #000000;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    outline: none;
    border: 0 solid #333333;
    background-color: #ffffff;
    box-shadow: 0 0 5px #888888;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::placeholder,
textarea::placeholder {
  color: #888888; }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #dadfe1;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.8;
  color: #000000; }
  label.middle {
    margin: 0 0 1rem;
    padding: 0.5625rem 0; }

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #000000; }

.input-group {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  align-items: stretch; }
  .input-group > :first-child {
    border-radius: 0 0 0 0; }
  .input-group > :last-child > * {
    border-radius: 0 0 0 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #888888;
  background: #dadfe1;
  color: #000000;
  text-align: center;
  white-space: nowrap;
  display: flex;
  flex: 0 0 auto;
  align-items: center; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  flex: 1 1 0px;
  height: auto;
  min-width: 0; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  display: flex;
  flex: 0 0 auto; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: auto;
    align-self: stretch;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1rem; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.5rem; }

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #888888; }
  .fieldset legend {
    margin: 0;
    margin-left: -0.1875rem;
    padding: 0 0.1875rem; }

select {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  appearance: none;
  border: 1px solid #888888;
  border-radius: 0;
  background-color: #ffffff;
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #000000;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%2851, 51, 51%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  @media screen and (min-width: 0\0) {
    select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }
  select:focus {
    outline: none;
    border: 0 solid #333333;
    background-color: #ffffff;
    box-shadow: 0 0 5px #888888;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  select:disabled {
    background-color: #dadfe1;
    cursor: not-allowed; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #cc4b37;
  background-color: #faedeb; }
  .is-invalid-input:not(:focus)::placeholder {
    color: #cc4b37; }

.is-invalid-label {
  color: #cc4b37; }

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #cc4b37; }
  .form-error.is-visible {
    display: block; }

.breadcrumbs {
  margin: 0 0 0.5rem 0;
  list-style: none; }
  .breadcrumbs::before, .breadcrumbs::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .breadcrumbs::after {
    clear: both; }
  .breadcrumbs li {
    float: left;
    font-size: 14px;
    color: #000000;
    cursor: default; }
    .breadcrumbs li:not(:last-child)::after {
      position: relative;
      margin: 0 0.3rem;
      opacity: 1;
      content: "›";
      color: #281E1E; }
  .breadcrumbs a {
    color: #281E1E; }
    .breadcrumbs a:hover {
      text-decoration: underline; }
  .breadcrumbs .disabled {
    color: #888888;
    cursor: not-allowed; }

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 0;
  background-color: white;
  color: #281E1E; }
  .callout > :first-child {
    margin-top: 0; }
  .callout > :last-child {
    margin-bottom: 0; }
  .callout.primary {
    background-color: #fcdde0;
    color: #281E1E; }
  .callout.secondary {
    background-color: #e3d9d9;
    color: #281E1E; }
  .callout.success {
    background-color: #e1faea;
    color: #281E1E; }
  .callout.warning {
    background-color: #fff3d9;
    color: #281E1E; }
  .callout.alert {
    background-color: #f7e4e1;
    color: #281E1E; }
  .callout.small {
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem; }
  .callout.large {
    padding-top: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    padding-left: 3rem; }

.card {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 2rem;
  border: 0 solid #dadfe1;
  border-radius: 0;
  background: #ffffff;
  box-shadow: none;
  overflow: hidden;
  color: #281E1E; }
  .card > :last-child {
    margin-bottom: 0; }

.card-divider {
  flex: 0 1 auto;
  display: flex;
  padding: 1rem;
  background: #dadfe1; }
  .card-divider > :last-child {
    margin-bottom: 0; }

.card-section {
  flex: 1 0 auto;
  padding: 1rem; }
  .card-section > :last-child {
    margin-bottom: 0; }

.card-image {
  min-height: 1px; }

.close-button {
  position: absolute;
  color: #333333;
  cursor: pointer; }
  [data-whatinput='mouse'] .close-button {
    outline: 0; }
  .close-button:hover, .close-button:focus {
    color: #000000; }
  .close-button.small {
    right: 0.66rem;
    top: 0.33em;
    font-size: 1.5em;
    line-height: 1; }
  .close-button, .close-button.medium {
    right: 1rem;
    top: 0.5rem;
    font-size: 2em;
    line-height: 1; }

.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: flex;
  flex-wrap: wrap; }
  [data-whatinput='mouse'] .menu li {
    outline: 0; }
  .menu a,
  .menu .button {
    line-height: 1;
    text-decoration: none;
    display: block;
    padding: 0.7rem 1rem; }
  .menu input,
  .menu select,
  .menu a,
  .menu button {
    margin-bottom: 0; }
  .menu input {
    display: inline-block; }
  .menu, .menu.horizontal {
    flex-wrap: wrap;
    flex-direction: row; }
  .menu.vertical {
    flex-wrap: nowrap;
    flex-direction: column; }
  .menu.expanded li {
    flex: 1 1 0px; }
  .menu.simple {
    align-items: center; }
    .menu.simple li + li {
      margin-left: 1rem; }
    .menu.simple a {
      padding: 0; }
  @media print, screen and (min-width: 48em) {
    .menu.medium-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.medium-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.medium-expanded li {
      flex: 1 1 0px; }
    .menu.medium-simple li {
      flex: 1 1 0px; } }
  @media print, screen and (min-width: 64em) {
    .menu.large-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.large-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.large-expanded li {
      flex: 1 1 0px; }
    .menu.large-simple li {
      flex: 1 1 0px; } }
  .menu.nested {
    margin-right: 0;
    margin-left: 1rem; }
  .menu.icons a {
    display: flex; }
  .menu.icon-top a, .menu.icon-right a, .menu.icon-bottom a, .menu.icon-left a {
    display: flex; }
  .menu.icon-left li a {
    flex-flow: row nowrap; }
    .menu.icon-left li a img,
    .menu.icon-left li a i,
    .menu.icon-left li a svg {
      margin-right: 0.25rem; }
  .menu.icon-right li a {
    flex-flow: row nowrap; }
    .menu.icon-right li a img,
    .menu.icon-right li a i,
    .menu.icon-right li a svg {
      margin-left: 0.25rem; }
  .menu.icon-top li a {
    flex-flow: column nowrap; }
    .menu.icon-top li a img,
    .menu.icon-top li a i,
    .menu.icon-top li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu.icon-bottom li a {
    flex-flow: column nowrap; }
    .menu.icon-bottom li a img,
    .menu.icon-bottom li a i,
    .menu.icon-bottom li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu .is-active > a {
    background: #ec1a2e;
    color: #ffffff; }
  .menu .active > a {
    background: #ec1a2e;
    color: #ffffff; }
  .menu.align-left {
    justify-content: flex-start; }
  .menu.align-right li {
    display: flex;
    justify-content: flex-end; }
    .menu.align-right li .submenu li {
      justify-content: flex-start; }
  .menu.align-right.vertical li {
    display: block;
    text-align: right; }
    .menu.align-right.vertical li .submenu li {
      text-align: right; }
  .menu.align-right .nested {
    margin-right: 1rem;
    margin-left: 0; }
  .menu.align-center li {
    display: flex;
    justify-content: center; }
    .menu.align-center li .submenu li {
      justify-content: flex-start; }
  .menu .menu-text {
    padding: 0.7rem 1rem;
    font-weight: bold;
    line-height: 1;
    color: inherit; }

.menu-centered > .menu {
  justify-content: center; }
  .menu-centered > .menu li {
    display: flex;
    justify-content: center; }
    .menu-centered > .menu li .submenu li {
      justify-content: flex-start; }

.no-js [data-responsive-menu] ul {
  display: none; }

.responsive-embed,
.flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 75%;
  overflow: hidden; }
  .responsive-embed iframe,
  .responsive-embed object,
  .responsive-embed embed,
  .responsive-embed video,
  .flex-video iframe,
  .flex-video object,
  .flex-video embed,
  .flex-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .responsive-embed.widescreen,
  .flex-video.widescreen {
    padding-bottom: 56.25%; }

.label {
  display: inline-block;
  padding: 0.33333rem 0.5rem;
  border-radius: 0;
  font-size: 0.8rem;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
  background: #ec1a2e;
  color: #ffffff; }
  .label.primary {
    background: #ec1a2e;
    color: #000000; }
  .label.secondary {
    background: #281E1E;
    color: #ffffff; }
  .label.success {
    background: #3adb76;
    color: #000000; }
  .label.warning {
    background: #ffae00;
    color: #000000; }
  .label.alert {
    background: #cc4b37;
    color: #000000; }

.media-object {
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: nowrap; }
  .media-object img {
    max-width: none; }
  @media screen and (max-width: 47.9375em) {
    .media-object.stack-for-small {
      flex-wrap: wrap; } }
  @media screen and (max-width: 47.9375em) {
    .media-object.stack-for-small .media-object-section {
      padding: 0;
      padding-bottom: 0.625rem;
      flex-basis: 100%;
      max-width: 100%; }
      .media-object.stack-for-small .media-object-section img {
        width: 100%; } }

.media-object-section {
  flex: 0 1 auto; }
  .media-object-section:first-child {
    padding-right: 0.625rem; }
  .media-object-section:last-child:not(:nth-child(2)) {
    padding-left: 0.625rem; }
  .media-object-section > :last-child {
    margin-bottom: 0; }
  .media-object-section.main-section {
    flex: 1 1 0px; }

.pagination {
  margin-left: 0;
  margin-bottom: 1rem; }
  .pagination::before, .pagination::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .pagination::after {
    clear: both; }
  .pagination li {
    margin-right: 0.0625rem;
    border-radius: 0;
    font-size: 0.875rem;
    display: none; }
    .pagination li:last-child, .pagination li:first-child {
      display: inline-block; }
    @media print, screen and (min-width: 48em) {
      .pagination li {
        display: inline-block; } }
  .pagination a,
  .pagination button {
    display: block;
    padding: 0.1875rem 0.625rem;
    border-radius: 0;
    color: #000000; }
    .pagination a:hover,
    .pagination button:hover {
      background: #dadfe1; }
  .pagination .current {
    padding: 0.1875rem 0.625rem;
    background: #ec1a2e;
    color: #ffffff;
    cursor: default; }
  .pagination .disabled {
    padding: 0.1875rem 0.625rem;
    color: #888888;
    cursor: not-allowed; }
    .pagination .disabled:hover {
      background: transparent; }
  .pagination .ellipsis::after {
    padding: 0.1875rem 0.625rem;
    content: '\2026';
    color: #000000; }

.pagination-previous a::before,
.pagination-previous.disabled::before {
  display: inline-block;
  margin-right: 0.5rem;
  content: '\00ab'; }

.pagination-next a::after,
.pagination-next.disabled::after {
  display: inline-block;
  margin-left: 0.5rem;
  content: '\00bb'; }

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0; }
  thead,
  tbody,
  tfoot {
    border: 1px solid #f2f2f2;
    background-color: #ffffff; }
  caption {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold; }
  thead {
    background: #f9f9f9;
    color: #281E1E; }
  tfoot {
    background: #f2f2f2;
    color: #281E1E; }
  thead tr,
  tfoot tr {
    background: transparent; }
  thead th,
  thead td,
  tfoot th,
  tfoot td {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold;
    text-align: left; }
  tbody th,
  tbody td {
    padding: 0.5rem 0.625rem 0.625rem; }
  tbody tr:nth-child(even) {
    border-bottom: 0;
    background-color: #f2f2f2; }
  table.unstriped tbody {
    background-color: #ffffff; }
    table.unstriped tbody tr {
      border-bottom: 0;
      border-bottom: 1px solid #f2f2f2;
      background-color: #ffffff; }

@media screen and (max-width: 63.9375em) {
  table.stack thead {
    display: none; }
  table.stack tfoot {
    display: none; }
  table.stack tr,
  table.stack th,
  table.stack td {
    display: block; }
  table.stack td {
    border-top: 0; } }

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto; }

table.hover thead tr:hover {
  background-color: #f4f4f4; }

table.hover tfoot tr:hover {
  background-color: #ededed; }

table.hover tbody tr:hover {
  background-color: #fafafa; }

table.hover:not(.unstriped) tr:nth-of-type(even):hover {
  background-color: #ededed; }

.table-scroll {
  overflow-x: auto; }
  .table-scroll table {
    width: auto; }

.thumbnail {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1rem;
  border: solid 4px #ffffff;
  border-radius: 0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
  line-height: 0; }

a.thumbnail {
  transition: box-shadow 200ms ease-out; }
  a.thumbnail:hover, a.thumbnail:focus {
    box-shadow: 0 0 6px 1px rgba(236, 26, 46, 0.5); }
  a.thumbnail image {
    box-shadow: none; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 47.9375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 48em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 48em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 47.9375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 47.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.align-right {
  justify-content: flex-end; }

.align-center {
  justify-content: center; }

.align-justify {
  justify-content: space-between; }

.align-spaced {
  justify-content: space-around; }

.align-right.vertical.menu > li > a {
  justify-content: flex-end; }

.align-center.vertical.menu > li > a {
  justify-content: center; }

.align-top {
  align-items: flex-start; }

.align-self-top {
  align-self: flex-start; }

.align-bottom {
  align-items: flex-end; }

.align-self-bottom {
  align-self: flex-end; }

.align-middle {
  align-items: center; }

.align-self-middle {
  align-self: center; }

.align-stretch {
  align-items: stretch; }

.align-self-stretch {
  align-self: stretch; }

.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center; }

.small-order-1 {
  order: 1; }

.small-order-2 {
  order: 2; }

.small-order-3 {
  order: 3; }

.small-order-4 {
  order: 4; }

.small-order-5 {
  order: 5; }

.small-order-6 {
  order: 6; }

@media print, screen and (min-width: 48em) {
  .medium-order-1 {
    order: 1; }
  .medium-order-2 {
    order: 2; }
  .medium-order-3 {
    order: 3; }
  .medium-order-4 {
    order: 4; }
  .medium-order-5 {
    order: 5; }
  .medium-order-6 {
    order: 6; } }

@media print, screen and (min-width: 64em) {
  .large-order-1 {
    order: 1; }
  .large-order-2 {
    order: 2; }
  .large-order-3 {
    order: 3; }
  .large-order-4 {
    order: 4; }
  .large-order-5 {
    order: 5; }
  .large-order-6 {
    order: 6; } }

.flex-container {
  display: flex; }

.flex-child-auto {
  flex: 1 1 auto; }

.flex-child-grow {
  flex: 1 0 auto; }

.flex-child-shrink {
  flex: 0 1 auto; }

.flex-dir-row {
  flex-direction: row; }

.flex-dir-row-reverse {
  flex-direction: row-reverse; }

.flex-dir-column {
  flex-direction: column; }

.flex-dir-column-reverse {
  flex-direction: column-reverse; }

@media print, screen and (min-width: 48em) {
  .medium-flex-container {
    display: flex; }
  .medium-flex-child-auto {
    flex: 1 1 auto; }
  .medium-flex-child-grow {
    flex: 1 0 auto; }
  .medium-flex-child-shrink {
    flex: 0 1 auto; }
  .medium-flex-dir-row {
    flex-direction: row; }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .medium-flex-dir-column {
    flex-direction: column; }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media print, screen and (min-width: 64em) {
  .large-flex-container {
    display: flex; }
  .large-flex-child-auto {
    flex: 1 1 auto; }
  .large-flex-child-grow {
    flex: 1 0 auto; }
  .large-flex-child-shrink {
    flex: 0 1 auto; }
  .large-flex-dir-row {
    flex-direction: row; }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .large-flex-dir-column {
    flex-direction: column; }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

.font-wide {
  letter-spacing: 0.25rem; }

.font-normal {
  font-weight: normal; }

.font-bold {
  font-weight: bold; }

.font-italic {
  font-style: italic !important; }

.margin-0 {
  margin-top: 0rem !important;
  margin-right: 0rem !important;
  margin-bottom: 0rem !important;
  margin-left: 0rem !important; }

.padding-0 {
  padding-top: 0rem !important;
  padding-right: 0rem !important;
  padding-bottom: 0rem !important;
  padding-left: 0rem !important; }

.margin-top-0 {
  margin-top: 0rem !important; }

.padding-top-0 {
  padding-top: 0rem !important; }

.margin-right-0 {
  margin-right: 0rem !important; }

.padding-right-0 {
  padding-right: 0rem !important; }

.margin-bottom-0 {
  margin-bottom: 0rem !important; }

.padding-bottom-0 {
  padding-bottom: 0rem !important; }

.margin-left-0 {
  margin-left: 0rem !important; }

.padding-left-0 {
  padding-left: 0rem !important; }

.margin-horizontal-0 {
  margin-right: 0rem !important;
  margin-left: 0rem !important; }

.padding-horizontal-0 {
  padding-right: 0rem !important;
  padding-left: 0rem !important; }

.margin-vertical-0 {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important; }

.padding-vertical-0 {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important; }

.margin-1 {
  margin-top: 1rem !important;
  margin-right: 1rem !important;
  margin-bottom: 1rem !important;
  margin-left: 1rem !important; }

.padding-1 {
  padding-top: 1rem !important;
  padding-right: 1rem !important;
  padding-bottom: 1rem !important;
  padding-left: 1rem !important; }

.margin-top-1 {
  margin-top: 1rem !important; }

.padding-top-1 {
  padding-top: 1rem !important; }

.margin-right-1 {
  margin-right: 1rem !important; }

.padding-right-1 {
  padding-right: 1rem !important; }

.margin-bottom-1 {
  margin-bottom: 1rem !important; }

.padding-bottom-1 {
  padding-bottom: 1rem !important; }

.margin-left-1 {
  margin-left: 1rem !important; }

.padding-left-1 {
  padding-left: 1rem !important; }

.margin-horizontal-1 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.padding-horizontal-1 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.margin-vertical-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.padding-vertical-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.margin-2 {
  margin-top: 2rem !important;
  margin-right: 2rem !important;
  margin-bottom: 2rem !important;
  margin-left: 2rem !important; }

.padding-2 {
  padding-top: 2rem !important;
  padding-right: 2rem !important;
  padding-bottom: 2rem !important;
  padding-left: 2rem !important; }

.margin-top-2 {
  margin-top: 2rem !important; }

.padding-top-2 {
  padding-top: 2rem !important; }

.margin-right-2 {
  margin-right: 2rem !important; }

.padding-right-2 {
  padding-right: 2rem !important; }

.margin-bottom-2 {
  margin-bottom: 2rem !important; }

.padding-bottom-2 {
  padding-bottom: 2rem !important; }

.margin-left-2 {
  margin-left: 2rem !important; }

.padding-left-2 {
  padding-left: 2rem !important; }

.margin-horizontal-2 {
  margin-right: 2rem !important;
  margin-left: 2rem !important; }

.padding-horizontal-2 {
  padding-right: 2rem !important;
  padding-left: 2rem !important; }

.margin-vertical-2 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important; }

.padding-vertical-2 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important; }

.margin-3 {
  margin-top: 3rem !important;
  margin-right: 3rem !important;
  margin-bottom: 3rem !important;
  margin-left: 3rem !important; }

.padding-3 {
  padding-top: 3rem !important;
  padding-right: 3rem !important;
  padding-bottom: 3rem !important;
  padding-left: 3rem !important; }

.margin-top-3 {
  margin-top: 3rem !important; }

.padding-top-3 {
  padding-top: 3rem !important; }

.margin-right-3 {
  margin-right: 3rem !important; }

.padding-right-3 {
  padding-right: 3rem !important; }

.margin-bottom-3 {
  margin-bottom: 3rem !important; }

.padding-bottom-3 {
  padding-bottom: 3rem !important; }

.margin-left-3 {
  margin-left: 3rem !important; }

.padding-left-3 {
  padding-left: 3rem !important; }

.margin-horizontal-3 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.padding-horizontal-3 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.margin-vertical-3 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.padding-vertical-3 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.separator-center {
  text-align: center !important; }
  .separator-center::before, .separator-center::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .separator-center::after {
    clear: both; }
  .separator-center::after {
    position: relative !important;
    width: 3rem;
    border-bottom: 0.125rem solid #ec1a2e;
    margin: 1rem auto 0; }

.separator-left {
  text-align: left !important; }
  .separator-left::before, .separator-left::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .separator-left::after {
    clear: both; }
  .separator-left::after {
    position: relative !important;
    width: 3rem;
    border-bottom: 0.125rem solid #ec1a2e;
    margin: 1rem auto 0;
    margin-left: 0 !important; }

.separator-right {
  text-align: right !important; }
  .separator-right::before, .separator-right::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .separator-right::after {
    clear: both; }
  .separator-right::after {
    position: relative !important;
    width: 3rem;
    border-bottom: 0.125rem solid #ec1a2e;
    margin: 1rem auto 0;
    margin-right: 0 !important; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 24px;
  width: 24px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  border-radius: 50%;
  background: black;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  z-index: 1; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: black;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 0.75; }
  .slick-prev.slick-disabled,
  .slick-next.slick-disabled {
    background-color: transparent;
    border: 1px solid rgba(172, 172, 172, 0.5); }
    .slick-prev.slick-disabled:before,
    .slick-next.slick-disabled:before {
      color: rgba(172, 172, 172, 0.5);
      opacity: 1; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "icomoon";
    font-size: 14px;
    line-height: 1;
    color: white;
    opacity: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: 8px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: 8px; }
  .slick-prev:before {
    content: ""; }
    [dir="rtl"] .slick-prev:before {
      content: ""; }

.slick-next {
  right: 8px; }
  [dir="rtl"] .slick-next {
    left: 8px;
    right: auto; }
  .slick-next:before {
    content: ""; }
    [dir="rtl"] .slick-next:before {
      content: ""; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 0.75; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "icomoon";
        font-size: 20px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 1; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.container-divider-right {
  border-bottom: 1px solid #dadfe1; }
  @media print, screen and (min-width: 48em) {
    .container-divider-right {
      border-right: 1px solid #dadfe1;
      border-bottom: 0; } }

.avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  min-width: 40px; }
  .avatar.avatar-small {
    height: 25px;
    width: 25px;
    border-radius: 50%; }
  .avatar.avatar-large {
    height: 100px;
    width: 100px;
    border-radius: 50%; }

.mb0 {
  margin-bottom: 0rem !important; }

.mb1 {
  margin-bottom: 1rem !important; }

.mb2 {
  margin-bottom: 2rem !important; }

.mb3 {
  margin-bottom: 3rem !important; }

.type-sidelines {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-align: left; }
  .type-sidelines span {
    display: inline-block;
    position: relative;
    padding-right: 0.5em;
    padding-left: 0; }
    .type-sidelines span:before, .type-sidelines span:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 9999px;
      top: 50%;
      border-top-style: solid;
      border-top-width: 2px;
      margin-top: -1px; }
    .type-sidelines span:before {
      right: 100%; }
    .type-sidelines span:after {
      left: 100%; }

.type-sidelines-dotted {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-align: left; }
  .type-sidelines-dotted span {
    display: inline-block;
    position: relative;
    padding-right: 0.5em;
    padding-left: 0; }
    .type-sidelines-dotted span:before, .type-sidelines-dotted span:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 9999px;
      top: 50%;
      border-top-style: dotted;
      border-top-width: 2px;
      margin-top: -1px; }
    .type-sidelines-dotted span:before {
      right: 100%; }
    .type-sidelines-dotted span:after {
      left: 100%; }

.double {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-align: center; }
  .double span {
    display: inline-block;
    position: relative;
    padding-right: 0.5em;
    padding-left: 0.5em; }
    .double span:before, .double span:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 9999px;
      top: 50%;
      border-top-style: solid;
      border-top-width: 2px;
      border-bottom-style: solid;
      border-bottom-width: 2px;
      height: 0.5em;
      margin-top: -0.25em; }
    .double span:before {
      right: 100%; }
    .double span:after {
      left: 100%; }

.custom {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-align: center; }
  .custom span {
    display: inline-block;
    position: relative;
    padding-right: 20px;
    padding-left: 20px; }
    .custom span:before, .custom span:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      top: 50%;
      border-top-style: dotted;
      border-top-width: 4px;
      border-top-color: #2980b9;
      margin-top: -2px; }
    .custom span:before {
      right: 100%; }
    .custom span:after {
      left: 100%; }

.custom-2 {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-align: center; }
  .custom-2 span {
    display: inline-block;
    position: relative;
    padding-right: 0.5em;
    padding-left: 0.5em; }
    .custom-2 span:before, .custom-2 span:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 9999px;
      top: 50%;
      border-top-style: solid;
      border-top-width: 4px;
      border-top-color: #27ae60;
      margin-top: -2px; }
    .custom-2 span:before {
      right: 100%; }
    .custom-2 span:after {
      left: 100%; }

.thing {
  z-index: 4000; }

.clearfix::before, .clearfix::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1; }

.clearfix::after {
  clear: both; }

.headline-slider {
  height: 390px;
  overflow: hidden;
  position: relative;
  margin-bottom: 1rem;
  font-family: "Independent Sans"; }
  @media print, screen and (min-width: 48em) {
    .headline-slider {
      height: 450px; } }
  @media print, screen and (min-width: 64em) {
    .headline-slider {
      height: 390px; } }
  .headline-slider.slick-dotted {
    margin-bottom: 0.75rem; }

.headline-slider:before {
  /*left: 0;
  bottom: 0;
  width: 100%;
  height: 37px;
  content: '';
  z-index: 2;
  background: #e3e3e3;
  position: absolute;*/ }

.headline-slider-item.son_dakika_sol .image, .headline-slider-item.son_dakika_ust .image, .headline-slider-item.son_durum .image, .headline-slider-item.yeni_haber .image {
  position: relative; }

.headline-slider-item.son_dakika_sol .image:after, .headline-slider-item.son_dakika_ust .image:after, .headline-slider-item.son_durum .image:after, .headline-slider-item.yeni_haber .image:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  position: absolute; }

/*.headline-slider-item.son_dakika_ust .image:after {
  background: url(../images/headline-animation/breaking-news-1.gif)
}

.headline-slider-item.son_dakika_sol .image:after {
  background: url(../images/headline-animation/breaking-news-2.gif)
}

.headline-slider-item.son_durum .image:after {
  background: url(../images/headline-animation/last-news.gif)
}

.headline-slider-item.yeni_haber .image:after {
  background: url(../images/headline-animation/new-news.gif)
}*/
.headline-slider .slick-dots {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  left: 0;
  bottom: 0;
  z-index: 3;
  height: 37px;
  background: #E3E3E3;
  -webkit-box-shadow: inset 1px 0 0 #C7C7C7, inset -1px 0 0 #C7C7C7, inset 0 -1px 0 #C7C7C7;
  box-shadow: inset 1px 0 0 #C7C7C7, inset -1px 0 0 #C7C7C7, inset 0 -1px 0 #C7C7C7; }
  @media screen and (max-width: 47.9375em) {
    .headline-slider .slick-dots {
      display: none !important; } }

.headline-slider .slick-dots li, .headline-slider .slick-dots span {
  flex: 1;
  width: auto;
  height: auto;
  margin: 0;
  text-align: center; }

.headline-slider .slick-dots li + li, .headline-slider .slick-dots li + span, .headline-slider .slick-dots span + li, .headline-slider .slick-dots span + span {
  -webkit-box-shadow: inset 1px 0 0 #c7c7c7;
  box-shadow: inset 1px 0 0 #c7c7c7; }

.headline-slider .slick-dots li a, .headline-slider .slick-dots span a {
  width: auto;
  height: 37px;
  color: #444;
  display: block;
  font-size: 13px;
  line-height: 37px; }

.headline-slider .slick-dots span, .headline-slider .slick-slide img {
  display: inline-block; }

.headline-slider .slick-slide .image {
  height: 390px;
  position: relative; }
  @media print, screen and (min-width: 48em) {
    .headline-slider .slick-slide .image {
      height: 413px; } }
  @media print, screen and (min-width: 64em) {
    .headline-slider .slick-slide .image {
      height: 353px; } }

.headline-slider .slick-dots li.slick-active a, .headline-slider .slick-dots span.slick-active a {
  color: #fff;
  background-color: #D8242F; }

.ie9 .headline-slider .slick-dots {
  z-index: 999; }

.headline-slider .slick-slide {
  text-align: center; }

.headline-slider .slick-slide .title, .small-headline-slider .slick-slide .title {
  display: block;
  bottom: 0;
  position: absolute;
  padding: 10px;
  text-align: center;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  line-height: 1.2;
  color: #ffffff;
  font-size: 1.25rem; }

.headline-slider .title {
  color: #faf8f8; }

.slider-arrow {
  top: 50%;
  width: 20px;
  height: 35px;
  z-index: 1;
  margin-top: -12px;
  color: rgba(255, 255, 255, 0.45);
  position: absolute;
  cursor: pointer; }

.slider-arrow:hover {
  color: rgba(255, 255, 255, 0.9);
  opacity: 0.7; }

.slider-arrow.prev {
  left: 20px;
  background: url("../img/svg/chevron-left.svg?zmxcfzxmc") top left no-repeat; }

.slider-arrow.next {
  right: 20px;
  background: url("../img/svg/chevron-right.svg?zmxcfzxmc") top left no-repeat; }

.headline-slider .slider-arrow {
  width: 23px;
  height: 41px;
  margin-top: -39px;
  background-size: contain; }

.headline-slider .slider-arrow:hover {
  color: rgba(255, 255, 255, 0.55); }

.ie9 .headline-slider .slider-arrow {
  z-index: 1000; }

.small-headline-slider .slider-arrow {
  margin-top: -54px; }

/* ------------------ */
.small-headline {
  font-family: "Independent Sans";
  max-width: 320px;
  margin: 0 auto; }

.small-headline-header {
  color: #ECECEC;
  height: 40px;
  line-height: 1;
  background: #222;
  overflow-x: auto;
  overflow-y: hidden;
  border-top: 2px solid #D72530;
  white-space: nowrap;
  width: 100%; }
  @media print, screen and (min-width: 64em) {
    .small-headline-header {
      overflow: hidden; } }

.small-headline-header:after, .small-headline-header:before {
  content: "";
  display: table; }

.small-headline-header_item {
  font-family: "Independent Sans";
  font-weight: normal;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  margin: 0;
  padding: 0 0.5rem;
  line-height: 40px;
  display: inline-block; }

.small-headline-header_item.isActive {
  background: #D72530; }

.small-headline-slider {
  height: 350px;
  overflow: hidden;
  display: none;
  position: relative; }

.small-headline-slider:before {
  /*left: 0;
  bottom: 0;
  width: 100%;
  height: 37px;
  content: '';
  z-index: 2;
  background: #222;
  position: absolute;*/ }

.small-headline-slider.isActive {
  display: block; }

.small-headline-slider_item {
  position: relative;
  height: 313px; }
  .small-headline-slider_item a.author-img {
    display: block;
    position: absolute;
    left: 0.5rem;
    top: 0.5rem;
    width: 90px;
    height: 90px;
    z-index: 1;
    overflow: hidden;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.2); }

.small-headline-slider .slick-dots {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  left: 0;
  z-index: 3;
  bottom: 0;
  height: 37px;
  background: #222;
  -webkit-box-shadow: inset 1px 0 0 #353535, inset -1px 0 0 #353535, inset 0 -1px 0 #353535;
  box-shadow: inset 1px 0 0 #353535, inset -1px 0 0 #353535, inset 0 -1px 0 #353535; }
  @media screen and (max-width: 47.9375em) {
    .small-headline-slider .slick-dots {
      display: none !important; } }

.small-headline-slider .slick-dots li {
  flex: 1;
  width: auto;
  height: auto;
  margin: 0;
  text-align: center; }

.small-headline-slider .slick-dots li + li {
  -webkit-box-shadow: inset 1px 0 0 #353535;
  box-shadow: inset 1px 0 0 #353535; }

.small-headline-slider .slick-dots li a {
  width: auto;
  height: 37px;
  color: #B8B8B8;
  display: block;
  font-size: 13px;
  line-height: 37px; }

.small-headline-slider .slick-dots li.slick-active a {
  color: #fff;
  background-color: #D8242F; }

.ie9 .small-headline-slider .slick-dots {
  z-index: 999; }

.small-headline-slider .slick-slide {
  text-align: center; }

.small-headline-slider .slick-slide img {
  display: inline-block; }

.small-headline.ekonomi .small-headline-header {
  border-top-color: #008eae; }

.small-headline.ekonomi .small-headline-header_item.isActive, .small-headline.ekonomi .small-headline-slider .slick-dots li.slick-active a {
  background-color: #008eae; }

.small-headline.spor .small-headline-header {
  border-top-color: #8fc249; }

.small-headline.spor .small-headline-header_item.isActive, .small-headline.spor .small-headline-slider .slick-dots li.slick-active a {
  background-color: #8fc249; }

.small-headline.dunya .small-headline-header {
  border-top-color: #FF7F00; }

.small-headline.dunya .small-headline-header_item.isActive, .small-headline.dunya .small-headline-slider .slick-dots li.slick-active a {
  background-color: #FF7F00; }

.small-headline-header_item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none; }

.nav-container > ul {
  margin: 0;
  font-family: "Independent Sans";
  font-size: 13px;
  font-weight: 500; }
  [dir='rtl'] .nav-container > ul {
    font-family: "Independent Sans";
    font-size: 15px;
    font-weight: 800; }
  .urdu-theme .nav-container > ul {
    font-size: 21px; }
  .nav-container > ul > li.active > a:hover {
    color: white; }
  .nav-container > ul > li:hover > a:after {
    display: block; }
  .nav-container > ul > li:hover > ul {
    display: flex;
    visibility: visible;
    opacity: 1;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    -ms-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s; }
  .nav-container > ul > li.active > a:after {
    display: block; }
  .nav-container > ul > li > a {
    padding: 11px 8px 11px 16px;
    text-transform: uppercase;
    color: #281E1E;
    display: block;
    white-space: nowrap;
    position: relative; }
    [dir="rtl"] .nav-container > ul > li > a {
      padding: 11px 16px 11px 8px; }
    .nav-container > ul > li > a:after {
      content: " ";
      height: 2px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: #ec1a2e;
      display: none; }
    .nav-container > ul > li > a:hover {
      color: #ec1a2e; }

.nav-container > ul ul {
  margin: 0;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  width: 100vw;
  list-style: none;
  background-color: #000000;
  z-index: 200;
  opacity: 0;
  visibility: hidden;
  transition: .5s all;
  transition-delay: 1s; }
  .nav-container > ul ul li a {
    display: block;
    padding: 16px 22px;
    font-size: 14px;
    color: #fff;
    line-height: 16px;
    position: relative; }
    .nav-container > ul ul li a:after {
      content: " ";
      height: 2px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: #ec1a2e;
      display: none; }
    .nav-container > ul ul li a:hover:after {
      display: block; }

.menu-visible {
  background: #000000;
  padding-top: 0 !important; }
  @media print, screen and (min-width: 64em) {
    .menu-visible .ad-block-header {
      background-color: #000000; } }

.menu-visible #main-header {
  position: relative;
  height: 100%; }

.menu-visible #nav-panel {
  display: block; }

.menu-visible .subheader-block ul li.edition, .menu-visible .subheader-block ul li.search {
  display: block; }

.menu-visible main,
.menu-visible .main-header-inner,
.menu-visible #main-footer {
  display: none; }

.nav-panel-wrap {
  background-color: #000000;
  color: #ffffff; }
  @media screen and (max-width: 47.9375em) {
    .menu-visible .nav-panel-wrap {
      background-color: #ffffff;
      color: #000000; } }

#nav-panel {
  display: none;
  padding: 1rem; }
  @media print, screen and (min-width: 64em) {
    #nav-panel {
      width: 1100px;
      margin: auto; } }
  #nav-panel ul {
    list-style: none;
    margin: 0;
    font-family: "Independent Sans"; }
    @media screen and (max-width: 47.9375em) {
      #nav-panel ul {
        margin: 0 -1rem; } }
    @media screen and (max-width: 63.9375em) {
      #nav-panel ul ul {
        display: flex;
        flex-direction: column;
        padding: 0 35px;
        -webkit-columns: 2;
        -moz-columns: 2;
        columns: 2;
        background-color: #f2f2f2; } }
    @media screen and (max-width: 47.9375em) {
      #nav-panel ul ul li:not(:last-child) {
        border-bottom: 1px solid #888888; } }
    #nav-panel ul ul li:not(:last-child) a {
      color: #000000; }
  #nav-panel ul.topics > li:not(.expanded) {
    border-bottom: 1px solid #000000; }
  #nav-panel ul.topics > li.expanded {
    max-height: 600px;
    padding-bottom: 20px;
    padding-top: 0;
    color: #ffffff; }
    #nav-panel ul.topics > li.expanded > a {
      background-color: #000000;
      color: #ffffff;
      border-bottom: 1px solid #cccccc; }
      #nav-panel ul.topics > li.expanded > a:hover {
        color: #ec1a2e; }
      .menu-visible #nav-panel ul.topics > li.expanded > a {
        color: #ffffff; }
        .menu-visible #nav-panel ul.topics > li.expanded > a:hover {
          color: #ec1a2e; }
  #nav-panel ul.topics > li > ul li {
    position: relative; }
    #nav-panel ul.topics > li > ul li:before {
      position: absolute;
      font-family: "icomoon";
      top: 10px;
      color: #000000; }
      [dir="rtl"] #nav-panel ul.topics > li > ul li:before {
        left: 0;
        content: ""; }
      [dir="ltr"] #nav-panel ul.topics > li > ul li:before {
        right: 0;
        content: ""; }
  #nav-panel ul.topics > li > ul a {
    color: #ffffff;
    display: block;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    padding: 6px 0; }
    [dir="rtl"] #nav-panel ul.topics > li > ul a {
      font-weight: 400; }
    @media screen and (max-width: 47.9375em) {
      #nav-panel ul.topics > li > ul a {
        padding: 13px 0;
        color: #000000; } }
    @media print, screen and (min-width: 64em) {
      #nav-panel ul.topics > li > ul a {
        font-size: 14px;
        font-weight: 300; } }

#nav-panel form.search {
  display: none; }

@media print, screen and (min-width: 64em) {
  #nav-panel .topics .toggle {
    display: none; } }

#nav-panel .topics .expanded .toggle span {
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg); }

#nav-panel ul.topics > li {
  max-height: 40px;
  overflow: hidden;
  position: relative;
  border-bottom: 1px solid #ffffff; }
  #nav-panel ul.topics > li .toggle {
    content: "\e907";
    position: absolute;
    height: 100%;
    top: 0;
    right: 2px;
    width: 40%;
    text-align: right;
    cursor: pointer;
    z-index: 1;
    padding-right: 20px;
    padding-left: 20px; }
    #nav-panel ul.topics > li .toggle i {
      font-size: 1rem;
      line-height: 40px; }
    #nav-panel ul.topics > li .toggle:hover {
      color: #ec1a2e; }

#nav-panel ul.topics > li > a {
  color: #ffffff;
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  padding: 9px 20px;
  text-transform: uppercase; }
  [dir="rtl"] #nav-panel ul.topics > li > a {
    font-weight: 800; }
  @media print, screen and (min-width: 64em) {
    #nav-panel ul.topics > li > a {
      font-size: 13px;
      padding: 9px 0; } }
  @media screen and (max-width: 47.9375em) {
    .menu-visible #nav-panel ul.topics > li > a {
      color: #000000; } }

#nav-panel ul.topics > li > a:hover {
  color: #ec1a2e; }

/*@media only screen and (max-width: 619px) {
  #nav-panel ul.topics > li > ul {
    -webkit-columns: 2;
    -moz-columns: 2;
    columns: 2;
  }
}

@media only screen and (min-width: 620px) and (max-width: 929px) {
  #nav-panel ul.topics > li > ul {
    -webkit-columns: 3;
    -moz-columns: 3;
    columns: 3;
  }
}*/
#nav-panel ul.topics > li > ul a:hover {
  color: #ec1a2e; }

#nav-panel .socials-area {
  font-size: 18px;
  line-height: 22px;
  padding: 13px 0; }

#nav-panel .socials-area li {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 10px; }

#nav-panel .socials-area h6 {
  margin-bottom: 0;
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
  font-weight: 400;
  font-size: 1.125rem; }

#nav-panel .socials-area .socials-btns {
  display: inline-block;
  vertical-align: middle; }

#nav-panel .socials-area a:hover {
  background: #ec1a2e; }

#nav-panel .secButtons {
  list-style: none;
  margin-top: 15px;
  text-align: left; }

#nav-panel .secButtons li {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 10px; }

.subscribe-field {
  display: none; }
  @media screen and (max-width: 39.375em) {
    .subscribe-field {
      display: block;
      margin-bottom: 16px;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      font-family: "Independent Sans"; }
      .subscribe-field strong {
        font-weight: 700; }
      .subscribe-field a {
        color: #281E1E; }
        .subscribe-field a:hover {
          color: #ec1a2e; } }

@media print, screen and (min-width: 64em) {
  #nav-panel > ul:after,
  #nav-panel > ul:before {
    content: "";
    display: table; }
  #nav-panel > ul:after {
    clear: both; }
  #nav-panel .socials-area {
    border-top: 1px solid #ffffff;
    color: #000; }
  #nav-panel .secButtons {
    text-align: right; }
  #nav-panel ul.topics > li {
    max-height: none;
    padding-bottom: 20px;
    float: left;
    width: 154px;
    margin: 0 20px;
    border: none; }
  #nav-panel ul.topics > li > a {
    border-bottom: 1px solid #ffffff;
    margin-bottom: 10px; }
  #nav-panel ul.topics > li:nth-child(6) {
    clear: left; } }

#notfound {
  position: relative;
  min-height: 450px; }

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.notfound {
  max-width: 710px;
  width: 100%;
  padding-left: 190px;
  line-height: 1.4;
  font-family: "Independent Sans"; }

.notfound .input-group {
  margin-bottom: 0; }

.notfound .notfound-404 {
  position: absolute;
  left: 0;
  top: 0;
  width: 150px;
  height: 150px; }

.notfound .notfound-404 h1 {
  color: #ec1a2e;
  font-size: 150px;
  margin: 0px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%; }

.notfound h2 {
  color: #292929;
  font-size: 28px;
  text-transform: uppercase;
  margin-top: 0; }

.notfound p {
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 15px;
  color: #333; }

.notfound a {
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background: #fff;
  display: inline-block;
  padding: 15px 30px;
  border-radius: 40px;
  color: #292929;
  -webkit-box-shadow: 0px 4px 15px -5px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 4px 15px -5px rgba(0, 0, 0, 0.3);
  -webkit-transition: 0.2s all;
  transition: 0.2s all; }

.notfound a:hover {
  color: #fff;
  background-color: #ec1a2e; }

@media screen and (max-width: 47.9375em) {
  .notfound {
    text-align: center; }
  .notfound .notfound-404 {
    position: relative;
    width: 100%;
    margin-bottom: 15px; }
  .notfound {
    padding-left: 15px;
    padding-right: 15px; } }

@media screen and (max-width: 47.9375em) {
  .main-header-inner {
    position: relative;
    height: 40px; } }

@media screen and (max-width: 47.9375em) {
  .main-header__navbar {
    display: flex;
    justify-content: center;
    gap: 30px;
    align-items: center; } }

@media screen and (max-width: 47.9375em) {
  .main-header__navbar .nav-container {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 15px;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */ }
    .main-header__navbar .nav-container::-webkit-scrollbar {
      display: none; } }

@media screen and (max-width: 47.9375em) {
  .main-header__navbar .nav-container > .menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 50px;
    justify-content: flex-start;
    flex-wrap: nowrap; }
    .main-header__navbar .nav-container > .menu li {
      display: block;
      position: relative; } }

#main-footer {
  margin: 2rem 1rem;
  padding: 2rem 1.25rem;
  border-top: 1px solid #dadfe1;
  color: #281E1E; }
  @media print, screen and (min-width: 64em) {
    #main-footer {
      padding-left: 40px;
      padding-right: 40px; } }
  .eng-theme #main-footer,
  .turkish-theme #main-footer {
    font-size: 14px; }
  #main-footer h6 {
    font-family: "Independent Sans";
    font-weight: 500;
    font-size: inherit;
    text-transform: uppercase; }
    [dir="rtl"] #main-footer h6 {
      font-weight: 800; }
  #main-footer ul.menu.vertical {
    font-family: "Independent Sans";
    font-weight: 300;
    list-style: none;
    margin-left: 0;
    margin-bottom: 1rem; }
    [dir="rtl"] #main-footer ul.menu.vertical {
      font-weight: 400; }
    #main-footer ul.menu.vertical a {
      display: block;
      color: #281E1E;
      padding: 6px 0;
      line-height: 1.1; }
      #main-footer ul.menu.vertical a:hover {
        color: #ec1a2e; }

.main-footer-before {
  margin-bottom: 1.5rem; }

.main-footer-inner > ul {
  list-style: none;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%; }
  @media (min-width: 768px) {
    .main-footer-inner > ul {
      grid-template-columns: 1fr 1fr 1fr 1fr; } }
  @media (min-width: 1000px) {
    .main-footer-inner > ul {
      align-items: flex-start;
      display: flex;
      flex-wrap: wrap; } }
  .main-footer-inner > ul > li {
    width: 100%;
    margin-bottom: 20px; }
    .main-footer-inner > ul > li:nth-of-type(4) {
      order: 5; }
    @media (min-width: 768px) {
      .main-footer-inner > ul > li {
        grid-row: span 2;
        width: auto; } }
    @media (min-width: 768px) {
      .main-footer-inner > ul > li:nth-of-type(2), .main-footer-inner > ul > li:nth-of-type(3) {
        grid-row: span 1;
        -ms-grid-row-span: 1; }
      .main-footer-inner > ul > li:nth-of-type(3) {
        order: 5; }
      .main-footer-inner > ul > li:nth-of-type(4) {
        order: 0; } }
    @media (min-width: 1000px) {
      .main-footer-inner > ul > li {
        width: 50%;
        margin-bottom: 20px;
        flex: 1 0 145px; }
        .main-footer-inner > ul > li:nth-of-type(3) {
          order: 0; } }
    .main-footer-inner > ul > li:first-of-type {
      grid-row: span 2;
      -ms-grid-row-span: 2; }

.main-footer-after {
  padding: 0.5rem 0;
  line-height: 1.5; }

.logo-footer img {
  height: 80px; }

li .logo-footer {
  margin-bottom: 1.5rem; }

.footer-links {
  margin-left: 0;
  margin-bottom: 2rem;
  margin-top: -7px;
  list-style: none;
  clear: left;
  -webkit-columns: 2;
  -moz-columns: 2;
  columns: 2;
  -webkit-column-gap: 0;
  -moz-column-gap: 0;
  column-gap: 0; }
  @media screen and (max-width: 47.9375em) {
    .footer-links {
      padding-top: 2rem; } }
  .footer-links a {
    font-size: 14px;
    line-height: 32px;
    display: block;
    color: #281E1E; }
    .footer-links a:hover {
      color: #ec1a2e; }
  @media only screen and (min-width: 450px) {
    .footer-links {
      -webkit-columns: 3;
      -moz-columns: 3;
      columns: 3;
      -webkit-column-gap: 20px;
      -moz-column-gap: 20px;
      column-gap: 20px; } }
  @media print, screen and (min-width: 48em) {
    .footer-links {
      clear: none;
      -webkit-columns: 2;
      -moz-columns: 2;
      columns: 2; } }
  @media print, screen and (min-width: 64em) {
    .footer-links {
      float: left;
      width: auto;
      clear: none;
      -webkit-columns: 3;
      -moz-columns: 3;
      columns: 3;
      -webkit-column-gap: 40px;
      -moz-column-gap: 40px;
      column-gap: 40px; } }

@media screen and (max-width: 47.9375em) {
  .footer-menu {
    padding-top: 1rem; } }

.footer-menu a {
  font-size: 14px;
  line-height: 1.2;
  display: block;
  color: #281E1E; }
  .footer-menu a:hover {
    color: #ec1a2e; }

.copyright {
  text-align: left;
  padding: 0 1rem;
  font-size: 13px;
  color: #888888; }

.footer-extra-links {
  font-size: 12px; }
  .footer-extra-links a {
    padding: 0 0.75rem;
    color: #ffffff;
    border-right: 1px solid #ffffff; }
    .footer-extra-links a:hover {
      text-decoration: underline; }

@media only screen {
  .no-spacer {
    margin: 0 !important; }
  .no-top-spacer {
    margin-top: 0 !important;
    padding-top: 0 !important; }
  .no-bottom-spacer {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important; }
  .no-left-spacer {
    margin-left: 0 !important;
    padding-left: 0 !important; }
  .no-right-spacer {
    margin-right: 0 !important;
    padding-right: 0 !important; }
  .top-spacer--xl {
    margin-top: 3rem !important; }
  .top-spacer--l {
    margin-top: 2.5rem !important; }
  .top-spacer--m {
    margin-top: 2rem !important; }
  .top-spacer {
    margin-top: 1.5rem !important; }
  .top-spacer--s {
    margin-top: 1rem !important; }
  .top-spacer--xs {
    margin-top: 0.5rem !important; }
  .bottom-spacer--xl {
    margin-bottom: 3rem !important; }
  .bottom-spacer--l {
    margin-bottom: 2.5rem !important; }
  .bottom-spacer--m {
    margin-bottom: 2rem !important; }
  .bottom-spacer {
    margin-bottom: 1.5rem !important; }
  .bottom-spacer--s {
    margin-bottom: 16px !important; }
  .bottom-spacer--xs {
    margin-bottom: 0.5rem !important; } }

.small-up-1 .column:last-child:last-child:last-child, .small-up-1 .columns:last-child:last-child:last-child,
.small-up-1 .columns:last-child:last-child:last-child {
  float: left; }

.small-up-2 .column:last-child:last-child:last-child, .small-up-2 .columns:last-child:last-child:last-child,
.small-up-2 .columns:last-child:last-child:last-child {
  float: left; }

.small-up-3 .column:last-child:last-child:last-child, .small-up-3 .columns:last-child:last-child:last-child,
.small-up-3 .columns:last-child:last-child:last-child {
  float: left; }

.small-up-4 .column:last-child:last-child:last-child, .small-up-4 .columns:last-child:last-child:last-child,
.small-up-4 .columns:last-child:last-child:last-child {
  float: left; }

.small-up-5 .column:last-child:last-child:last-child, .small-up-5 .columns:last-child:last-child:last-child,
.small-up-5 .columns:last-child:last-child:last-child {
  float: left; }

.small-up-6 .column:last-child:last-child:last-child, .small-up-6 .columns:last-child:last-child:last-child,
.small-up-6 .columns:last-child:last-child:last-child {
  float: left; }

.small-up-7 .column:last-child:last-child:last-child, .small-up-7 .columns:last-child:last-child:last-child,
.small-up-7 .columns:last-child:last-child:last-child {
  float: left; }

.small-up-8 .column:last-child:last-child:last-child, .small-up-8 .columns:last-child:last-child:last-child,
.small-up-8 .columns:last-child:last-child:last-child {
  float: left; }

@media print, screen and (min-width: 48em) {
  .medium-up-1 .column:last-child:last-child:last-child, .medium-up-1 .columns:last-child:last-child:last-child,
  .medium-up-1 .columns:last-child:last-child:last-child {
    float: left; }
  .medium-up-2 .column:last-child:last-child:last-child, .medium-up-2 .columns:last-child:last-child:last-child,
  .medium-up-2 .columns:last-child:last-child:last-child {
    float: left; }
  .medium-up-3 .column:last-child:last-child:last-child, .medium-up-3 .columns:last-child:last-child:last-child,
  .medium-up-3 .columns:last-child:last-child:last-child {
    float: left; }
  .medium-up-4 .column:last-child:last-child:last-child, .medium-up-4 .columns:last-child:last-child:last-child,
  .medium-up-4 .columns:last-child:last-child:last-child {
    float: left; }
  .medium-up-5 .column:last-child:last-child:last-child, .medium-up-5 .columns:last-child:last-child:last-child,
  .medium-up-5 .columns:last-child:last-child:last-child {
    float: left; }
  .medium-up-6 .column:last-child:last-child:last-child, .medium-up-6 .columns:last-child:last-child:last-child,
  .medium-up-6 .columns:last-child:last-child:last-child {
    float: left; }
  .medium-up-7 .column:last-child:last-child:last-child, .medium-up-7 .columns:last-child:last-child:last-child,
  .medium-up-7 .columns:last-child:last-child:last-child {
    float: left; }
  .medium-up-8 .column:last-child:last-child:last-child, .medium-up-8 .columns:last-child:last-child:last-child,
  .medium-up-8 .columns:last-child:last-child:last-child {
    float: left; } }

@media print, screen and (min-width: 64em) {
  .large-up-1 .column:last-child:last-child:last-child, .large-up-1 .columns:last-child:last-child:last-child,
  .large-up-1 .columns:last-child:last-child:last-child {
    float: left; }
  .large-up-2 .column:last-child:last-child:last-child, .large-up-2 .columns:last-child:last-child:last-child,
  .large-up-2 .columns:last-child:last-child:last-child {
    float: left; }
  .large-up-3 .column:last-child:last-child:last-child, .large-up-3 .columns:last-child:last-child:last-child,
  .large-up-3 .columns:last-child:last-child:last-child {
    float: left; }
  .large-up-4 .column:last-child:last-child:last-child, .large-up-4 .columns:last-child:last-child:last-child,
  .large-up-4 .columns:last-child:last-child:last-child {
    float: left; }
  .large-up-5 .column:last-child:last-child:last-child, .large-up-5 .columns:last-child:last-child:last-child,
  .large-up-5 .columns:last-child:last-child:last-child {
    float: left; }
  .large-up-6 .column:last-child:last-child:last-child, .large-up-6 .columns:last-child:last-child:last-child,
  .large-up-6 .columns:last-child:last-child:last-child {
    float: left; }
  .large-up-7 .column:last-child:last-child:last-child, .large-up-7 .columns:last-child:last-child:last-child,
  .large-up-7 .columns:last-child:last-child:last-child {
    float: left; }
  .large-up-8 .column:last-child:last-child:last-child, .large-up-8 .columns:last-child:last-child:last-child,
  .large-up-8 .columns:last-child:last-child:last-child {
    float: left; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 69px; }
  @media print, screen and (min-width: 64em) {
    body {
      padding-top: 115px; }
      [dir="rtl"] body {
        padding-top: 122px; } }
  body.persian-simple {
    padding: 0; }

.card.card-item.card-podcast {
  height: calc(100% - 2rem); }

.gutters-1x {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }
  .gutters-1x > .cell {
    padding-right: 0.625rem;
    padding-left: 0.625rem; }

.gutters-2x {
  margin-right: -1.25rem;
  margin-left: -1.25rem; }
  .gutters-2x > .cell {
    padding-right: 1.25rem;
    padding-left: 1.25rem; }

.gutters-margin-1x {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }
  .gutters-margin-1x .cell {
    margin-right: 0.625rem;
    margin-left: 0.625rem; }

.gutters-margin-2x .cell {
  margin-right: 1.25rem;
  margin-left: 1.25rem; }

/*@include breakpoint(large) {
  [dir="rtl"] {
    .large-uncentered, .large-push-0, .large-pull-0 {
      float: $opposite-direction;
      margin-left: 0;
      margin-right: 0;
      position: static;
    }
  }
}*/
.vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.center-element {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

.round {
  border-radius: 1000px; }

.use-loader {
  height: 360px;
  overflow: hidden;
  background: url(../img/ajax-loader.gif) no-repeat center center transparent; }
  .use-loader > div {
    opacity: 0;
    visibility: hidden;
    height: 400px; }

.use-loader.slick-initialized.slick-loaded {
  background: none;
  height: auto;
  overflow: visible; }
  .use-loader.slick-initialized.slick-loaded > div {
    height: auto; }
  .use-loader.slick-initialized.slick-loaded.slider-nav {
    background: #000000; }

.use-loader.slick-initialized.slick-loaded .slick-list,
.use-loader.slick-initialized.slick-loaded button {
  opacity: 1;
  visibility: visible;
  transition: all 0.25s ease-in; }

.round {
  border-radius: 1000px;
  overflow: hidden; }

/*
.input-group.with-radius > *:first-child {
  border-radius: 0 5px 5px 0;
}

.input-group.with-radius > *:last-child > * {
  border-radius: 5px 0 0 5px;
}
*/
.show-for-printable-area {
  display: none; }

input.radius,
.input-group > input:first-child {
  border-radius: 4px; }

.search-form input.input-group-field {
  border-radius: 4px !important; }

textarea {
  height: 10rem; }

.arial-heading {
  font-family: Arial, sans-serif; }

.font-primary {
  font-family: "Independent Serif"; }

.font-secondary {
  font-family: "Independent Sans"; }

a {
  transition: color 0.25s ease 0s, background-color 0.25s ease 0s, opacity 0.25s ease 0s; }

h1,
h2,
h3,
h4 {
  line-height: 1.2; }
  [dir="rtl"] h1, [dir="rtl"]
  h2, [dir="rtl"]
  h3, [dir="rtl"]
  h4 {
    line-height: 1.4; }
  .urdu-theme h1, .urdu-theme
  h2, .urdu-theme
  h3, .urdu-theme
  h4 {
    line-height: 1.2; }

/*
.off-canvas {
  height: 100%;
}

.js-off-canvas-exit {
  z-index: 10;
}
*/
/*[dir="rtl"] .close-button {
  right: auto;
  left: 1rem;
}*/
/*.placeholder {
  font-size: 0.875rem;
  color: #aaa;
}*/
p {
  font-size: 1rem; }

figure {
  margin: 0 0 0.5rem; }
  figure.round {
    overflow: hidden; }

.leaderboard-ad {
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0; }

button.radius,
.button.radius {
  border-radius: 5px; }

button.secondary:hover,
.button.secondary:hover {
  background-color: #ec1a2e; }

button.gray,
.button.gray {
  background-color: #d6d6d6;
  color: #000000; }
  button.gray:hover,
  .button.gray:hover {
    background-color: #281E1E;
    color: #ffffff; }

/* ------ navigation, panel and sticky header ------ START ------ */
#sticky-header {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: -100px;
  width: 100%;
  z-index: 100;
  transition: all 0.4s ease 0s;
  background-color: #ffffff; }
  #sticky-header.stuck-header {
    visibility: visible;
    opacity: 1;
    top: 0;
    display: block;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px;
    box-shadow: 0 1px 10px 0 #cccccc;
    /*~ .canvas-panel {
      top: 40px;
    }*/ }

.sticky-header-spacer {
  display: none;
  height: auto;
  visibility: hidden; }

.toggle-nav-panel {
  position: relative;
  z-index: 1;
  font-size: 1.75rem;
  cursor: pointer;
  color: #281E1E;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  height: 100%;
  padding: 0 1.5rem; }
  .toggle-nav-panel:hover {
    color: #ec1a2e; }

/* ------ navigation, panel and sticky header ------ END ------ */
.container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  overflow-x: hidden; }

main,
header,
footer {
  position: relative; }

main {
  margin: 0 auto 3rem; }
  .persian-simple main {
    padding: 0; }
  @media screen and (max-width: 47.9375em) {
    main .grid-container {
      padding-right: 1rem;
      padding-left: 1rem; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    main .grid-container {
      padding-right: 0.75rem;
      padding-left: 0.75rem;
      max-width: 768px; } }

.mobile-webview {
  padding: 0; }
  [dir="rtl"] .mobile-webview {
    padding: 0; }
  .mobile-webview main {
    padding: 0;
    margin: 0; }
    [dir="rtl"] .mobile-webview main {
      padding: 0;
      margin: 0; }
  .mobile-webview .entry-header.has-avatar .entry-title h1,
  .mobile-webview .entry-meta-field.entry-meta-author > a {
    font-style: normal; }

@media print, screen and (min-width: 64em) {
  .static-column-wrap {
    width: 640px; } }

.equalise-columns .content-wrap, .equalise-columns
.static-column-wrap {
  margin-bottom: 1rem; }
  @media print, screen and (min-width: 64em) {
    .equalise-columns .content-wrap, .equalise-columns
    .static-column-wrap {
      margin-bottom: 0;
      height: 100%; } }

header#main-header {
  background-color: #ffffff;
  position: fixed;
  z-index: 5000000;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); }

.dropdown-toggle {
  vertical-align: middle;
  cursor: pointer;
  line-height: 36px;
  height: 100%;
  font-weight: 500;
  padding: 0 22px; }
  .dropdown-toggle:hover {
    color: #ec1a2e; }

.dropdown-menu {
  list-style: none;
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  width: 178px;
  background-color: #ffffff;
  border-color: rgba(218, 223, 225, 0.5);
  border-style: solid;
  border-width: 1px 0 1px 1px; }
  [dir="rtl"] .dropdown-menu {
    border-width: 1px 1px 1px 0; }
  .dropdown-menu li {
    line-height: 1; }
    .dropdown-menu li:hover:after {
      content: " ";
      width: 5px;
      position: absolute;
      bottom: 0;
      right: 0;
      height: 100%;
      background-color: #ec1a2e;
      display: none; }
    .dropdown-menu li:last-child {
      font-family: Tahoma;
      font-size: 16px; }
    .dropdown-menu li a {
      color: #281E1E;
      font-size: 16px;
      display: block;
      padding: 16px 0 16px 24px; }
      [dir="rtl"] .dropdown-menu li a {
        padding: 16px 24px 16px 0; }
      .dropdown-menu li a i {
        font-size: 1.25rem;
        margin-right: 1rem; }
      .dropdown-menu li a:hover {
        color: #ec1a2e; }
  .dropdown-menu.open {
    display: block; }

.check-icon {
  padding: 0 0.25rem; }

.search-form {
  background: rgba(0, 0, 0, 0.8);
  display: none;
  clear: both;
  padding: 15px 25px 15px 18px;
  position: fixed;
  width: 90%;
  z-index: 1;
  right: 0; }
  .search-form > div {
    position: relative; }
  .search-form.open {
    display: block; }
  @media print, screen and (min-width: 48em) {
    .search-form {
      width: 50%; } }
  .search-form .search-form-inner {
    position: relative; }
  .search-form .form-field,
  .search-form .form-item.form-type-textfield {
    margin: 0;
    padding: 0; }
    .search-form .form-field input,
    .search-form .form-item.form-type-textfield input {
      width: 100%;
      border: 0;
      line-height: 1;
      padding: 6px;
      height: 1.75rem;
      font-size: 14px; }
  .search-form .button,
  .search-form input[type="submit"] {
    font-family: "icomoon";
    font-size: 0.75rem;
    border: none;
    background-color: #ec1a2e;
    color: #ffffff;
    position: absolute;
    right: 0;
    top: -1px;
    display: block;
    outline: 0;
    bottom: 0;
    line-height: 1; }

.main-header-before {
  border-bottom: 1px solid rgba(218, 223, 225, 0.7); }

.mag-meta {
  padding-top: 0.5rem; }

.subheader-block1 {
  height: 100%; }
  .subheader-block1 > ul {
    font-family: "Independent Sans";
    height: 100%; }
    .eng-theme .subheader-block1 > ul,
    .turkish-theme .subheader-block1 > ul {
      font-size: 13px; }
    .subheader-block1 > ul > li {
      position: relative; }
      .subheader-block1 > ul > li:last-child {
        border: 0; }
      .subheader-block1 > ul > li:after {
        content: " ";
        height: 2px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #ec1a2e;
        display: none; }
      .subheader-block1 > ul > li:hover:after {
        display: block; }
      @media screen and (max-width: 47.9375em) {
        .subheader-block1 > ul > li.account, .subheader-block1 > ul > li.edition {
          display: none; } }
      .subheader-block1 > ul > li > a {
        text-transform: uppercase;
        display: block;
        padding: 0;
        height: 100%; }
        .subheader-block1 > ul > li > a .button {
          padding: 0; }
      .subheader-block1 > ul > li.subscription {
        display: flex;
        align-items: center;
        text-transform: uppercase; }
        @media screen and (max-width: 39.375em) {
          .subheader-block1 > ul > li.subscription {
            display: none; } }
        .subheader-block1 > ul > li.subscription:after {
          display: none; }
        .subheader-block1 > ul > li.subscription a {
          height: auto;
          color: #281E1E;
          padding: 0 1.25rem 0 0; }
          [dir="rtl"] .subheader-block1 > ul > li.subscription a {
            padding: 0 0 0 1.25rem; }
          .subheader-block1 > ul > li.subscription a:hover {
            color: #ec1a2e; }
          .subheader-block1 > ul > li.subscription a strong {
            padding-left: 6px; }
      .main-header-before .subheader-block1 > ul > li.quick-search {
        /*@include breakpoint(small only) {
            display: none;
          }*/ }
        @media print, screen and (min-width: 64em) {
          .main-header-before .subheader-block1 > ul > li.quick-search {
            display: none; } }
      .subheader-block1 > ul > li.quick-search i {
        vertical-align: middle;
        font-size: 1.25rem; }
      .subheader-block1 > ul > li.edition button {
        padding: 0 1.625rem; }
      .subheader-block1 > ul > li.edition i {
        vertical-align: middle;
        font-size: 1.5rem; }
      .subheader-block1 > ul > li.account i {
        vertical-align: middle;
        font-size: 1.5rem; }

.subheader-block {
  height: 100%; }
  .subheader-block > ul {
    font-family: "Independent Sans";
    height: 100%; }
    .eng-theme .subheader-block > ul,
    .turkish-theme .subheader-block > ul {
      font-size: 13px; }
    .subheader-block > ul > li {
      position: relative;
      border-right: 1px solid rgba(218, 223, 225, 0.7); }
      .subheader-block > ul > li:last-child {
        border: 0; }
      .subheader-block > ul > li:after {
        content: " ";
        height: 2px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #ec1a2e;
        display: none; }
      .subheader-block > ul > li:hover:after {
        display: block; }
      @media screen and (max-width: 47.9375em) {
        .subheader-block > ul > li.account, .subheader-block > ul > li.edition {
          display: none; } }
      .subheader-block > ul > li > a {
        text-transform: uppercase;
        display: block;
        padding: 0;
        height: 100%; }
        .subheader-block > ul > li > a .button {
          padding: 0; }
      .subheader-block > ul > li.subscription {
        display: flex;
        align-items: center;
        text-transform: uppercase; }
        @media screen and (max-width: 39.375em) {
          .subheader-block > ul > li.subscription {
            display: none; } }
        .subheader-block > ul > li.subscription:after {
          display: none; }
        .subheader-block > ul > li.subscription a {
          height: auto;
          color: #281E1E;
          padding: 0 1.25rem 0 0; }
          [dir="rtl"] .subheader-block > ul > li.subscription a {
            padding: 0 0 0 1.25rem; }
          .subheader-block > ul > li.subscription a:hover {
            color: #ec1a2e; }
          .subheader-block > ul > li.subscription a strong {
            padding-left: 6px; }
      .main-header-before .subheader-block > ul > li.quick-search {
        /*@include breakpoint(small only) {
            display: none;
          }*/ }
        @media print, screen and (min-width: 64em) {
          .main-header-before .subheader-block > ul > li.quick-search {
            display: none; } }
      .subheader-block > ul > li.quick-search i {
        vertical-align: middle;
        font-size: 1.25rem; }
      .subheader-block > ul > li.edition button {
        padding: 0 1.625rem; }
      .subheader-block > ul > li.edition i {
        vertical-align: middle;
        font-size: 1.5rem; }
      .subheader-block > ul > li.account i {
        vertical-align: middle;
        font-size: 1.5rem; }

.logo {
  margin: 12px 0;
  padding-left: 16px; }
  .logo img {
    height: 27px; }
    [dir="rtl"] .logo img,
    .turkish-theme .logo img {
      height: 44px; }
  @media print, screen and (min-width: 64em) {
    .logo img {
      height: 34px; }
      [dir="rtl"] .logo img,
      .turkish-theme .logo img {
        height: 54px; } }

@media screen and (min-width: 21.875em) {
  .logo-txt {
    margin-left: 0.25rem; } }

.holder-area {
  position: relative; }
  header .holder-area,
  .subheader-block .holder-area {
    height: 30px;
    width: 34px;
    display: inline-block;
    vertical-align: middle; }

.current-time,
.current-issue-number {
  font-family: Arial;
  font-size: 14px; }

.entry-comments {
  margin-bottom: 2rem; }
  .entry-comments .box-title {
    margin-bottom: 0.5rem; }
    .entry-comments .box-title h3,
    .entry-comments .box-title h4 {
      color: #281E1E; }
  .entry-comments a {
    color: #000000; }
    .entry-comments a:hover {
      color: #ec1a2e !important; }

.comments-container {
  background-color: #f2f2f2;
  border: 2px solid #cccccc;
  padding: 0 0.5rem 0.5rem;
  margin-bottom: 2rem;
  margin-top: 1.5rem; }

.comments-head {
  text-align: center;
  position: relative;
  margin-top: -22px !important;
  margin-bottom: 1rem; }
  .comments-head a {
    display: block; }
  .comments-head i {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 1.5rem;
    color: #ffffff;
    background: #ec1a2e;
    border-radius: 50%; }
  .comments-head h4,
  .comments-head h5 {
    color: #000000;
    margin: 1rem 0 0; }

.comments-inner {
  display: none;
  background: #f5f5f5;
  border: 1px solid #dadfe1;
  padding: 0.9375rem;
  color: #707070; }
  .comments-inner .subheader {
    margin-bottom: 1.25rem; }
  .comments-inner button {
    margin-bottom: 0; }
  .comments-inner label {
    margin-bottom: 0; }

.custom-icon-minus:before {
  content: "-";
  font-family: verdana;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.1; }

.grid-items.style-v2 .article-item {
  padding: 1rem 0;
  border-top: 1px solid #dadfe1;
  border-bottom: 1px dashed #bfbfbf; }

.grid-items.has-bottom-border .article-item {
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #281E1E; }

.grid-items-wrapper {
  margin-bottom: 1rem; }

.fill-content, .headline-slider .slick-slide img, .small-headline-slider_item a > img {
  display: block;
  height: 0;
  max-height: 100%;
  max-width: 100%;
  min-height: 100%;
  min-width: 100%;
  width: 0;
  margin: auto;
  object-fit: cover; }
  .fill-content.abs-element, .headline-slider .slick-slide img.abs-element, .small-headline-slider_item a > img.abs-element {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }
  .article-item-img .fill-content, .article-item-img .headline-slider .slick-slide img, .headline-slider .slick-slide .article-item-img img, .article-item-img .small-headline-slider_item a > img, .small-headline-slider_item .article-item-img a > img,
  .entry-media-inner-img .fill-content,
  .entry-media-inner-img .headline-slider .slick-slide img,
  .headline-slider .slick-slide .entry-media-inner-img img,
  .entry-media-inner-img .small-headline-slider_item a > img,
  .small-headline-slider_item .entry-media-inner-img a > img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; }

.sizer {
  display: block;
  padding-top: 66.6667%;
  /*.entry-media-inner-img & {
    padding-top: 40%;
  }*/ }
  .entry-media .sizer {
    padding-top: 75%; }
  .with-square-sizer .sizer {
    padding-top: 100%; }
  .node-type-cartoons .sizer,
  .page-cartoons .sizer,
  .article-block-cartoon .sizer {
    padding-top: 72%; }

.article-item {
  position: relative; }
  .article-item.article-hero {
    height: 100%; }
    .block-wrapper .article-item.article-hero {
      margin-bottom: 0.5rem; }
    @media print, screen and (min-width: 48em) {
      .article-item.article-hero {
        margin-bottom: -6px; }
        .article-item.article-hero .article-item-img {
          height: 100%; } }
    .article-item.article-hero .article-item-content > :not(.category-field) a {
      display: block; }
    .article-item.article-hero.media-video .abs-bottom-element, .article-item.article-hero.media-audio .abs-bottom-element {
      padding-top: 24px; }
      .article-item.article-hero.media-video .abs-bottom-element:before, .article-item.article-hero.media-audio .abs-bottom-element:before {
        background: rgba(236, 26, 46, 0.8);
        border-radius: 50%;
        cursor: pointer;
        text-align: center;
        content: "\e90b";
        position: absolute;
        font-family: "icomoon";
        width: 32px;
        height: 32px;
        line-height: 32px;
        font-size: 1.25rem;
        z-index: 1;
        top: -16px;
        text-indent: 3px; }
        [dir="rtl"] .article-item.article-hero.media-video .abs-bottom-element:before, [dir="rtl"] .article-item.article-hero.media-audio .abs-bottom-element:before {
          text-indent: -3px; }
        @media print, screen and (min-width: 48em) {
          .splash-row > .grid-x > .cell.large-8 > .article-item.article-hero.media-video .abs-bottom-element:before, .splash-row > .grid-x > .cell.large-8 > .article-item.article-hero.media-audio .abs-bottom-element:before {
            text-indent: 6px;
            font-size: 2rem;
            top: -28px;
            margin: 0;
            width: 56px;
            height: 56px;
            line-height: 56px; }
            [dir="rtl"] .splash-row > .grid-x > .cell.large-8 > .article-item.article-hero.media-video .abs-bottom-element:before, [dir="rtl"] .splash-row > .grid-x > .cell.large-8 > .article-item.article-hero.media-audio .abs-bottom-element:before {
              text-indent: -6px; } }
    .article-item.article-hero.media-audio .abs-bottom-element:before {
      content: "\e91e"; }
    @media screen and (max-width: 47.9375em) {
      .splash-row .article-item.article-hero {
        margin-bottom: 0.5rem; } }
  .articles-list .article-item.article-hero {
    margin-bottom: 0; }
    @media print, screen and (min-width: 64em) {
      .articles-list .article-item.article-hero .article-item-img {
        height: 260px; } }
  @media screen and (max-width: 63.9375em) {
    .article-item.video-grid-story.img-as-bg .article-item-img {
      position: relative;
      padding-top: 56.25%; } }
  @media print, screen and (min-width: 64em) {
    .article-item.video-grid-story.img-as-bg {
      height: 360px; } }
  @media screen and (max-width: 63.9375em) {
    .article-item.full-grid-story.related-content h2 {
      font-size: 21px; } }
  .article-item.full-grid-story .article-item-category {
    margin-top: -27px;
    margin-bottom: 1rem; }
  @media screen and (max-width: 47.9375em) {
    .article-item.full-grid-story.img-as-bg .article-item-img {
      position: relative;
      padding-top: 56.25%; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .article-item.full-grid-story.img-as-bg {
      display: flex;
      flex-flow: column wrap; }
      .article-item.full-grid-story.img-as-bg .article-item-img {
        flex: 1 1 0px;
        width: auto; }
      .article-item.full-grid-story.img-as-bg .article-item-content {
        flex: 1 1 0px;
        width: auto; } }
  @media print, screen and (min-width: 48em) {
    .article-item.full-grid-story.img-as-bg {
      height: 600px; } }
  .article-item.half-grid-story {
    padding-bottom: 0.625rem;
    border-bottom: 1px solid #dadfe1; }
    @media screen and (max-width: 47.9375em) {
      .article-item.half-grid-story.img-as-bg .article-item-img {
        position: relative;
        padding-top: 56.25%; } }
    @media print, screen and (min-width: 48em) {
      .article-item.half-grid-story.img-as-bg {
        height: 290px; } }
    @media screen and (min-width: 48em) and (max-width: 63.9375em) {
      .grid-items.medium-up-3 .article-item.half-grid-story.img-as-bg {
        height: 230px; } }
  @media print, screen and (min-width: 48em) {
    .article-item.small-grid-story.img-as-bg {
      height: 230px; } }
  @media print, screen and (min-width: 48em) {
    .article-item.card-grid-story.img-as-bg {
      height: 600px; } }
  .article-item.card-grid-story .article-item-content {
    padding: 0.5rem 1.25rem;
    border: 1px solid #dadfe1; }
  .article-item.half-card-grid-story {
    margin-bottom: 0;
    height: 100%;
    border: 1px solid #d6d6d6; }
    .article-item.half-card-grid-story.img-as-bg .article-item-img {
      position: relative;
      padding-top: 56.25%; }
    .article-item.half-card-grid-story .article-item-content {
      padding: 0.5rem 0.75rem 1rem; }
  .article-item.small-grid-story .article-item-category, .article-item.half-grid-story .article-item-category, .article-item.card-grid-story .article-item-category, .article-item.half-card-grid-story .article-item-category {
    margin-top: -19px; }
  .grid-items .article-item {
    max-width: 360px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem; }
  .article-item .duration {
    position: absolute;
    bottom: 0.5rem;
    left: 0.5rem;
    font-size: 11px;
    background-color: #000000;
    padding: 4px 4px 3px;
    line-height: 1; }
  .article-item.article-video-hero .article-item-img:before {
    background: rgba(236, 26, 46, 0.8);
    border-radius: 50%;
    cursor: pointer;
    text-align: center;
    content: "\e90b";
    position: absolute;
    font-family: "icomoon";
    width: 50px;
    height: 50px;
    line-height: 48px;
    font-size: 2rem;
    z-index: 1;
    text-indent: 3px;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    margin: 0 auto; }
    [dir="rtl"] .article-item.article-video-hero .article-item-img:before {
      text-indent: -3px; }
  .article-item.article-video .article-item-img:before {
    background: rgba(236, 26, 46, 0.8);
    border-radius: 50%;
    cursor: pointer;
    text-align: center;
    content: "\e90b";
    position: absolute;
    font-family: "icomoon";
    width: 32px;
    height: 32px;
    line-height: 32px;
    font-size: 1.25rem;
    z-index: 1;
    text-indent: 3px;
    bottom: 0.5rem;
    left: 0.5rem; }
    [dir="rtl"] .article-item.article-video .article-item-img:before {
      text-indent: -3px; }

.half-card-grid-stories .cell {
  margin-bottom: 2rem; }

.article-item-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.img-as-bg .article-item-img {
  background-color: #000000;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  display: block;
  position: relative;
  height: 100%; }
  .img-as-bg .article-item-img > a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1; }

.article-item-img {
  position: relative; }
  .horoscopes-articles .article-item-img {
    margin-bottom: 0.5rem; }
  .card .article-item-img {
    margin-bottom: 0; }
  .article-item-img a:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: transparent;
    transition: color 0.25s ease 0s, background-color 0.25s ease 0s, opacity 0.25s ease 0s; }
  .article-item-img a:hover:after, .article-item-img a.hovered-img:after {
    background-color: rgba(0, 0, 0, 0.1); }

.article-item-content {
  padding-top: 0.5rem; }

.article-item-title a {
  color: #281E1E; }
  .article-item-title a:hover {
    color: #ec1a2e; }

@media print, screen and (min-width: 64em) {
  .video-grid-story .article-item-title h2 {
    font-size: 2.5rem; } }

.playlist .article-item-title h6 {
  font-size: 14px;
  font-weight: 600; }

.half-card-grid-story .article-item-title .article-item-content h4 {
  font-size: 1.25rem; }

.object-item .article-item-title h6 {
  margin-bottom: 0;
  letter-spacing: 0.22px;
  font-weight: 500; }
  .eng-theme .object-item .article-item-title h6,
  .turkish-theme .object-item .article-item-title h6 {
    font-size: 15px; }
  [dir="rtl"] .object-item .article-item-title h6 {
    font-weight: 800; }
  @media print, screen and (min-width: 48em) {
    .eng-theme .splash-row .object-item .article-item-title h6,
    .turkish-theme .splash-row .object-item .article-item-title h6 {
      font-size: 14px; } }

.block-wrapper .article-item-title h6 {
  font-weight: 300; }

.article-item-title .media-prefix {
  color: #ec1a2e;
  margin-right: 0.4rem; }

.article-item-author {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 700;
  font-style: italic;
  color: #ec1a2e; }
  .urdu-theme .article-item-author {
    font-style: normal; }
  @media screen and (max-width: 63.9375em) {
    .articles-list.cards .article-item-author {
      display: none; } }

.urdu-theme p {
  font-size: 1.5rem; }

.article-item-author1 {
  margin-top: 0.3rem;
  font-size: 14px;
  font-weight: 700;
  font-style: italic;
  color: #337e81; }
  .urdu-theme .article-item-author1 {
    font-style: normal; }
  @media screen and (max-width: 63.9375em) {
    .articles-list.cards .article-item-author1 {
      display: none; } }

.article-item-category {
  position: relative;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0.5rem;
  z-index: 1; }
  .article-item-category a {
    line-height: 16px;
    display: inline-block;
    padding: 2px 8px;
    text-transform: uppercase;
    background-color: #ec1a2e;
    color: #ffffff; }
    .article-item-category a:hover {
      background-color: #000000;
      color: #ffffff; }

.article-item-labels {
  position: absolute;
  top: 0;
  height: auto;
  z-index: 1;
  font-family: "Independent Sans";
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 8px;
  color: #ffffff;
  letter-spacing: 0.26px;
  line-height: normal;
  text-shadow: none;
  display: flex;
  font-size: 10px; }
  .eng-theme .article-item-labels,
  .turkish-theme .article-item-labels {
    font-size: 11px; }
  .article-item-labels a {
    background: #ec1a2e;
    padding: 4px 8px 2px;
    color: #ffffff;
    display: flex; }
    .article-item-labels a:hover {
      background-color: #ffffff;
      color: #ec1a2e; }

.live-blog {
  background: #fffd38;
  color: #000;
  padding: 4px 8px 2px;
  display: inline-block; }

.lead {
  padding-top: 16px; }
  .lead p {
    font-family: "Independent Sans";
    margin-bottom: 0; }
    .eng-theme .lead p,
    .turkish-theme .lead p {
      font-size: 14px; }
    @media print, screen and (min-width: 64em) {
      .eng-theme .article-hero .abs-bottom-element .lead p,
      .turkish-theme .article-hero .abs-bottom-element .lead p {
        font-size: 16px; } }

.abs-bottom-element {
  background: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  display: block;
  width: 100%;
  margin: 0;
  padding: 16px;
  position: absolute;
  bottom: 0;
  left: 0; }
  .abs-bottom-element a {
    position: relative;
    z-index: 1;
    color: #ffffff; }
    .abs-bottom-element a:hover {
      color: #ec1a2e; }
  @media screen and (max-width: 47.9375em) {
    .splash-row .abs-bottom-element {
      position: relative; } }
  .abs-bottom-element .article-item-title h4,
  .abs-bottom-element .article-item-title h5,
  .abs-bottom-element .article-item-title h6 {
    margin-bottom: 0; }
  .video-grid-story .abs-bottom-element {
    padding: 1.5rem; }
    @media print, screen and (min-width: 64em) {
      .video-grid-story .abs-bottom-element {
        padding-top: 80px; } }

.abs-bottom-element1 {
  padding: 0.75rem;
  position: relative;
  background: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  display: block;
  width: 100%;
  margin: 0;
  top: 0rem;
  backdrop-filter: blur(7px); }
  .abs-bottom-element1 a {
    position: relative;
    z-index: 1;
    color: #ffffff; }
    .abs-bottom-element1 a:hover {
      color: #ec1a2e; }
  @media print, screen and (min-width: 48em) {
    .abs-bottom-element1 {
      padding: 1.5rem;
      position: absolute;
      bottom: 0;
      left: 0; } }
  .abs-bottom-element1 .article-item-title h4,
  .abs-bottom-element1 .article-item-title h5,
  .abs-bottom-element1 .article-item-title h6 {
    margin-bottom: 0; }
  .video-grid-story .abs-bottom-element1 {
    padding: 1.5rem; }
    @media print, screen and (min-width: 64em) {
      .video-grid-story .abs-bottom-element1 {
        padding-top: 80px; } }

.play-icon {
  font-size: 1.25rem;
  padding: 8px 6px 8px 10px;
  background: rgba(236, 26, 46, 0.8);
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  left: 10px;
  position: absolute;
  top: 10px;
  line-height: 1;
  text-align: center; }
  @media print, screen and (min-width: 64em) {
    .play-icon {
      font-size: 2rem;
      padding: 12px 10px 12px 16px; } }
  .full-grid-story .play-icon,
  .card-grid-story .play-icon {
    font-size: 4rem;
    padding: 16px 14px 16px 22px; }
  .object-item .play-icon {
    font-size: 20px;
    padding: 7px 6px 7px 9px;
    top: 7px;
    left: 7px; }
  .playlist .play-icon {
    background: rgba(0, 0, 0, 0.4);
    top: 5px;
    left: 5px;
    font-size: 16px;
    padding: 6px 5px 6px 7px; }

.playlist-wrapper {
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  min-height: 245px;
  margin-bottom: 2rem; }

span.author {
  font-family: "Independent Serif";
  font-weight: 700;
  font-style: italic;
  color: #ec1a2e; }
  .urdu-theme span.author {
    font-style: normal; }

.headlines-list {
  list-style: none;
  border-left: 1px solid #fff;
  margin: 16px 0 0 8px;
  padding: 0; }
  .headlines-list a {
    display: block;
    letter-spacing: normal;
    line-height: 1.25;
    font-family: "Independent Sans";
    font-weight: 700;
    color: #ffffff; }
    .headlines-list a:hover {
      color: #ec1a2e; }
  .abs-bottom-element .headlines-list a {
    color: #ffffff; }
    .abs-bottom-element .headlines-list a:hover {
      color: #ec1a2e; }
  .half-card-grid-story .headlines-list {
    margin-top: 1.25rem; }
    .half-card-grid-story .headlines-list li {
      margin-top: 1.25rem; }
  .headlines-list.has-separator li {
    padding: 0.75rem 0 0.75rem 1.5rem;
    border-top: 1px solid #dadfe1;
    margin-top: 0; }
    .headlines-list.has-separator li:before {
      top: 13px; }
    [dir="rtl"] .headlines-list.has-separator li {
      padding: 0.75rem 1.5rem 0.75rem 0; }
  .headlines-list li {
    font-weight: 600;
    padding-left: 2.2em;
    position: relative;
    margin-top: 0.5rem;
    line-height: 1.2; }
    .eng-theme .headlines-list li,
    .turkish-theme .headlines-list li {
      font-size: 14px; }
    [dir="rtl"] .headlines-list li {
      line-height: 1.4; }
    .headlines-list li:before {
      content: "";
      position: absolute;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background-color: #ec1a2e;
      top: -1px;
      left: 8px;
      display: block !important;
      padding-right: 15px;
      background-image: url(../img/svg/white_arrow.svg);
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 16px; }
      .urdu-theme .headlines-list li:before {
        top: -7px; }
      [dir="rtl"] .headlines-list li:before {
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        filter: FlipH;
        -ms-filter: "FlipH";
        top: 2px; }

.headlines-list.hrefblack a {
  color: black; }
  .headlines-list.hrefblack a :hover {
    color: #ec1a2e; }

.date-field {
  font-size: 13px;
  color: #888888; }

@media screen and (max-width: 47.9375em) {
  section.section.bottom-spacer--s {
    margin-bottom: 0.5rem !important; } }

.section-wrapper {
  margin-bottom: 2rem; }

.section-title {
  padding-top: 20px;
  color: #000000;
  position: relative; }
  .section-title h2 {
    font-family: "Independent Sans";
    font-weight: 700;
    margin-bottom: 12px; }
    .eng-theme .section-title h2,
    .turkish-theme .section-title h2 {
      font-size: 20px; }
    .turkish-theme .section-title h2 {
      text-transform: uppercase; }
  .section-title a:not(.see-all) {
    color: #000000; }
    .section-title a:not(.see-all):hover {
      color: #ec1a2e; }
  .section-title.has-dot h2:before,
  .section-title.has-dot h3:before,
  .section-title.has-dot h4:before,
  .section-title.has-dot h5:before {
    display: inline-block;
    content: " ";
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #ec1a2e;
    margin-right: 8px; }
    .eng-theme .section-title.has-dot h2:before,
    .turkish-theme .section-title.has-dot h2:before, .eng-theme
    .section-title.has-dot h3:before,
    .turkish-theme
    .section-title.has-dot h3:before, .eng-theme
    .section-title.has-dot h4:before,
    .turkish-theme
    .section-title.has-dot h4:before, .eng-theme
    .section-title.has-dot h5:before,
    .turkish-theme
    .section-title.has-dot h5:before {
      margin-top: 1px;
      vertical-align: top; }
  .section-title.has-logo h2:before,
  .section-title.has-logo h3:before,
  .section-title.has-logo h4:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    background: transparent url("../img/logo-icon.png") no-repeat center left;
    background-size: 30px 30px;
    width: 30px;
    height: 30px;
    margin-right: 0.5rem;
    position: relative;
    top: -3px; }
    [dir="rtl"] .section-title.has-logo h2:before, [dir="rtl"]
    .section-title.has-logo h3:before, [dir="rtl"]
    .section-title.has-logo h4:before {
      top: 1px; }

.see-all {
  display: inline-block;
  color: #ec1a2e;
  font-size: 0.9em;
  font-weight: 600;
  position: absolute;
  padding-right: 21px;
  right: 0;
  top: 50%;
  line-height: 1.2;
  text-transform: capitalize;
  vertical-align: middle;
  margin-top: -9px; }
  .see-all:hover {
    color: #000000; }
    .see-all:hover:after {
      color: #000000; }
  .see-all:after {
    content: "\e90a";
    display: inline-block;
    width: 16px;
    height: 16px;
    line-height: 15px;
    font-weight: normal;
    font-family: "icomoon";
    font-size: 0.5rem;
    border-radius: 50%;
    border: 1px solid #ec1a2e;
    position: absolute;
    top: 0;
    right: 0;
    text-align: center;
    color: #ec1a2e;
    text-indent: 1px; }
    [dir="rtl"] .see-all:after {
      content: "\e908"; }

.category-field {
  font-family: "Independent Sans";
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 1.2;
  margin-bottom: 8px; }
  .eng-theme .category-field,
  .turkish-theme .category-field {
    font-size: 11px; }
  .turkish-theme .category-field {
    text-transform: capitalize; }
  [dir="rtl"] .category-field {
    font-size: 11px; }
  .urdu-theme .category-field {
    font-size: 16px; }

.category-field1 {
  font-family: "Independent Sans";
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 1.2;
  margin-bottom: 0.5rem; }
  .category-field1 a {
    color: #337e81; }
  .eng-theme .category-field1,
  .turkish-theme .category-field1 {
    font-size: 11px; }
  .turkish-theme .category-field1 {
    text-transform: capitalize; }
  [dir="rtl"] .category-field1 {
    font-size: 11px; }
  .urdu-theme .category-field1 {
    font-size: 16px; }

.media-object {
  position: relative; }

.cards .objects-list .object-item .media-object .media-object-img {
  height: 100%; }

.cards .objects-list .object-item .media-object.media-video .media-object-img:before, .cards .objects-list .object-item .media-object.media-audio .media-object-img:before {
  background: rgba(236, 26, 46, 0.8);
  color: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  content: "\e90b";
  position: absolute;
  font-family: "icomoon";
  width: 32px;
  height: 32px;
  line-height: 32px;
  font-size: 1.25rem;
  z-index: 1;
  bottom: 0;
  text-indent: 6px;
  margin: 0.5rem; }
  [dir="rtl"] .cards .objects-list .object-item .media-object.media-video .media-object-img:before, [dir="rtl"] .cards .objects-list .object-item .media-object.media-audio .media-object-img:before {
    text-indent: -3px; }
  @media print, screen and (min-width: 64em) {
    .cards .objects-list .object-item .media-object.media-video .media-object-img:before, .cards .objects-list .object-item .media-object.media-audio .media-object-img:before {
      top: -24px;
      right: 0; } }

.cards .objects-list .object-item .media-object.media-audio .media-object-img:before {
  content: "\e91e"; }

.objects-list.has-separator .object-item {
  min-height: 69px;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #d6d6d6; }
  .objects-list.has-separator .object-item:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0; }
  .objects-list.has-separator .object-item .media-object {
    position: relative;
    margin-bottom: 0; }
    .objects-list.has-separator .object-item .media-object.media-video .media-object-img:before, .objects-list.has-separator .object-item .media-object.media-audio .media-object-img:before {
      background: rgba(236, 26, 46, 0.8);
      color: #ffffff;
      border-radius: 50%;
      cursor: pointer;
      text-align: center;
      content: "\e90b";
      position: absolute;
      font-family: "icomoon";
      width: 32px;
      height: 32px;
      line-height: 32px;
      font-size: 1.25rem;
      z-index: 1;
      bottom: 0;
      text-indent: 6px;
      margin: 0.5rem; }
      [dir="rtl"] .objects-list.has-separator .object-item .media-object.media-video .media-object-img:before, [dir="rtl"] .objects-list.has-separator .object-item .media-object.media-audio .media-object-img:before {
        text-indent: -3px; }
    .objects-list.has-separator .object-item .media-object.media-audio .media-object-img:before {
      content: "\e91e"; }

.media-object-img {
  position: relative; }
  .media-object-img img {
    max-width: 128px; }
    @media print, screen and (min-width: 48em) {
      .media-object-img img {
        max-width: 103px; } }
    @media print, screen and (min-width: 64em) {
      .media-object-img img {
        max-width: 128px; } }
  .block-wrapper .media-object-img img {
    max-width: 100px; }

.entry-title h1 {
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 1.1;
  margin-top: 24px;
  margin-bottom: 24px; }
  @media print, screen and (min-width: 48em) {
    .entry-title h1 {
      font-size: 2.5rem; } }
  @media print, screen and (min-width: 64em) {
    .entry-title h1 {
      font-size: 3.375rem; } }
  .entry-video-container .entry-title h1 {
    margin-bottom: 16px; }
  [dir="rtl"] .entry-title h1 {
    line-height: 1.4;
    font-size: 1.5rem; }
    @media print, screen and (min-width: 48em) {
      [dir="rtl"] .entry-title h1 {
        font-size: 1.75rem; } }
    @media print, screen and (min-width: 64em) {
      [dir="rtl"] .entry-title h1 {
        font-size: 2.125rem; } }

.entry-subtitle {
  margin-bottom: 16px; }
  .entry-subtitle h2 {
    color: #555555;
    font-family: "Independent Sans";
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 0; }
    @media print, screen and (min-width: 48em) {
      .entry-subtitle h2 {
        font-size: 1.25rem; } }

.entry-meta {
  line-height: 1.5em;
  margin-bottom: 16px;
  display: flex;
  align-items: center; }
  .entry-video-container .entry-meta {
    margin-bottom: 0; }

.entry-meta-field {
  display: inline-block;
  vertical-align: top;
  font-weight: normal;
  font-family: "Independent Sans";
  font-size: 16px; }
  [dir="rtl"] .entry-meta-field {
    font-family: "Independent Serif"; }
  .entry-meta-field.entry-meta-author {
    display: block; }
  .entry-meta-field.entry-meta-author > a {
    font-family: "Independent Serif";
    font-style: italic;
    font-size: 18px; }
    [dir="rtl"] .entry-meta-field.entry-meta-author > a {
      font-size: 16px;
      font-style: normal; }
    .urdu-theme .entry-meta-field.entry-meta-author > a {
      font-style: normal; }
  .entry-meta-field span,
  .entry-meta-field time {
    font-weight: 300;
    color: #555555; }
    .urdu-theme .entry-meta-field span, .urdu-theme
    .entry-meta-field time {
      word-spacing: 4px; }
  .entry-meta-field a {
    font-weight: 700; }
  @media screen and (max-width: 47.9375em) {
    .entry-meta-field {
      margin-bottom: 0.2rem; } }
  .entry-meta-field:after {
    content: " |";
    padding: 0 4px 0 4px;
    color: #555555;
    line-height: 1;
    font-size: 13px;
    vertical-align: middle; }
  .entry-meta-field:last-child:after {
    display: none; }

.entry-reading-time {
  color: #555555; }

.entry-meta-comment a {
  color: #555555;
  font-weight: 300; }

.entry-meta-shares-count {
  color: #ec1a2e; }
  .entry-meta-shares-count span {
    color: #ec1a2e;
    padding-right: 4px; }

[dir="rtl"] .author-twitter {
  display: inline-block;
  direction: ltr; }

.author-twitter a {
  color: #555555;
  text-decoration: none;
  font-weight: 300;
  font-style: normal;
  font-size: 16px; }
  [dir="ltr"] .author-twitter a:before {
    content: "|";
    padding: 4px;
    color: #555;
    line-height: 1;
    font-size: 13px;
    vertical-align: middle; }
  [dir="rtl"] .author-twitter a:after {
    content: "|";
    padding: 4px;
    color: #555;
    line-height: 1;
    font-size: 13px;
    vertical-align: middle; }

.entry-meta-field {
  margin-bottom: 10px; }

@media print, screen and (min-width: 64em) {
  .entry-meta-wrapper > .grid-x > .cell.shrink {
    max-width: 60%; } }

.entry-summary {
  margin-bottom: 16px; }
  .entry-summary h4 {
    margin-bottom: 16px; }
  .entry-summary ul li {
    margin-bottom: 16px;
    font-size: 1rem; }

.entry-article-topper {
  padding: 0 1rem; }
  @media print, screen and (min-width: 64em) {
    .entry-article-topper {
      padding-right: 0.3125rem;
      padding-left: 0.3125rem;
      max-width: 61.5rem;
      margin: 0 auto;
      padding: 0; } }
  @media print, screen and (min-width: 64em) and (min-width: 48em) {
    .entry-article-topper {
      padding-right: 0.375rem;
      padding-left: 0.375rem; } }
  @media print, screen and (min-width: 64em) and (min-width: 64em) {
    .entry-article-topper {
      padding-right: 0.5rem;
      padding-left: 0.5rem; } }

.entry-header.has-avatar .entry-title h1 {
  font-style: italic; }
  [dir="rtl"] .entry-header.has-avatar .entry-title h1 {
    font-style: normal; }
  .urdu-theme .entry-header.has-avatar .entry-title h1 {
    font-style: normal; }

.entry-header.has-avatar .entry-subtitle {
  margin-top: 10px;
  margin-bottom: 10px; }
  @media screen and (max-width: 47.9375em) {
    .entry-header.has-avatar .entry-subtitle {
      margin-bottom: 1rem; } }
  @media print, screen and (min-width: 48em) {
    .entry-header.has-avatar .entry-subtitle {
      float: right;
      width: calc(100% - 157px); } }

@media print, screen and (min-width: 48em) {
  .entry-header.has-avatar .entry-meta-wrapper {
    float: right;
    width: calc(100% - 157px); } }

.entry-header.has-avatar .entry-meta-wrapper > .grid-x > .cell.shrink {
  max-width: 100%; }
  @media print, screen and (min-width: 48em) {
    .entry-header.has-avatar .entry-meta-wrapper > .grid-x > .cell.shrink {
      max-width: 75%; } }
  @media print, screen and (min-width: 64em) {
    .entry-header.has-avatar .entry-meta-wrapper > .grid-x > .cell.shrink {
      max-width: 50%; } }

.share-floater {
  position: fixed;
  display: none;
  left: 0;
  top: 20px;
  border-right: 1px solid #888888;
  padding: 10px 24px; }
  @media screen and (max-width: 63.9375em) {
    .share-floater {
      display: none; } }
  .share-floater .socials-btns {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center; }
    .share-floater .socials-btns .social-btn {
      background-color: #6d6d6d;
      font-size: 14px;
      margin: 0; }
      .share-floater .socials-btns .social-btn span {
        font-size: 14px; }

.share-block {
  margin-bottom: 1rem;
  display: inline-block;
  vertical-align: middle; }
  .entry-video-container .share-block {
    margin-bottom: 0; }
  .page-node .share-block .socials-btns {
    display: flex; }
    .page-node .share-block .socials-btns .social-btn {
      width: 30px;
      height: 30px;
      line-height: 30px;
      margin-right: 3px;
      margin-left: 0; }

.follow-block {
  margin-bottom: 1rem;
  display: inline-block;
  vertical-align: middle; }
  .entry-video-container .follow-block {
    margin-bottom: 0; }

.follow-fb-info {
  font-size: 12px;
  line-height: 14px;
  margin-left: 8px;
  color: #888;
  display: inline-block;
  vertical-align: middle; }

.caption-field,
.dnd-caption-wrapper {
  font-family: "Independent Sans";
  font-weight: 300;
  font-size: 16px;
  color: #888888;
  line-height: 1.2;
  background: #f7f7f7;
  padding: 6px 10px; }

.entry-article.shifted-area {
  position: relative;
  max-width: 800px;
  background-color: #ffffff;
  width: 100%;
  margin: 0 auto; }
  @media print, screen and (min-width: 48em) {
    .entry-article.shifted-area {
      padding-left: 82px;
      padding-right: 82px; } }
  @media print, screen and (min-width: 64em) {
    .entry-article.shifted-area {
      padding-top: 50px;
      transform: translateY(-230px); } }
  .entry-article.shifted-area .share-block {
    display: none; }
    @media print, screen and (min-width: 48em) {
      .entry-article.shifted-area .share-block {
        display: block;
        position: absolute;
        right: 90%;
        z-index: 1;
        top: 20px; } }
    @media print, screen and (min-width: 64em) {
      .entry-article.shifted-area .share-block {
        top: 220px;
        right: 100%;
        padding-right: 30px; } }
    .entry-article.shifted-area .share-block .social-btn.round {
      margin-bottom: 0.75rem; }

.content-wrap-holder {
  width: 100%; }
  @media print, screen and (min-width: 48em) {
    .content-wrap-holder {
      margin: 0 auto;
      max-width: 636px; } }
  @media print, screen and (min-width: 64em) {
    .content-wrap-holder {
      margin-left: 0;
      margin-right: 0;
      padding: 0;
      width: calc(100% - 344px); } }

.sidebar-wrap-holder {
  display: none;
  width: 308px; }
  @media print, screen and (min-width: 64em) {
    .sidebar-wrap-holder {
      display: block; } }

.entry-media {
  position: relative;
  margin-bottom: 24px; }
  .entry-video-container .entry-media {
    margin-bottom: 0; }

.entry-content {
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 1.5rem;
  /*> p:last-of-type:after {
    content: " ";
    display: inline-block;
    width: 16px;
    height: 16px;
    background: #ec1a2e;
    border-radius: 50%;
    margin-left: 6px;
    position: relative;
    top: 2px;
  }*/ }
  .entry-content > h2 {
    font-size: 21px;
    font-weight: 700; }
  .entry-content > h1,
  .entry-content > h2,
  .entry-content > h3 {
    margin: 2rem 0 18px;
    clear: both; }
  @media print, screen and (min-width: 48em) {
    .entry-content {
      font-size: 19px; } }
  [dir="rtl"] .persian-theme .entry-content {
    font-size: 18px; }
  .urdu-theme .entry-content {
    line-height: 1; }
    .urdu-theme .entry-content p {
      margin-bottom: 1rem; }
  .entry-content table {
    max-width: 100%; }
    @media screen and (max-width: 47.9375em) {
      .entry-content table {
        line-height: 1.4; }
        .entry-content table th {
          font-size: 14px; }
        .entry-content table td {
          font-size: 15px; } }

.entry-content blockquote {
  border: 0;
  font-size: 18px; }
  .entry-content blockquote > p:first-child {
    color: #888888;
    font-style: italic; }
    .urdu-theme .entry-content blockquote > p:first-child {
      font-style: normal; }
    .entry-content blockquote > p:first-child:before {
      margin-top: 0;
      background-size: contain;
      width: 38px;
      height: 16px;
      margin-bottom: 5px;
      margin-right: 0; }
    [dir="rtl"] .entry-content blockquote > p:first-child {
      font-size: 16px; }

.page-taxonomy-term-61 .entry-content > p:first-of-type:before,
blockquote > p:first-of-type:before {
  -webkit-print-color-adjust: exact;
  content: " ";
  width: 80px;
  height: 47px;
  float: left;
  background-image: url(../img/svg/quote.svg);
  background-repeat: no-repeat;
  margin: 4px 24px 24px 0; }
  [dir="rtl"] .page-taxonomy-term-61 .entry-content > p:first-of-type:before, [dir="rtl"]
  blockquote > p:first-of-type:before {
    margin: 4px 0 24px 24px;
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: "FlipH"; }

.entry-tags {
  margin-bottom: 1.5rem;
  padding: 12px 0;
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6; }
  .entry-tags span {
    text-transform: uppercase;
    font-family: "Independent Sans";
    font-size: 15px;
    font-weight: 700;
    color: #281E1E;
    margin-right: 10px; }
  .entry-tags a {
    font-size: 12px;
    line-height: 1.5;
    font-weight: 700;
    padding: 1px 8px 0;
    margin: 4px 8px 4px 0;
    text-transform: uppercase;
    font-family: "Independent Sans";
    display: inline-block; }

.gigya-newsletter {
  margin-bottom: 1.5rem; }

.entry-promoted-stories {
  margin-bottom: 1.5rem; }

.box-title {
  margin-bottom: 0.5rem;
  /*&:after {
    content: " ";
    display: block;
    background: #ec1a2e;
    height: 5px;
    width: 50px;
    margin-bottom: 8px;
  }*/ }
  .box-title h2,
  .box-title h3,
  .box-title h4,
  .box-title h5,
  .box-title h6 {
    font-family: "Independent Sans";
    margin-bottom: 0;
    opacity: 0.5;
    color: #281E1E; }

.block-wrapper {
  margin-bottom: 24px; }

.ad-block-header {
  margin-bottom: 0;
  margin: 0 auto;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: #ffffff;
  padding: 16px; }

.block-title {
  margin-bottom: 0.5rem;
  /*&:after {
    content: " ";
    display: block;
    background: #ec1a2e;
    height: 5px;
    width: 50px;
    margin-bottom: 8px;
  }*/ }
  .block-title h2,
  .block-title h3,
  .block-title h4,
  .block-title h5,
  .block-title h6 {
    font-family: "Independent Sans";
    margin-bottom: 0;
    opacity: 0.5;
    color: #281E1E; }

.injected-block,
.injected-blocks {
  width: 100%;
  padding: 0 10px;
  border-left: 1px solid #979797;
  border-right: 1px solid #979797;
  float: left;
  font-family: "Independent Serif"; }
  @media print, screen and (min-width: 48em) {
    .injected-block,
    .injected-blocks {
      width: 50%;
      margin-right: 16px; } }
  @media print, screen and (min-width: 64em) {
    .injected-block,
    .injected-blocks {
      margin-left: 0; } }
  .injected-block .views-field-nid,
  .injected-blocks .views-field-nid {
    display: none; }
  .injected-block ul,
  .injected-blocks ul {
    list-style: none;
    margin: 0; }
    .injected-block ul li,
    .injected-blocks ul li {
      margin-bottom: 10px;
      line-height: 1.2em;
      display: flex; }
      .injected-block ul li > div:first-child,
      .injected-blocks ul li > div:first-child {
        margin-right: 8px;
        flex-basis: 33%;
        flex-shrink: 0; }
  .injected-block a,
  .injected-blocks a {
    color: #281E1E;
    font-size: 14px;
    font-weight: 700;
    font-family: "Independent Serif"; }
    .injected-block a:hover,
    .injected-blocks a:hover {
      color: #ec1a2e; }
  .injected-block h6,
  .injected-blocks h6 {
    font-family: "Independent Sans";
    font-weight: 700;
    opacity: 0.5;
    color: #281E1E; }

.injected-block-watch-more,
.injected-blocks-watch-more {
  width: 48%;
  background-color: #000000;
  padding: 0.5rem; }
  @media print, screen and (min-width: 48em) {
    .injected-block-watch-more,
    .injected-blocks-watch-more {
      width: 36%; } }
  .injected-block-watch-more ul li,
  .injected-blocks-watch-more ul li {
    flex-wrap: wrap; }
    .injected-block-watch-more ul li > div:first-child,
    .injected-blocks-watch-more ul li > div:first-child {
      flex-basis: 100%;
      margin: 0 0 8px 0; }
  .injected-block-watch-more a,
  .injected-blocks-watch-more a {
    flex: 0 0 100%;
    font-family: "Independent Serif";
    font-size: 17px;
    color: #ffffff;
    padding-top: 6.5px; }
  .injected-block-watch-more h6,
  .injected-blocks-watch-more h6 {
    color: #ffffff;
    opacity: 1; }

.breadcrumbs-wrapper {
  display: block;
  font-family: "Independent Sans";
  font-weight: 500;
  padding: 0 1rem; }
  @media print, screen and (min-width: 64em) {
    .breadcrumbs-wrapper {
      padding-top: 24px; } }
  .breadcrumbs-wrapper .breadcrumbs {
    margin-bottom: 0; }
    .breadcrumbs-wrapper .breadcrumbs li {
      font-size: 16px; }
      .breadcrumbs-wrapper .breadcrumbs li a {
        color: #ec1a2e; }
        .breadcrumbs-wrapper .breadcrumbs li a.breadcrumbs__home {
          font-size: 14px;
          display: inline-block;
          color: #cccccc; }
          .breadcrumbs-wrapper .breadcrumbs li a.breadcrumbs__home:hover {
            text-decoration: none; }
      .breadcrumbs-wrapper .breadcrumbs li:not(:last-child)::after {
        content: "";
        font-family: "icomoon";
        font-size: 9px;
        margin-left: 8px;
        margin-right: 8px;
        color: #dadfe1; }

.entry-media-inner {
  margin-bottom: 16px; }
  .entry-media-inner a {
    color: inherit; }

.entry-media-inner-img {
  position: relative; }

.gallery-count {
  font-size: 1.25rem;
  padding: 10px;
  line-height: 1;
  position: absolute;
  bottom: 6px;
  right: 6px;
  background: rgba(237, 237, 237, 0.666); }

.gallery-trigger {
  cursor: pointer; }

.grid-mod-gallery .images ul li {
  position: relative;
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  float: left;
  border-left: 10px solid #fff;
  border-right: 10px solid #fff;
  border-top: 10px solid #fff;
  height: 20%;
  width: 25%; }
  .grid-mod-gallery .images ul li:first-child {
    height: 78%;
    width: 100%;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid #fff; }

.grid-mod-gallery {
  box-shadow: none;
  border: 1px solid #d6d6d6;
  display: flex;
  flex-direction: column;
  height: 600px; }

@media all and (max-width: 480px) {
  .grid-mod-gallery {
    height: 600px; } }

.grid-mod-gallery .gallery-title {
  font-size: 20px !important;
  font-weight: 600;
  line-height: 24px !important;
  padding: 20px !important;
  height: auto !important; }

.grid-mod-gallery .images {
  flex: 1; }

.grid-mod-gallery .images ul {
  height: 100%;
  list-style: none; }

.grid-mod-gallery li {
  position: relative;
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  float: left; }

.grid-mod-gallery li a {
  display: block;
  height: 100%;
  color: #fff; }

.grid-mod-gallery li a:hover {
  background: rgba(255, 255, 255, 0.2);
  color: #ec1a2e; }

.grid-mod-gallery li:first-child a {
  float: left;
  width: 50%; }

.grid-mod-gallery li:first-child a:hover {
  background: 0 0; }

.grid-mod-gallery li:first-child a:hover span {
  opacity: 0.5; }

.grid-mod-gallery li:last-child a {
  background-color: #dadfe1;
  color: #555;
  padding-top: 30px;
  text-align: center; }

.grid-mod-gallery li.image-small {
  background-size: inherit;
  background-position: center center; }

.grid-mod-gallery .pager {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  line-height: 1;
  padding: 1rem; }
  .grid-mod-gallery .pager i {
    font-size: 28px;
    vertical-align: top; }
  .grid-mod-gallery .pager.next {
    right: 15px; }
  .grid-mod-gallery .pager.prev {
    left: 15px; }

@media only screen and (min-width: 620px) {
  .grid-mod-gallery .gallery-title {
    min-height: 60px; } }

.grid-mod-gallery a:hover .pager {
  background: rgba(0, 0, 0, 0.9); }

.grid-mod-gallery .additional-photos {
  display: block;
  font-size: 40px;
  line-height: 46px;
  font-weight: 700;
  margin-bottom: 0;
  font-family: sans serif; }
  .grid-mod-gallery .additional-photos i {
    margin-right: 5px;
    line-height: 1;
    display: inline-block;
    vertical-align: text-top; }

.grid-mod-gallery .label {
  font-size: 14px;
  line-height: 18px;
  background-color: transparent;
  color: #555555; }

.grid-mod-gallery ul {
  list-style: none;
  height: 400px;
  margin: 0; }

.grid-mod-gallery li {
  height: 25%;
  width: 50%; }

.grid-mod-gallery li:first-child {
  height: 50%;
  width: 100%; }

@media (min-width: 400px) {
  .grid-mod-gallery ul {
    height: 500px; } }

@media (min-width: 500px) {
  .grid-mod-gallery ul {
    height: 540px; } }

.weight-1 .grid-mod-gallery li:nth-child(2n) {
  border-right: 1px solid #fff;
  border-top: 1px solid #fff; }

.weight-1 .grid-mod-gallery li:nth-child(2n + 1) {
  border-top: 1px solid #fff; }

.grid-mod-gallery-wrapper .grid-mod-gallery li {
  border-left: 10px solid #fff;
  border-right: 10px solid #fff;
  border-top: 10px solid #fff;
  height: 20%;
  width: 25%; }

.grid-mod-gallery-wrapper .grid-mod-gallery li:first-child {
  height: 78%;
  width: 100%;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid #fff; }

.grid-mod-gallery-wrapper .grid-mod-gallery li:last-child a {
  padding-top: 20px; }

.weight-3 .grid-mod-gallery li,
.weight-4 .grid-mod-gallery li {
  border-bottom: 10px solid #fff;
  border-left: 10px solid #fff;
  border-right: 10px solid #fff;
  height: 25%;
  width: 20%; }

.weight-3 .grid-mod-gallery li:first-child,
.weight-4 .grid-mod-gallery li:first-child {
  border-right: 1px solid #fff;
  border-left: none;
  border-top: none;
  height: 100%;
  width: 80%; }

.weight-3 .grid-mod-gallery li:last-child a,
.weight-4 .grid-mod-gallery li:last-child a {
  padding-top: 24px; }

.weight-3 .grid-mod-gallery .additional-photos,
.weight-4 .grid-mod-gallery .additional-photos {
  font-size: 48px;
  line-height: 52px;
  margin-bottom: 12px;
  margin-bottom: 0; }

.grid-mod-gallery-wrapper .grid-mod-half-height h1,
.weight-3 .grid-mod-gallery .label,
.weight-4 .grid-mod-gallery .label {
  font-size: 26px;
  line-height: 30px; }

@media only screen and (min-width: 620px) {
  .full-article .grid-mod-gallery li {
    border-left: 10px solid #fff;
    border-right: 10px solid #fff;
    border-top: 10px solid #fff;
    height: 20%;
    width: 25%; }
  .full-article .grid-mod-gallery li:first-child {
    height: 78%;
    width: 100%;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid #fff; }
  .full-article .grid-mod-gallery li:last-child a {
    padding-top: 20px; } }

@media all and (max-width: 999px) {
  .grid-mod-gallery-wrapper .grid-mod-gallery li:nth-child(2n) {
    border-right: 1px solid #fff;
    border-left: 0 none;
    border-top: 1px solid #fff; }
  .grid-mod-gallery-wrapper .grid-mod-gallery li:nth-child(2n + 1) {
    border-top: 1px solid #fff;
    border-left: 0 none;
    border-right: 0 none; }
  .grid-mod-gallery-wrapper .grid-mod-gallery li:nth-child(n + 2) {
    width: 50%;
    height: 25%; }
  .grid-mod-gallery-wrapper .grid-mod-gallery li:first-child {
    height: 50%; } }

@media all and (min-width: 668px) {
  .grid-mod-gallery-wrapper .grid-mod-gallery li:nth-child(n + 2) {
    height: 25%; } }

@media all and (min-width: 1000px) {
  .grid-mod-gallery-wrapper .grid-mod-gallery li:nth-child(n + 2) {
    height: 20%; } }

body.fullscreen-gallery {
  overflow: hidden; }

.gallery-specific-trigger {
  cursor: pointer; }

.full-gallery,
.full-gallery-specific {
  visibility: hidden;
  opacity: 0;
  -moz-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-transition: all 0.1s;
  -moz-transition: all 0.1s;
  transition: all 0.1s;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 6050000;
  background: #fff; }

.full-gallery .close,
.full-gallery-specific .close {
  float: right;
  font-size: 32px;
  padding: 14px;
  color: #281E1E;
  position: absolute;
  top: 0;
  right: 0;
  line-height: 1; }

.full-gallery .close:hover,
.full-gallery-specific .close:hover {
  color: #ec1a2e; }

.full-gallery .gallery-title,
.full-gallery-specific .gallery-title {
  font-family: "Independent Sans";
  font-size: 20px;
  line-height: 24px;
  padding: 20px;
  padding-right: 4rem; }
  .full-gallery .gallery-title .gallery-icon,
  .full-gallery-specific .gallery-title .gallery-icon {
    color: #ec1a2e; }

.full-gallery .scroller,
.full-gallery-specific .scroller {
  height: 100%;
  overflow: auto; }

.full-gallery .gallery-wrapper,
.full-gallery-specific .gallery-wrapper {
  height: 100vh; }

.gallery-inner {
  height: 100%; }

.full-gallery .images,
.full-gallery-specific .images {
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 20px;
  height: 100%;
  /** {
    max-height: 100%;
  }*/ }

.full-gallery .images li,
.full-gallery-specific .images li {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  text-align: center; }

.full-gallery ul,
.full-gallery-specific ul {
  list-style: none;
  margin: 0; }

.full-gallery li,
.full-gallery-specific li {
  display: none !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center; }

.full-gallery li.active,
.full-gallery-specific li.active {
  display: block !important; }

.full-gallery li img,
.full-gallery-specific li img {
  max-height: 100%; }

/*
@media only screen and (min-width: 620px) {
  .full-gallery .icon-arrow-left, .full-gallery .icon-arrow-right {
    margin-top: -30px
  }

  .full-gallery .icon-arrow-left:before, .full-gallery .icon-arrow-right:before {
    font-size: 60px
  }
}
*/
.full-gallery .pager,
.full-gallery-specific .pager {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0; }
  .full-gallery .pager i,
  .full-gallery-specific .pager i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 28px;
    vertical-align: top;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.7);
    padding: 1rem; }
    .full-gallery .pager i:before,
    .full-gallery-specific .pager i:before {
      display: block; }
    [dir="rtl"] .full-gallery .pager i:before, [dir="rtl"]
    .full-gallery-specific .pager i:before {
      -moz-transform: scaleX(-1);
      -o-transform: scaleX(-1);
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      filter: FlipH;
      -ms-filter: "FlipH"; }
    .full-gallery .pager i.icon-arrow-left,
    .full-gallery-specific .pager i.icon-arrow-left {
      left: 0;
      /*[dir="rtl"] & {
        left: auto;
        right: 0;
      }*/ }
    .full-gallery .pager i.icon-arrow-right,
    .full-gallery-specific .pager i.icon-arrow-right {
      right: 0;
      /*[dir="rtl"] & {
        left: 0;
        right: auto;
      }*/ }

.full-gallery .pager.prev,
.full-gallery-specific .pager.prev {
  width: 50%;
  left: 0;
  /*[dir="rtl"] & {
    left: auto;
    right: 0;
  }*/ }

.full-gallery .pager.next,
.full-gallery-specific .pager.next {
  right: 0;
  width: 50%;
  /*[dir="rtl"] & {
    left: 0;
    right: auto;
  }*/ }

.gallery-inner .pager.prev:hover i,
.gallery-inner .pager.next:hover i {
  background-color: #ec1a2e; }

.full-gallery .legends,
.full-gallery-specific .legends {
  padding: 20px; }

.full-gallery .legends h2,
.full-gallery-specific .legends h2 {
  font-family: "Independent Sans";
  font-size: 20px;
  font-weight: 700;
  line-height: 19px;
  margin-bottom: 10px; }

.full-gallery .legends h2 .counter,
.full-gallery-specific .legends h2 .counter {
  color: #ec1a2e;
  padding-right: 0.5rem; }

.full-gallery .legends p,
.full-gallery-specific .legends p {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 10px; }

.full-gallery .legends .credits,
.full-gallery-specific .legends .credits {
  color: #888; }

.full-gallery .legends :last-child,
.full-gallery-specific .legends :last-child {
  margin-bottom: 0; }

.full-gallery .legends a,
.full-gallery-specific .legends a {
  color: #ec1a2e !important; }

.full-gallery .legends a:hover,
.full-gallery-specific .legends a:hover {
  color: #940c19 !important; }

.full-gallery .ad-button,
.full-gallery-specific .ad-button {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #281E1E; }

.full-gallery .ad-button img,
.full-gallery-specific .ad-button img {
  display: block;
  margin: 0 auto; }

.full-gallery .ad-mpu,
.full-gallery-specific .ad-mpu {
  text-align: center;
  background-color: #f5f5f5; }
  .full-gallery .ad-mpu .premium-content,
  .full-gallery-specific .ad-mpu .premium-content {
    width: 300px; }

@media only screen and (min-width: 930px) {
  .full-gallery .ad-button,
  .full-gallery-specific .ad-button {
    display: none; }
  .full-gallery .ad-mpu,
  .full-gallery .ad-mpu img,
  .full-gallery-specific .ad-mpu,
  .full-gallery .ad-mpu img {
    display: block;
    width: auto; }
  .full-gallery .ad-mpu,
  .full-gallery-specific .ad-mpu {
    float: left;
    padding: 20px; }
  .full-gallery .images,
  .full-gallery-specific .images {
    float: left;
    width: calc(100% - 340px);
    margin-bottom: 0; }
  .full-gallery .legends,
  .full-gallery-specific .legends {
    float: right;
    width: 340px;
    background: rgba(0, 0, 0, 0.03); } }

.full-gallery.gallery-open,
.full-gallery-specific.gallery-open {
  visibility: visible;
  opacity: 1;
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1); }

.premium-content {
  padding: 5px;
  margin: 0 auto 0.75rem; }
  .sidebar-wrap .premium-content {
    margin-bottom: 1.5rem; }
  @media print, screen and (min-width: 48em) {
    .premium-content {
      margin: 0 auto; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .premium-content {
      padding: 5px 0 0; } }
  .premium-content .title {
    font-size: 12px;
    padding: 4px 8px 8px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.2);
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .premium-content .title h6 {
      font-family: "Independent Sans";
      font-size: 12px;
      font-weight: 700;
      line-height: 1;
      margin: 0; }

.dmpu {
  text-align: center; }
  @media print, screen and (min-width: 64em) {
    .dmpu {
      height: 600px;
      margin: 0; } }

.mpu {
  text-align: center; }
  @media print, screen and (min-width: 64em) {
    .mpu {
      margin-bottom: 0.2rem; } }

.right-position > .grid-x {
  flex-direction: column-reverse; }
  @media print, screen and (min-width: 48em) {
    .right-position > .grid-x {
      flex-direction: row-reverse; } }

@media print, screen and (min-width: 48em) {
  .left-position > .grid-x {
    flex-direction: row; } }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .eight-articles-dmpu .articles-list:not(.cards) .objects-list.has-separator .object-item:last-child {
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #d6d6d6; } }

@media print, screen and (min-width: 64em) {
  .eight-articles-dmpu .articles-list:not(.cards) .media-object .media-object-img img {
    max-width: 138px; } }

.eight-articles-dmpu .objects-list.has-separator .object-item,
.six-articles-mpu .objects-list.has-separator .object-item {
  margin-top: 0;
  padding-top: 0;
  border-top: 0;
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #d6d6d6; }
  .eight-articles-dmpu .objects-list.has-separator .object-item:last-child,
  .six-articles-mpu .objects-list.has-separator .object-item:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0; }

.six-articles-mpu .media-object-img,
.seven-articles .media-object-img {
  position: relative; }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .six-articles-mpu .media-object-img img,
    .seven-articles .media-object-img img {
      max-width: 126px; } }

.six-articles-mpu .article-item-author,
.seven-articles .article-item-author {
  display: none; }

@media screen and (max-width: 47.9375em) {
  .six-articles-mpu .articles-list,
  .seven-articles .articles-list {
    margin-bottom: 0.5rem; }
  .six-articles-mpu .cell.medium-12 .articles-list .objects-list.has-separator,
  .seven-articles .cell.medium-12 .articles-list .objects-list.has-separator {
    padding-top: 0.5rem;
    border-top: 1px solid #d6d6d6; } }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .six-articles-mpu .cell.medium-12 .articles-list .objects-list,
  .seven-articles .cell.medium-12 .articles-list .objects-list {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 12px; }
  .six-articles-mpu .cell.medium-12 .articles-list .object-item,
  .seven-articles .cell.medium-12 .articles-list .object-item {
    width: calc(33.33% - 8px);
    border: 0;
    margin-bottom: 0;
    padding-bottom: 0; }
  .six-articles-mpu .cell.medium-12 .articles-list .article-item-author,
  .seven-articles .cell.medium-12 .articles-list .article-item-author {
    display: block;
    font-size: 16px; }
  .six-articles-mpu .cell.medium-12 .articles-list .article-item-title h6,
  .seven-articles .cell.medium-12 .articles-list .article-item-title h6 {
    font-size: 19px;
    margin-top: 0.5rem; }
  .six-articles-mpu .cell.medium-12 .articles-list .media-object,
  .seven-articles .cell.medium-12 .articles-list .media-object {
    height: 100%;
    margin-bottom: 0;
    flex-direction: column-reverse;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding-bottom: 0; }
    .six-articles-mpu .cell.medium-12 .articles-list .media-object .media-object-section:first-child,
    .seven-articles .cell.medium-12 .articles-list .media-object .media-object-section:first-child {
      padding: 0; }
    .six-articles-mpu .cell.medium-12 .articles-list .media-object .media-object-section:last-child,
    .seven-articles .cell.medium-12 .articles-list .media-object .media-object-section:last-child {
      position: relative;
      min-height: 160px;
      width: 100%;
      padding: 16px; }
    .six-articles-mpu .cell.medium-12 .articles-list .media-object .media-object-img,
    .seven-articles .cell.medium-12 .articles-list .media-object .media-object-img {
      position: relative; }
      .six-articles-mpu .cell.medium-12 .articles-list .media-object .media-object-img:after,
      .seven-articles .cell.medium-12 .articles-list .media-object .media-object-img:after {
        content: "";
        display: block;
        padding-top: 66.8067%;
        width: 100%; }
      .six-articles-mpu .cell.medium-12 .articles-list .media-object .media-object-img img,
      .seven-articles .cell.medium-12 .articles-list .media-object .media-object-img img {
        display: block;
        height: 0;
        max-height: 100%;
        max-width: 100%;
        min-height: 100%;
        min-width: 100%;
        width: 0;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0; } }

.four-articles .article-item,
.cards-articles .article-item,
.two-articles-compact .article-item {
  border: 1px solid rgba(0, 0, 0, 0.2); }
  .four-articles .article-item.article-hero .article-item-img,
  .cards-articles .article-item.article-hero .article-item-img,
  .two-articles-compact .article-item.article-hero .article-item-img {
    height: auto; }
  .four-articles .article-item.article-hero.media-video .article-item-img:before, .four-articles .article-item.article-hero.media-audio .article-item-img:before,
  .cards-articles .article-item.article-hero.media-video .article-item-img:before,
  .cards-articles .article-item.article-hero.media-audio .article-item-img:before,
  .two-articles-compact .article-item.article-hero.media-video .article-item-img:before,
  .two-articles-compact .article-item.article-hero.media-audio .article-item-img:before {
    background: rgba(236, 26, 46, 0.8);
    color: #ffffff;
    border-radius: 50%;
    cursor: pointer;
    text-align: center;
    content: "\e90b";
    position: absolute;
    font-family: "icomoon";
    width: 32px;
    height: 32px;
    line-height: 32px;
    font-size: 1.25rem;
    z-index: 1;
    bottom: 0;
    top: -24px;
    right: 0;
    text-indent: 6px;
    margin: 0.5rem; }
    [dir="rtl"] .four-articles .article-item.article-hero.media-video .article-item-img:before, [dir="rtl"] .four-articles .article-item.article-hero.media-audio .article-item-img:before, [dir="rtl"]
    .cards-articles .article-item.article-hero.media-video .article-item-img:before, [dir="rtl"]
    .cards-articles .article-item.article-hero.media-audio .article-item-img:before, [dir="rtl"]
    .two-articles-compact .article-item.article-hero.media-video .article-item-img:before, [dir="rtl"]
    .two-articles-compact .article-item.article-hero.media-audio .article-item-img:before {
      text-indent: -6px; }
  .four-articles .article-item.article-hero.media-audio .article-item-img:before,
  .cards-articles .article-item.article-hero.media-audio .article-item-img:before,
  .two-articles-compact .article-item.article-hero.media-audio .article-item-img:before {
    content: "\e91e"; }

.four-articles .article-item-content,
.cards-articles .article-item-content,
.two-articles-compact .article-item-content {
  position: relative;
  width: 100%;
  padding: 1rem; }
  @media print, screen and (min-width: 48em) {
    .block-wrapper .four-articles .article-item-content, .block-wrapper
    .cards-articles .article-item-content, .block-wrapper
    .two-articles-compact .article-item-content {
      min-height: initial; } }

.four-articles .article-item-content1,
.cards-articles .article-item-content1,
.two-articles-compact .article-item-content1 {
  position: relative;
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 40px; }
  @media print, screen and (min-width: 48em) {
    .block-wrapper .four-articles .article-item-content1, .block-wrapper
    .cards-articles .article-item-content1, .block-wrapper
    .two-articles-compact .article-item-content1 {
      min-height: initial; } }

.four-articles .article-item-author,
.cards-articles .article-item-author,
.two-articles-compact .article-item-author {
  font-size: 16px; }

.four-articles .article-item-img,
.cards-articles .article-item-img,
.two-articles-compact .article-item-img {
  height: auto; }

.four-articles .article-item-desc,
.cards-articles .article-item-desc,
.two-articles-compact .article-item-desc {
  font-size: 15px; }

.four-articles .article-item-title h4,
.cards-articles .article-item-title h4,
.two-articles-compact .article-item-title h4 {
  font-weight: 500;
  font-size: 14px; }
  [dir="rtl"] .four-articles .article-item-title h4, [dir="rtl"]
  .cards-articles .article-item-title h4, [dir="rtl"]
  .two-articles-compact .article-item-title h4 {
    font-weight: 800;
    font-size: 17px; }
  .urdu-theme .four-articles .article-item-title h4, .urdu-theme
  .cards-articles .article-item-title h4, .urdu-theme
  .two-articles-compact .article-item-title h4 {
    font-size: 19px;
    line-height: 0.8; }
  @media screen and (min-width: 22.5em) {
    .four-articles .article-item-title h4,
    .cards-articles .article-item-title h4,
    .two-articles-compact .article-item-title h4 {
      font-size: 17px; } }
  @media print, screen and (min-width: 64em) {
    .four-articles .article-item-title h4,
    .cards-articles .article-item-title h4,
    .two-articles-compact .article-item-title h4 {
      font-size: 19px; } }

.four-articles1 .article-item.article-hero.media-video .abs-bottom-element,
.four-articles1 .article-item.article-hero.media-audio .abs-bottom-element,
.cards-articles .article-item.article-hero.media-video .abs-bottom-element,
.cards-articles .article-item.article-hero.media-audio .abs-bottom-element,
.two-articles-compact .article-item.article-hero.media-video .abs-bottom-element,
.two-articles-compact .article-item.article-hero.media-audio .abs-bottom-element {
  padding-top: 20px;
  margin-top: -80px;
  margin-bottom: 8px; }

@media screen and (max-width: 47.9375em) {
  .four-articles1 .cell.medium-6.large-6,
  .cards-articles .cell.medium-6.large-6,
  .two-articles-compact .cell.medium-6.large-6 {
    width: 97.5%;
    margin-bottom: 0.5rem; } }

.four-articles1 .article-item,
.cards-articles .article-item,
.two-articles-compact .article-item {
  border: 1px solid rgba(0, 0, 0, 0.2); }
  .four-articles1 .article-item.article-hero .article-item-img,
  .cards-articles .article-item.article-hero .article-item-img,
  .two-articles-compact .article-item.article-hero .article-item-img {
    height: auto; }
  .four-articles1 .article-item.article-hero.media-video .article-item-img:before, .four-articles1 .article-item.article-hero.media-audio .article-item-img:before,
  .cards-articles .article-item.article-hero.media-video .article-item-img:before,
  .cards-articles .article-item.article-hero.media-audio .article-item-img:before,
  .two-articles-compact .article-item.article-hero.media-video .article-item-img:before,
  .two-articles-compact .article-item.article-hero.media-audio .article-item-img:before {
    background: rgba(236, 26, 46, 0.8);
    color: #ffffff;
    border-radius: 50%;
    cursor: pointer;
    text-align: center;
    position: absolute;
    font-family: "icomoon";
    width: 32px;
    height: 32px;
    line-height: 32px;
    font-size: 1.25rem;
    z-index: 1;
    bottom: 0;
    top: -24px;
    right: 0;
    text-indent: 6px;
    margin: 0.5rem; }
    [dir="rtl"] .four-articles1 .article-item.article-hero.media-video .article-item-img:before, [dir="rtl"] .four-articles1 .article-item.article-hero.media-audio .article-item-img:before, [dir="rtl"]
    .cards-articles .article-item.article-hero.media-video .article-item-img:before, [dir="rtl"]
    .cards-articles .article-item.article-hero.media-audio .article-item-img:before, [dir="rtl"]
    .two-articles-compact .article-item.article-hero.media-video .article-item-img:before, [dir="rtl"]
    .two-articles-compact .article-item.article-hero.media-audio .article-item-img:before {
      text-indent: -6px; }
  .four-articles1 .article-item.article-hero.media-audio .article-item-img:before,
  .cards-articles .article-item.article-hero.media-audio .article-item-img:before,
  .two-articles-compact .article-item.article-hero.media-audio .article-item-img:before {
    content: "\e91e"; }

.four-articles1 .article-item-content,
.cards-articles .article-item-content,
.two-articles-compact .article-item-content {
  position: relative;
  width: 100%;
  padding: 1rem; }
  @media print, screen and (min-width: 48em) {
    .block-wrapper .four-articles1 .article-item-content, .block-wrapper
    .cards-articles .article-item-content, .block-wrapper
    .two-articles-compact .article-item-content {
      min-height: initial; } }

.four-articles1 .article-item-content1,
.cards-articles .article-item-content1,
.two-articles-compact .article-item-content1 {
  position: relative;
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 40px; }
  @media print, screen and (min-width: 48em) {
    .block-wrapper .four-articles1 .article-item-content1, .block-wrapper
    .cards-articles .article-item-content1, .block-wrapper
    .two-articles-compact .article-item-content1 {
      min-height: initial; } }

.four-articles1 .article-item-author,
.cards-articles .article-item-author,
.two-articles-compact .article-item-author {
  font-size: 16px; }

.four-articles1 .article-item-img,
.cards-articles .article-item-img,
.two-articles-compact .article-item-img {
  height: auto; }

.four-articles1 .article-item-desc,
.cards-articles .article-item-desc,
.two-articles-compact .article-item-desc {
  font-size: 15px; }

.four-articles1 .article-item-title h4,
.cards-articles .article-item-title h4,
.two-articles-compact .article-item-title h4 {
  font-weight: 500;
  font-size: 14px; }
  [dir="rtl"] .four-articles1 .article-item-title h4, [dir="rtl"]
  .cards-articles .article-item-title h4, [dir="rtl"]
  .two-articles-compact .article-item-title h4 {
    font-weight: 800;
    font-size: 17px; }
  .urdu-theme .four-articles1 .article-item-title h4, .urdu-theme
  .cards-articles .article-item-title h4, .urdu-theme
  .two-articles-compact .article-item-title h4 {
    line-height: 1.6; }
  @media screen and (min-width: 22.5em) {
    .four-articles1 .article-item-title h4,
    .cards-articles .article-item-title h4,
    .two-articles-compact .article-item-title h4 {
      font-size: 17px; } }
  @media print, screen and (min-width: 64em) {
    .four-articles1 .article-item-title h4,
    .cards-articles .article-item-title h4,
    .two-articles-compact .article-item-title h4 {
      font-size: 19px; } }

.four-articles1 article.article-item.article-hero.media-video,
.cards-articles article.article-item.article-hero.media-video,
.two-articles-compact article.article-item.article-hero.media-video {
  border: 0px;
  margin-bottom: 9px; }

@media print, screen and (min-width: 48em) {
  .two-articles-compact .article-item {
    flex-direction: row-reverse; }
  .two-articles-compact .article-item-img {
    width: 50%; }
  .two-articles-compact .article-item-content {
    width: 50%; } }

@media print, screen and (min-width: 48em) {
  .three-articles .article-item.article-hero {
    height: 220px; } }

@media print, screen and (min-width: 64em) {
  .three-articles .article-item.article-hero {
    height: 300px; } }

.three-articles .article-item.article-hero h4 {
  font-size: 17px; }

.urdu-theme .three-articles .article-item.article-hero h4 {
  font-size: 22px; }

.splash-row > .grid-x > .cell.large-8 > .article-item.media-video .abs-bottom-element,
.splash-row > .grid-x > .cell.large-8 > .article-item.media-audio .abs-bottom-element {
  padding-top: 40px; }

.splash-row .articles-list .article-item.article-hero {
  margin-bottom: 0.5rem; }

.splash-row .articles-list.has-related .article-item.article-hero {
  margin-bottom: 0; }

.splash-row .articles-list.has-related .abs-bottom-element {
  padding-bottom: 0;
  padding-left: 8px;
  padding-right: 8px; }
  .splash-row .articles-list.has-related .abs-bottom-element .article-item-title a {
    border-left: 1px solid #fff;
    padding: 0 0 8px 8px; }
    [dir="rtl"] .splash-row .articles-list.has-related .abs-bottom-element .article-item-title a {
      padding: 0 8px 8px 0; }

.splash-row .articles-list.has-related .headlines-list {
  border-left: 1px solid #000000;
  padding-top: 0.5rem;
  margin: 0 0 0.5rem 0.5rem; }
  [dir="rtl"] .splash-row .articles-list.has-related .headlines-list {
    margin: 0 0.5rem 0.5rem 0; }
  .splash-row .articles-list.has-related .headlines-list li {
    margin-top: 0; }
    .splash-row .articles-list.has-related .headlines-list li a {
      font-weight: 500;
      line-height: 1.25; }

.eight-articles .articles-list .objects-list.has-separator .object-item:first-child {
  margin-top: 0; }

@media screen and (max-width: 47.9375em) {
  .seven-articles .grid-x > div:first-child,
  .two-articles .grid-x > div:first-child {
    margin-bottom: 0.5rem; } }

@media print, screen and (min-width: 64em) {
  .eng-theme .eight-articles-dmpu .articles-list .article-item-title h6,
  .turkish-theme .eight-articles-dmpu .articles-list .article-item-title h6 {
    font-size: 17px; } }

.articles-list {
  /*@include breakpoint(small only) {
    margin-bottom: 0.5rem;
  }*/ }
  @media screen and (max-width: 47.9375em) {
    .articles-list.cards .objects-list {
      position: relative;
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 12px;
      margin-bottom: 12px; }
    .articles-list.cards .object-item {
      width: calc(50% - 3px);
      border: 0 !important;
      margin-bottom: 0 !important;
      padding-bottom: 0 !important; }
    .articles-list.cards .article-item-author {
      display: block;
      font-size: 16px; }
    .articles-list.cards .article-item-title h6 {
      font-size: 16px;
      margin-top: 0.5rem; }
    .articles-list.cards .media-object {
      height: 100%;
      margin-bottom: 0;
      flex-direction: column-reverse;
      border: 1px solid rgba(0, 0, 0, 0.2);
      padding-bottom: 0; }
      .articles-list.cards .media-object .media-object-section:first-child {
        padding: 0; }
      .articles-list.cards .media-object .media-object-section:last-child {
        position: relative;
        min-height: 160px;
        width: 100%;
        padding: 16px; }
      .articles-list.cards .media-object .media-object-img {
        position: relative; }
        .articles-list.cards .media-object .media-object-img:after {
          content: "";
          display: block;
          padding-top: 66.8067%;
          width: 100%; }
        .articles-list.cards .media-object .media-object-img img {
          display: block;
          height: 0;
          max-height: 100%;
          max-width: 100%;
          min-height: 100%;
          min-width: 100%;
          width: 0;
          margin: auto;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0; } }
  @media print, screen and (min-width: 64em) {
    .articles-list.cards {
      height: 100%; }
      .articles-list.cards .objects-list {
        position: relative;
        height: 100%; }
      .articles-list.cards .media-object {
        height: 100%;
        margin-bottom: 0; } }
    @media print, screen and (min-width: 64em) and (min-width: 64em) {
      .articles-list.cards .media-object {
        flex-direction: column-reverse;
        border: 1px solid rgba(0, 0, 0, 0.2);
        padding-bottom: 0; } }
  @media print, screen and (min-width: 64em) {
        .articles-list.cards .media-object .media-object-section {
          flex: 1; }
        .articles-list.cards .media-object .media-object-section:first-child {
          padding: 0; }
        .articles-list.cards .media-object .media-object-section:last-child {
          position: relative;
          width: 100%;
          padding: 16px; }
        .articles-list.cards .media-object .media-object-img {
          position: relative; }
          .articles-list.cards .media-object .media-object-img img {
            display: block;
            height: 0;
            max-height: 100%;
            max-width: 100%;
            min-height: 100%;
            min-width: 100%;
            width: 0;
            margin: auto;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0; } }
  @media print, screen and (min-width: 64em) and (min-width: 64em) {
    .articles-list.cards .object-item {
      position: absolute;
      height: 49.4%;
      width: 100%; }
      .articles-list.cards .object-item:last-child {
        margin-bottom: 0;
        bottom: 0; } }

.author-name {
  color: #ec1a2e;
  font-style: italic;
  font-weight: 700;
  font-size: 14px; }
  .urdu-theme .author-name {
    font-style: normal; }

.author-headline {
  font-style: italic;
  font-weight: 500;
  letter-spacing: 0.22px;
  line-height: 1.4; }
  .eng-theme .author-headline,
  .turkish-theme .author-headline {
    font-size: 14px; }
  .author-headline a {
    color: #000000; }
    .author-headline a:hover {
      color: #ec1a2e; }

.three-articles-headshots {
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
  background: #f5f5f5;
  margin-bottom: 16px; }
  @media print, screen and (min-width: 48em) {
    .three-articles-headshots .slick-track {
      display: flex; }
      .three-articles-headshots .slick-track .slick-slide {
        display: flex;
        height: auto; } }
  .three-articles-headshots .media-object-img {
    position: relative;
    height: 100%; }
    .three-articles-headshots .media-object-img a {
      display: flex;
      flex-direction: column;
      align-items: end;
      justify-content: end;
      align-content: end;
      position: relative;
      height: 100%; }
  .three-articles-headshots .carousel-items {
    overflow-y: hidden;
    padding: 0 2.5rem; }
  .three-articles-headshots .media-object {
    margin-bottom: 0; }
  .three-articles-headshots .author-name {
    margin-bottom: 8px; }
  .three-articles-headshots .media-object-section-content {
    padding: 8px 0; }
    @media screen and (max-width: 47.9375em) {
      .three-articles-headshots .media-object-section-content .author-headline a {
        font-size: 13px;
        display: block; }
        .urdu-theme .three-articles-headshots .media-object-section-content .author-headline a {
          font-size: 20px; } }
  @media print, screen and (min-width: 64em) {
    .three-articles-headshots .carousel-items {
      padding: 0; } }
  [dir="rtl"] .three-articles-headshots .author-headline {
    font-size: 14px;
    font-style: normal; }
  .urdu-theme .three-articles-headshots .author-headline {
    font-size: 18px;
    margin-top: 7px; }
  .persian-theme .three-articles-headshots .author-headline {
    font-size: 15px; }

.author-field {
  text-align: center; }
  .author-field .media-object {
    margin-bottom: 0; }
  @media print, screen and (min-width: 48em) {
    .author-field {
      text-align: left; }
      .author-field .media-object {
        margin-bottom: 1rem; } }

.author-field-img img {
  max-width: 75px; }

.author-field-name {
  color: #ec1a2e;
  font-size: 26px;
  font-weight: 700;
  font-style: italic;
  line-height: 1;
  margin-top: 0.5rem; }
  [dir="rtl"] .author-field-name {
    line-height: 1.3; }

.author-field-job-title {
  font-family: "Independent Sans";
  font-size: 19px;
  font-weight: 300;
  color: #888888;
  display: inline-block;
  vertical-align: middle; }

.author-field-social {
  display: inline-block;
  vertical-align: middle; }
  .author-field-social a {
    font-family: "Independent Sans";
    font-size: 19px;
    font-weight: 300;
    color: #888888; }
    .author-field-social a:before {
      color: #888888;
      content: "|";
      margin-right: 5px; }

.author-field-bio {
  font-size: 17px;
  line-height: 1.4;
  font-weight: 300; }
  @media print, screen and (min-width: 48em) {
    .author-field-bio {
      margin-top: 1rem;
      margin-bottom: 1rem; } }

.i100-block {
  width: 100%; }

.inline-gallery {
  font-family: "Independent Sans";
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
  padding: 4px 0 16px;
  margin-bottom: 16px; }

span.gallery-icon {
  width: 24px;
  height: 18px;
  display: inline-block;
  margin-right: 8px; }

.inline-gallery-before {
  display: flex;
  justify-content: space-between;
  padding: 8px 0; }
  .inline-gallery-before h4 {
    font-family: inherit;
    font-size: 18px;
    font-weight: 700;
    margin-right: 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 1.2;
    white-space: nowrap;
    display: flex;
    align-items: center; }
  .inline-gallery-before .inline-gallery-count {
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ec1a2e;
    font: 700 14px Independent Sans, sans-serif;
    cursor: pointer; }
    .inline-gallery-before .inline-gallery-count:hover {
      color: #940c19; }

.inline-gallery-inner {
  display: flex;
  flex-direction: column;
  height: 49.5%;
  width: 100%;
  position: relative; }
  @media print, screen and (min-width: 48em) {
    .inline-gallery-inner {
      flex-direction: row; } }

.inline-gallery-preview {
  cursor: pointer;
  display: flex;
  position: relative;
  width: 100%;
  padding-top: 66%; }
  .inline-gallery-preview .sizer {
    padding-top: 80%; }
  @media print, screen and (min-width: 48em) {
    .inline-gallery-preview {
      width: 75%; } }
  .inline-gallery-preview .chevron-icon {
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 3;
    width: 50px;
    height: 50px;
    line-height: 50px;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
    color: #ffffff;
    font-size: 20px; }
    .inline-gallery-preview .chevron-icon.next-icon {
      left: auto;
      right: 0; }
    [dir="rtl"] .inline-gallery-preview .chevron-icon {
      left: auto;
      right: 0; }
      [dir="rtl"] .inline-gallery-preview .chevron-icon.next-icon {
        left: 0;
        right: auto; }

.inline-gallery-thumbnails {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-top: 12px; }
  @media print, screen and (min-width: 48em) {
    .inline-gallery-thumbnails {
      height: auto;
      flex: 1;
      flex-direction: column;
      margin-top: 0;
      margin-left: 12px; } }

.gallery-thumbnail {
  position: relative;
  height: calc(100% - 20px);
  width: calc(100% / 3 - 8px);
  cursor: pointer; }
  @media print, screen and (min-width: 48em) {
    .gallery-thumbnail {
      width: 100%;
      height: calc(33.3% - 8px); } }
  .gallery-thumbnail .sizer {
    padding-top: 80%; }

.author-avatar {
  width: 72px;
  height: 72px;
  overflow: hidden;
  border-radius: 50%;
  margin: 16px 8px 0 0;
  background-color: rgba(0, 0, 0, 0.2); }
  @media print, screen and (min-width: 48em) {
    .author-avatar {
      width: 125px;
      height: 125px;
      margin-left: 16px;
      margin-bottom: 16px;
      margin-right: 16px; } }
  @media print, screen and (min-width: 64em) {
    .author-avatar {
      width: 137px;
      height: 137px;
      margin-left: auto; } }
  .has-avatar .author-avatar {
    float: left;
    margin-top: 0; }

.search-form-wrapper {
  margin-bottom: 2.5rem; }
  .search-form-wrapper .page-title {
    margin-bottom: 1rem;
    color: #303638;
    text-transform: capitalize; }
    .search-form-wrapper .page-title h1 {
      font-size: 2rem;
      margin-bottom: 0; }
      .search-form-wrapper .page-title h1 a {
        color: #281E1E; }

.search-form-main.searchbox > div {
  display: flex;
  width: 100%;
  align-items: stretch; }
  .search-form-main.searchbox > div .form-item.form-type-textfield {
    flex: 1 1 0;
    height: auto;
    min-width: 0; }

.search-form-main input.input-group-field {
  background-color: #fdfdfc; }

.search-form-main .input-group-button {
  padding-left: 1rem; }

.search-form-main .button {
  font-family: "icomoon";
  font-size: 1.125rem;
  background-color: #281E1E;
  width: 45px;
  padding: 0;
  color: #ffffff; }
  .search-form-main .button:hover {
    background-color: #ec1a2e; }
  @media print, screen and (min-width: 48em) {
    .search-form-main .button {
      min-width: 5rem; } }

.search-meta {
  font-family: "Independent Sans";
  font-weight: 500;
  font-size: 13px; }
  .search-meta .search-term {
    color: #5f7176; }
    .search-meta .search-term span {
      font-family: "Independent Sans"; }
  .search-meta .typo {
    font-family: "Independent Sans";
    color: #000000; }
    .search-meta .typo span {
      color: #ec1a2e;
      text-transform: uppercase; }

.posts-list .article-item {
  font-family: "Independent Sans";
  margin-bottom: 2rem; }

.posts-list .article-item-img {
  margin-bottom: 0.75rem; }

.article-item-meta {
  color: rgba(40, 30, 30, 0.6); }
  .article-item-meta a {
    color: rgba(236, 26, 46, 0.8); }
    .article-item-meta a:hover {
      color: #000000; }
  .horoscopes-articles .article-item-meta {
    margin-bottom: 0.5rem; }

.entry-video-container {
  padding: 16px 0;
  background: #000000;
  color: #ffffff;
  overflow: hidden;
  margin-bottom: 24px; }
  .entry-video-container h1 {
    font-size: 21px; }
    @media print, screen and (min-width: 48em) {
      .entry-video-container h1 {
        font-size: 36px; } }
  .entry-video-container .entry-subtitle h2,
  .entry-video-container .entry-meta-field span,
  .entry-video-container .entry-meta-field time,
  .entry-video-container .entry-meta-field:after {
    color: #dadfe1; }
  .entry-video-container .author-twitter a,
  .entry-video-container .entry-meta-comment a {
    color: #dadfe1; }
    .entry-video-container .author-twitter a:hover,
    .entry-video-container .entry-meta-comment a:hover {
      color: #ec1a2e; }
  .entry-video-container .entry-meta-shares-count span {
    color: #ec1a2e; }

.branding {
  margin-top: -0.25rem;
  color: #999999;
  font-size: 12px;
  line-height: 1; }
  .branding a {
    color: inherit; }
    .branding a:hover {
      color: #ec1a2e; }

.show-for-printable-area {
  display: none; }

@media print {
  /*.injected-block, .entry-promoted-stories, .share-block, .follow-block, .inline-gallery, .full-gallery, .responsive-embed, iframe {
    display: none;
  }*/
  /*
   header, footer, .sidebar-wrap-holder, .breadcrumbs-wrapper, .entry-tags, .entry-comments, .injected-block, .entry-promoted-stories, .share-block, .follow-block, .inline-gallery, .full-gallery, .responsive-embed, iframe {
     display: none;
   }
   .content-wrap-holder {
     width: 100%;
     max-width: none;
   }
   .entry-title h1 {
     font-size: 2.5rem;
   }
   .entry-meta {
     width: 100%;
     max-width: none;
   }
   .entry-header.has-avatar .entry-meta-wrapper > .grid-x > .cell.shrink {
     max-width: none;
   }
   */ }

.urdu-theme * {
  word-spacing: -1px;
  letter-spacing: 0 !important; }

.horoscope-item {
  max-width: 320px;
  margin: 0 auto 2rem; }
  .horoscope-item a {
    color: #000000; }
    .horoscope-item a:hover {
      color: #ec1a2e; }

.horoscope-item-img {
  margin-bottom: 1rem;
  position: relative;
  padding: 1rem 1.5rem 0; }
  @media screen and (min-width: 30em) {
    .horoscope-item-img {
      padding: 1rem 3rem 0; } }
  .horoscope-item-img img {
    border-radius: 50%;
    padding: 15px;
    border: silver solid 1px; }
  .daily-horoscopes .horoscope-item-img a:after {
    content: "يومي"; }
  .weekly-horoscopes .horoscope-item-img a:after {
    content: "أسبوعي"; }
  .monthly-horoscopes .horoscope-item-img a:after {
    content: "شهري"; }
  .daily-horoscopes .horoscope-item-img a,
  .weekly-horoscopes .horoscope-item-img a,
  .monthly-horoscopes .horoscope-item-img a {
    display: block; }
    .daily-horoscopes .horoscope-item-img a:hover,
    .weekly-horoscopes .horoscope-item-img a:hover,
    .monthly-horoscopes .horoscope-item-img a:hover {
      opacity: 0.7; }
      .daily-horoscopes .horoscope-item-img a:hover:after,
      .weekly-horoscopes .horoscope-item-img a:hover:after,
      .monthly-horoscopes .horoscope-item-img a:hover:after {
        background-color: #ec1a2e; }
    .daily-horoscopes .horoscope-item-img a:after,
    .weekly-horoscopes .horoscope-item-img a:after,
    .monthly-horoscopes .horoscope-item-img a:after {
      display: block;
      position: absolute;
      bottom: 1rem;
      right: 1rem;
      background-color: #000000;
      color: #ffffff;
      text-transform: uppercase;
      width: 60px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      font-size: 0.75rem;
      font-weight: bold;
      border-radius: 50%; }
      @media print, screen and (min-width: 48em) {
        .daily-horoscopes .horoscope-item-img a:after,
        .weekly-horoscopes .horoscope-item-img a:after,
        .monthly-horoscopes .horoscope-item-img a:after {
          font-size: 16px;
          width: 80px;
          height: 80px;
          line-height: 80px; } }

.horoscope-item-meta {
  margin-bottom: 0.625rem;
  font-size: 15px;
  text-transform: uppercase; }

.horoscope-item-name {
  margin-bottom: 0.625rem; }

.horoscope-item-range {
  font-size: 15px; }

.related-horoscope {
  border-top: 6px solid #000000;
  padding-top: 1rem;
  margin-bottom: 2rem; }
  .related-horoscope img {
    max-width: 100px; }

/* ----- Live blog list styles ------ START ------ */
.RecentPostsWidget iframe[src*="jwplayer"] {
  width: 100%; }

@media print, screen and (min-width: 64em) {
  .liveblog-capsule {
    padding-right: 0.3125rem;
    padding-left: 0.3125rem;
    max-width: 61.5rem;
    margin: 0 auto;
    padding: 0; } }
  @media print, screen and (min-width: 64em) and (min-width: 48em) {
    .liveblog-capsule {
      padding-right: 0.375rem;
      padding-left: 0.375rem; } }
  @media print, screen and (min-width: 64em) and (min-width: 64em) {
    .liveblog-capsule {
      padding-right: 0.5rem;
      padding-left: 0.5rem; } }

.liveblog-capsule .inner {
  font-family: "Independent Sans";
  display: flex;
  align-items: center;
  padding: 20px 16px;
  background: #f7f7f7; }

.liveblog-capsule.active .inner {
  background: #fffd38; }

.liveblog-capsule span {
  display: flex;
  align-items: center;
  margin-right: 8px; }
  .liveblog-capsule span:first-child {
    text-transform: uppercase;
    font-size: 20px;
    margin-right: 16px; }
    [dir="rtl"] .liveblog-capsule span:first-child {
      font-size: 18px; }
    .liveblog-capsule span:first-child:before {
      content: " ";
      background: url(../img/icon-live-blog.png) no-repeat 50%;
      width: 20px;
      height: 15px;
      background-size: contain;
      margin-right: 8px; }

.liveblog-capsule time {
  font-size: 20px; }
  [dir="rtl"] .liveblog-capsule time {
    font-size: 18px; }

body.article-liveblog .entry-article-liveblog {
  background: #f7f7f7;
  border-top: 1px solid #dadfe1;
  padding-top: 18px; }
  @media (min-width: 768px) {
    body.article-liveblog .entry-article-liveblog {
      padding-top: 12px; } }

.liveblog .content {
  font-family: "Independent Sans";
  padding: 20px;
  border: 1px solid #888;
  line-height: 1.5; }
  .liveblog .content img {
    width: 100%; }
  .liveblog .content .twitter-widget {
    margin: 0 auto; }

.liveblog .liveblog-content .content,
.lb-items {
  position: relative; }

.lb-items ul {
  list-style: none;
  margin: 0;
  padding: 0; }

.lb-item {
  color: #281e1e; }

.liveblog .liveblog-content:before {
  content: " ";
  border: 1px solid #888;
  position: absolute;
  margin: -24px 0 0 28px;
  height: 68px; }
  [dir="rtl"] .liveblog .liveblog-content:before {
    margin: -24px 28px 0 0; }

.liveblog .post-date {
  position: relative;
  font: 700 17px Independent Sans, sans-serif;
  padding-left: 20px;
  margin: 24px 0 24px 20px;
  color: #888; }
  [dir="rtl"] .liveblog .post-date {
    margin: 24px 20px 24px 0; }

.liveblog .post-date:before {
  content: " ";
  border-radius: 50%;
  border: 2px solid #888;
  position: absolute;
  top: -1px;
  margin-left: -20px;
  width: 19px;
  height: 19px;
  background: #fff; }

.liveblog .post-date time {
  padding-left: 8px; }

.liveblog .post-footer {
  padding: 16px 20px;
  background: #fff;
  position: relative;
  border: 1px solid #888;
  border-width: 0 1px 1px; }

.liveblog .post-footer ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  font-family: "Independent Sans";
  font-size: 14px; }

.liveblog .post-footer ul li.author-name {
  color: #ec1a2e;
  font-weight: 700;
  font-style: normal; }

.liveblog .post-footer ul li:first-child {
  padding-left: 0; }

.liveblog .post-footer ul li {
  padding: 0 8px; }

.liveblog .post-footer ul li:last-child {
  color: #888;
  position: relative; }

.liveblog .post-footer ul li:last-child:before {
  content: "|";
  position: absolute;
  left: -1px; }

.highlights-wrapper {
  clear: both; }

.highlights {
  color: #281e1e;
  border: 1px solid #cfcfcf;
  padding: 20px;
  margin-bottom: 16px;
  background: #fff; }
  .highlights a:hover {
    color: #ec1a2e; }
  .highlights ul {
    margin: 0; }

@media (min-width: 768px) {
  .highlights h2 {
    font-size: 16px; } }

.highlights h2 {
  font-family: "Independent Sans";
  font-size: 1.125rem;
  margin-bottom: 16px;
  text-transform: uppercase; }

.highlights > div :last-child {
  margin-bottom: 0; }

.highlights li {
  position: relative;
  list-style: normal;
  padding-bottom: 26px;
  padding-left: 31px;
  font-size: 14px;
  font-weight: 700;
  padding-top: 4px;
  list-style-type: none; }
  .highlights li:before {
    content: " ";
    width: 19px;
    height: 19px;
    display: block;
    background: transparent url(../img/svg/white_arrow.svg) no-repeat;
    background-size: 19px;
    border-radius: 50%;
    padding-top: 4px;
    position: absolute;
    left: 2px;
    top: 0;
    z-index: 2; }
    [dir="rtl"] .highlights li:before {
      -moz-transform: scaleX(-1);
      -o-transform: scaleX(-1);
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      filter: FlipH;
      -ms-filter: "FlipH";
      top: 2px; }
  .highlights li:after {
    content: "";
    width: 2px;
    background: #dadfe1;
    height: 100%;
    position: absolute;
    left: 11px;
    top: 0; }
  .highlights li:last-child:after {
    display: none; }

.highlights li a {
  color: inherit; }

#live-list-update-button {
  width: 100%;
  background: #fffd38;
  border: 1px solid #888;
  display: none;
  align-items: center;
  justify-content: center;
  padding: 15px 20px 10px;
  outline: none;
  color: #281e1e;
  cursor: pointer;
  font-family: "Independent Sans";
  font-size: 16px; }
  #live-list-update-button.active {
    display: flex; }
  #live-list-update-button #live-list-update-button .update-text {
    padding-right: 10px;
    line-height: 1; }
  #live-list-update-button .icon-refresh {
    display: flex;
    margin-left: 0.75rem; }

.liveblog .pagination-wrapper {
  overflow: hidden;
  position: relative;
  margin-bottom: 3rem; }
  .liveblog .pagination-wrapper:before {
    width: 2px;
    background: #888;
    content: " ";
    position: absolute;
    left: 28px;
    height: 100%; }
  .liveblog .pagination-wrapper .pagination {
    font-family: "Independent Serif";
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px;
    margin: 30px 0 0;
    background: #ffffff;
    border: 1px solid #888888;
    position: relative; }
    .liveblog .pagination-wrapper .pagination .prev {
      margin-right: auto; }
    .liveblog .pagination-wrapper .pagination .next {
      margin-left: auto; }
    .liveblog .pagination-wrapper .pagination .shortcut {
      display: flex;
      flex-wrap: wrap; }
      .liveblog .pagination-wrapper .pagination .shortcut > * {
        margin: 0 10px;
        display: flex;
        font: 700 16px "Independent Sans", sans-serif; }
    .liveblog .pagination-wrapper .pagination a {
      padding: 0;
      color: #ec1a2e; }
      .liveblog .pagination-wrapper .pagination a:hover {
        background: transparent;
        color: #281E1E; }

.icon-holder.abs-element {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  z-index: 1;
  text-align: center; }
  .icon-holder.abs-element i {
    font-size: 2rem;
    padding: 1rem;
    background-color: #f9f9f9;
    border-radius: 10px;
    display: inline-block;
    vertical-align: middle; }
    .icon-holder.abs-element i.icon-facebook {
      background-color: #5168a4;
      color: #ffffff; }
    .icon-holder.abs-element i.icon-twitter {
      background-color: #00a6d4;
      color: #ffffff; }
    .icon-holder.abs-element i.icon-youtube {
      background-color: #bb0000;
      color: #ffffff; }

/* ----- Live blog list styles ------ END ------ */
.subscribe-field-title {
  text-transform: uppercase;
  font-family: "Independent Sans";
  font-size: 2.25rem;
  font-weight: 500;
  line-height: 1; }

.subscribe-blocks .grid-items {
  height: 100%; }

.subscribe-blocks .article-item {
  height: calc(100% - 1rem); }
  .subscribe-blocks .article-item .article-item-img {
    height: 100%; }

.subscribe-blocks .article-item-img a:after {
  z-index: 1; }

.card-item {
  border: 1px solid #e5e8ea; }

.article-book .entry-article-topper {
  max-width: 1240px; }

.article-book .entry-header {
  text-align: center; }

.article-book .entry-header.has-avatar .entry-subtitle {
  float: none;
  width: 100%; }
  .article-book .entry-header.has-avatar .entry-subtitle h2 {
    color: #888;
    font-size: 21px; }

.article-book .author-avatar {
  float: none;
  margin: 1rem auto; }

.article-book .entry-header.has-avatar .entry-meta-wrapper {
  float: none;
  width: 100%; }

.article-book .entry-media-inner {
  padding: 0 2rem; }

@media print, screen and (min-width: 64em) {
  .article-book .entry-article-topper .caption-field {
    position: absolute;
    right: 0;
    width: calc((100% - 800px) / 2); } }

.first-letter {
  font-size: 52px;
  width: 52px;
  height: 52px;
  line-height: 1;
  font-weight: 300;
  margin-right: 12px;
  margin-top: 6px;
  float: left;
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #222;
  position: relative; }
  .first-letter:before {
    position: absolute;
    content: "";
    border-radius: 50%;
    border: 1px solid #222;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }

.campaign-area .article-item-title {
  border-bottom: 1px solid #aaa;
  margin-bottom: 1rem; }

.campaign-area p {
  font-size: 16px;
  color: #222; }

.podcast-badge ul {
  margin-left: 0; }
  .podcast-badge ul li {
    margin-bottom: 10px;
    margin-right: 2px;
    margin-left: 2px;
    width: 47.5%;
    list-style: none;
    display: inline-block; }

.podcast-list-channel ul {
  margin-left: 0; }
  .podcast-list-channel ul li {
    list-style: none;
    display: inline-block; }
    .podcast-list-channel ul li img {
      width: 150px; }
      @media print, screen and (min-width: 48em) {
        .podcast-list-channel ul li img {
          width: 100px; } }
      @media print, screen and (min-width: 64em) {
        .podcast-list-channel ul li img {
          width: 150px; } }

.podcast-list.has-separator .grid-x {
  border-bottom: 0.5px solid #bdbdbd;
  padding: 1rem 0; }
  .podcast-list.has-separator .grid-x .article-img {
    margin-right: 1rem; }
    @media screen and (max-width: 47.9375em) {
      .podcast-list.has-separator .grid-x .article-img {
        margin-bottom: 1rem; } }

.card-podcast {
  padding: 0.2rem; }
  .podcast-black .card-podcast {
    background-color: transparent; }
    .podcast-black .card-podcast p {
      color: #8c8c8c; }

.podcast-social a {
  border: 1px solid #dddddd;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 5px;
  margin-right: 5px;
  display: inline-flex; }

.podcast-social img {
  width: 24px;
  height: 24px; }

.slick-loading {
  height: 400px;
  overflow: hidden;
  background: #f5f5f5 url(../img/ajax-loader.gif) no-repeat center center; }
  .slick-loading > div {
    opacity: 0; }
  .slick-loading.slick-initialized {
    height: auto;
    overflow: visible;
    background: none; }
  .slick-loading .slick-list {
    transition: height 250ms ease-in-out; }

.limelight-player {
  width: 100% !important;
  padding-bottom: 56.34%;
  height: 0 !important;
  margin-bottom: 1.5rem;
  overflow: hidden; }

.limelight-player .vjs-limelight-big-play {
  font-size: 12em !important; }

/* -------- Final say ---------------- Start ------------------------*/
@media screen and (max-width: 47.9375em) {
  .media-object-for-small {
    margin-left: 0;
    margin-right: 0; }
    .media-object-for-small .article-item {
      display: flex;
      margin-bottom: 1rem;
      flex-wrap: nowrap;
      margin-bottom: 1.5rem; }
      .media-object-for-small .article-item .article-item-img {
        flex: 0 1 auto;
        align-self: flex-start;
        margin-right: 0.75rem; }
        .media-object-for-small .article-item .article-item-img:first-child {
          padding-right: 0; }
        .media-object-for-small .article-item .article-item-img:last-child:not(:nth-child(2)) {
          padding-left: 0; }
        .media-object-for-small .article-item .article-item-img > :last-child {
          margin-bottom: 0; }
        .media-object-for-small .article-item .article-item-img img {
          max-width: 150px; }
      .media-object-for-small .article-item .article-item-info {
        padding-left: 1rem;
        flex: 0 1 auto; }
        .media-object-for-small .article-item .article-item-info:first-child {
          padding-right: 0; }
        .media-object-for-small .article-item .article-item-info:last-child:not(:nth-child(2)) {
          padding-left: 0; }
        .media-object-for-small .article-item .article-item-info > :last-child {
          margin-bottom: 0; } }

@media print, screen and (min-width: 64em) {
  .large-fluid-img img {
    width: 100%; } }

.uppercase {
  text-transform: uppercase !important; }

.no-border {
  border: 0; }

.block-custom-bg {
  background-color: #f2f2f2;
  color: #000000; }

@media screen and (max-width: 47.9375em) {
  .padding-horizontal-for-small {
    padding-left: 1.25rem;
    padding-right: 1.25rem; } }

.with-padding {
  padding: 1rem; }

.has-padding {
  padding: 1.5rem; }

.vertical-padd {
  padding: 1rem 0; }

.ft-sz-15 {
  font-size: 0.9375rem; }

.ft-sz-18 {
  font-size: 1.125rem; }

.ft-sz-28 {
  font-size: 1.75rem; }
  @media screen and (max-width: 47.9375em) {
    .ft-sz-28 {
      font-size: 1.25rem; } }

@media print, screen and (min-width: 64em) {
  .row .large-column.row.row,
  .row .row.row.large-columns {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0; } }

@media only screen and (max-width: 30em) {
  .xsmall-full-width > li,
  .xsmall-full-width.columns {
    list-style: outside none none;
    width: 100%; } }

.top-padding-1 {
  padding-top: 1rem; }

.top-padding-2 {
  padding-top: 2rem; }

.see-more-link a {
  font-family: "Independent Sans";
  font-size: 16px;
  color: #ec1a2e; }
  .see-more-link a:hover {
    color: #333333; }

.gray-font-color {
  color: #cccccc; }

.primary-font-color {
  color: #ec1a2e; }

.emperor-font-color {
  color: #555555; }
  h3.emperor-font-color a,
  h4.emperor-font-color a,
  h5.emperor-font-color a,
  h6.emperor-font-color a {
    color: inherit; }
    h3.emperor-font-color a:hover,
    h4.emperor-font-color a:hover,
    h5.emperor-font-color a:hover,
    h6.emperor-font-color a:hover {
      color: #ec1a2e; }

.border-gray-around {
  border: 1px solid #dadfe1; }

.thick-border-gray-around {
  border: 2px solid #dadfe1; }

.thicker-border-gray-around {
  border: 4px solid #dadfe1; }

.gray-border-bottom {
  border-bottom: 1px solid rgba(179, 179, 179, 0.3); }

.gray-border-top {
  border-top: 1px solid rgba(179, 179, 179, 0.3);
  padding-top: 0.5rem; }

.texture-pink-bg {
  background: transparent url(" . . /img/ texture-pink . jpg ") repeat center center; }
  .texture-pink-bg h2,
  .texture-pink-bg p,
  .texture-pink-bg a,
  .texture-pink-bg div {
    color: #ffffff; }

.white-bg {
  background-color: #ffffff; }

.light-gray-bg {
  background-color: #dadfe1; }
  .light-gray-bg.abs-element {
    background-color: #dadfe1; }

.white-smoke-bg {
  background-color: #f5f5f5;
  color: #000000; }

.white-smoke-opacity-bg {
  background-color: rgba(245, 245, 245, 0.6);
  color: #000000; }

.top-portion-black-bg:before {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 55%;
  content: "";
  background-color: #1c1c1c; }

.bottom-portion-smoke-bg:before {
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 30%;
  content: "";
  background-color: #f2f2f2; }

.white-to-smoke-gradient-bg {
  background: linear-gradient(to bottom, #ffffff, #f2f2f2); }

.white-to-light-gray-gradient-bg {
  background: linear-gradient(to bottom, #ffffff, #dadfe1); }

.smoke-to-white-gradient-bg {
  background: linear-gradient(to bottom, #f2f2f2, #ffffff); }

.light-gray-to-white-gradient-bg {
  background: linear-gradient(to bottom, #dadfe1 70%, #ffffff); }

.gradient-bg {
  position: relative;
  background: linear-gradient(90deg, rgba(247, 247, 247, 0.1) 33%, rgba(255, 255, 255, 0.6) 0); }
  .gradient-bg:after {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 783px;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -1; }

/*.texture-bg {
  background-image: url(" . . /img/ texture2 . png ");
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
}*/
.gray-bg {
  background-color: #f5f5f5;
  color: #281E1E; }
  .gray-bg h1,
  .gray-bg h2,
  .gray-bg h3,
  .gray-bg h4,
  .gray-bg h5,
  .gray-bg h6 {
    color: inherit; }
    .gray-bg h1 a,
    .gray-bg h2 a,
    .gray-bg h3 a,
    .gray-bg h4 a,
    .gray-bg h5 a,
    .gray-bg h6 a {
      color: inherit; }
      .gray-bg h1 a:hover,
      .gray-bg h2 a:hover,
      .gray-bg h3 a:hover,
      .gray-bg h4 a:hover,
      .gray-bg h5 a:hover,
      .gray-bg h6 a:hover {
        color: #ec1a2e; }

.dark-gray-bg {
  background-color: #252525;
  color: #ffffff; }
  .dark-gray-bg h1,
  .dark-gray-bg h2,
  .dark-gray-bg h3,
  .dark-gray-bg h4,
  .dark-gray-bg h5,
  .dark-gray-bg h6 {
    color: inherit; }
    .dark-gray-bg h1 a,
    .dark-gray-bg h2 a,
    .dark-gray-bg h3 a,
    .dark-gray-bg h4 a,
    .dark-gray-bg h5 a,
    .dark-gray-bg h6 a {
      color: inherit; }
      .dark-gray-bg h1 a:hover,
      .dark-gray-bg h2 a:hover,
      .dark-gray-bg h3 a:hover,
      .dark-gray-bg h4 a:hover,
      .dark-gray-bg h5 a:hover,
      .dark-gray-bg h6 a:hover {
        color: #ec1a2e; }

.button.hollow,
.pager--infinite-scroll li a {
  border-width: 1px; }

label span.required {
  color: red;
  font-weight: bold; }

@media print, screen and (min-width: 48em) {
  .last-no-margin article:last-child {
    margin-bottom: 0 !important; }
    .last-no-margin article:last-child h4,
    .last-no-margin article:last-child h5,
    .last-no-margin article:last-child h6 {
      margin-bottom: 0; } }

.maxWidth-medium,
.maxWidth-small,
.maxWidth-xsmall {
  float: none;
  margin-left: auto;
  margin-right: auto; }

.maxWidth-medium {
  max-width: 800px; }

.maxWidth-small {
  max-width: 670px; }

.maxWidth-xsmall {
  max-width: 360px; }

@media print, screen and (min-width: 64em) {
  .grid-collapse {
    padding-right: 0;
    padding-left: 0; } }

@media screen and (max-width: 47.9375em) {
  .small-grid-collapse {
    padding-right: 0;
    padding-left: 0; }
    .small-grid-collapse .article-item-info,
    .small-grid-collapse .article-item-overbox-inner {
      padding: 0 1.25rem; } }

@media screen and (min-width: 24.375em) and (max-width: 63.9375em) {
  .small-grid-collapse .grid-items .article-item-info,
  .small-grid-collapse .grid-items .article-item-overbox-inner {
    padding: 0; } }

.small-grid-collapse .padding-horizontal-for-small .block-title.style-v3 span,
.small-grid-collapse .padding-horizontal-for-small .section-title.style-v3 span,
.small-grid-collapse .padding-horizontal-for-small .page-title.style-v3 span {
  padding: 0; }

.small-grid-collapse .padding-horizontal-for-small .article-item-overbox-inner {
  padding: 0; }

.centered-column {
  margin-right: auto;
  margin-left: auto; }
  .centered-column, .centered-column:last-child:not(:first-child) {
    float: none;
    clear: both; }

.newsletter-area {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  background: #ffffff;
  position: relative;
  margin: 0;
  color: #000000; }
  .newsletter-area input {
    border-radius: 6px !important; }
  .newsletter-area p {
    font-size: 12px;
    margin-bottom: 5px; }
  .newsletter-area input[type=" submit "] {
    border: 0; }
    .newsletter-area input[type=" submit "]:hover {
      background-color: #ffffff;
      color: #ec1a2e; }
  .newsletter-area .input-group-field {
    height: 2.6rem;
    font-size: 14px;
    border: 0; }

.newsletter-area-intro h3 {
  margin: 0; }

.newsletter-area-intro span {
  display: block; }
  .newsletter-area-intro span:first-child {
    font-size: 1.875rem;
    color: #ec1a2e; }

.mc_embed_signup div#mce-responses {
  float: left;
  top: -1.4em;
  padding: 0em 0.5em 0em 0.5em;
  overflow: hidden;
  width: 90%;
  margin: 0 5%;
  clear: both; }

.mc_embed_signup div.response {
  margin: 1em 0;
  padding: 1em 0.5em 0.5em 0;
  font-weight: bold;
  float: left;
  top: -1.5em;
  z-index: 1;
  width: 80%; }

.mc_embed_signup #mce-error-response {
  display: none; }

.mc_embed_signup #mce-success-response {
  color: #529214;
  display: none; }

.mc_embed_signup label.error {
  display: block;
  float: none;
  width: auto;
  margin-left: 1.05em;
  text-align: left;
  padding: 0.5em 0; }

.banner-img {
  margin-bottom: 1rem; }

.schedule {
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
  background: #f5f5f5;
  padding: 1rem;
  margin: 1rem 0;
  font-family: "Independent Sans"; }
  .schedule .media-object {
    margin-bottom: 0; }
  .schedule .author-name {
    color: inherit;
    font-style: normal; }
  .schedule .author-live {
    font-size: 20px;
    font-weight: 700; }
  @media screen and (max-width: 47.9375em) {
    .schedule ul {
      margin-left: 0;
      margin-top: 1rem; } }
  .schedule ul li {
    list-style: none; }

.news-item {
  padding: 0 1.5rem; }

.stations-list ul {
  margin-left: 0; }
  .stations-list ul li {
    list-style: none;
    display: inline-block;
    margin: 0 0.2rem; }
    @media print, screen and (min-width: 64em) {
      .stations-list ul li {
        margin: 0 0.5rem; } }

/* Drupal admin styles ----- START -----*/
#admin-menu {
  z-index: 10000001; }

#overlay-container,
.overlay-modal-background,
.overlay-element {
  z-index: 9999999; }

.overlay-active {
  z-index: 10000000; }

div#toolbar a#edit-shortcuts {
  position: relative; }

html.overlay-open body {
  position: relative; }

.password-strength,
div.password-confirm {
  display: none; }
  @media print, screen and (min-width: 48em) {
    .password-strength,
    div.password-confirm {
      display: block; } }

.drupal-tabs ul.tabs {
  list-style: none;
  margin-right: 0; }
  .drupal-tabs ul.tabs li {
    background-color: #f8f8f8;
    border-radius: 5px;
    display: inline-block;
    margin: 0 5px 8px;
    padding: 5px 15px; }
    .drupal-tabs ul.tabs li:hover, .drupal-tabs ul.tabs li.active {
      background: #ec1a2e; }
      .drupal-tabs ul.tabs li:hover a, .drupal-tabs ul.tabs li.active a {
        color: white; }

.page-taxonomy-term .article-teaser .article-category {
  display: none; }

.load-more ul li {
  list-style: none; }

.pager--infinite-scroll {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  text-align: center; }
  .pager--infinite-scroll li a {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 1rem 0;
    font-family: inherit;
    padding: 0.85em 1em;
    -webkit-appearance: none;
    border: 1px solid transparent;
    border-radius: 0;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    font-size: 0.9rem;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    border: 1px solid #cccccc;
    color: #cccccc;
    background-color: #cccccc;
    color: #281E1E;
    font-family: "Independent Serif";
    text-transform: uppercase;
    width: 30%; }
    [data-whatinput='mouse'] .pager--infinite-scroll li a {
      outline: 0; }
    .pager--infinite-scroll li a, .pager--infinite-scroll li a:hover, .pager--infinite-scroll li a:focus {
      background-color: transparent; }
    .pager--infinite-scroll li a.disabled, .pager--infinite-scroll li a.disabled:hover, .pager--infinite-scroll li a.disabled:focus, .pager--infinite-scroll li a[disabled], .pager--infinite-scroll li a[disabled]:hover, .pager--infinite-scroll li a[disabled]:focus {
      background-color: transparent; }
    .pager--infinite-scroll li a:hover, .pager--infinite-scroll li a:focus {
      border-color: #666666;
      color: #666666; }
      .pager--infinite-scroll li a:hover.disabled, .pager--infinite-scroll li a:hover[disabled], .pager--infinite-scroll li a:focus.disabled, .pager--infinite-scroll li a:focus[disabled] {
        border: 1px solid #cccccc;
        color: #cccccc; }
    .pager--infinite-scroll li a:hover, .pager--infinite-scroll li a:focus {
      background-color: transparent;
      color: #281E1E; }

.admin-menu ul.tabs.primary {
  list-style: none; }
  .admin-menu ul.tabs.primary li {
    display: inline-block;
    margin: 0 5px;
    padding: 5px 15px; }
    .admin-menu ul.tabs.primary li:hover, .admin-menu ul.tabs.primary li.active {
      background: #ec1a2e; }
      .admin-menu ul.tabs.primary li:hover a, .admin-menu ul.tabs.primary li.active a {
        color: white; }

.element-invisible {
  display: none; }

/* Drupal admin styles ----- END -----*/
/* ------- Socials Btns Styles ------- START -------- */
header .socials-area,
#sticky-header .socials-area {
  position: relative;
  display: inline-block;
  z-index: 1; }

.canvas-panel .socials-area {
  padding: 1.5rem 0; }

footer .socials-area {
  margin-bottom: 0;
  line-height: 18px;
  font-weight: 600; }
  footer .socials-area h6 {
    font-size: 14px; }

.social-btn {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  color: #b8b8b8;
  font-size: 1.25rem;
  margin: 0 8px 0 0;
  line-height: 1; }
  [dir="rtl"] .social-btn {
    margin: 0 0 0 8px; }
  .social-btn:hover {
    color: #000000;
    transition: color 0.25s linear; }
  .share_tools .social-btn {
    margin: 0 0 0 4px; }
  .entry-meta-wrapper .social-btn.round {
    background-color: #888888;
    color: #ffffff; }
    .entry-meta-wrapper .social-btn.round:hover, .entry-meta-wrapper .social-btn.round:focus {
      background-color: #000000; }
  .social-btn.round {
    width: 34px;
    height: 34px;
    line-height: 38px;
    font-size: 16px;
    background-color: #ec1a2e;
    vertical-align: middle;
    color: #ffffff;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .canvas-panel .social-btn {
    border-radius: 50%;
    background: #1d1d1b;
    color: #ffffff;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center; }
  .social-btn img {
    width: 17px; }

.socials-block {
  padding: 2rem 0;
  text-align: center; }
  .socials-block a {
    margin: 0 0.25rem;
    font-size: 1.25rem;
    padding: 0.75rem;
    background: #ffffff; }
    .socials-block a:hover, .socials-block a:active, .socials-block a:focus {
      background-color: #ec1a2e !important;
      color: #ffffff; }
    .socials-block a i {
      vertical-align: middle; }

.envelope-btn {
  color: #000000; }

.envelope-btn-hover:hover {
  color: #000000 !important; }

.rss-btn {
  color: #fa9b39; }

.rss-btn-hover:hover {
  color: #fa9b39 !important; }

.youtube-btn {
  color: #bb0000; }

.youtube-btn-hover:hover {
  color: #bb0000 !important; }

.google-plus-btn {
  color: #dd4b39; }

.google-plus-btn-hover:hover {
  color: #dd4b39 !important; }

.twitter-btn {
  color: #00a6d4; }

.twitter-btn-hover:hover {
  color: #00a6d4 !important; }

.facebook-btn {
  color: #5168a4; }

.facebook-btn-hover:hover {
  color: #5168a4 !important; }

.whatsapp-btn {
  color: #2bb37c; }

.whatsapp-btn-hover:hover {
  color: #2bb37c !important; }

.instagram-btn {
  color: #a63f1f; }

.instagram-btn-hover:hover {
  color: #a63f1f !important; }

.snapchat-btn {
  color: #fffc00;
  text-shadow: 0 0 2px #888888; }

.telegram-btn {
  color: #0088cc; }

.linkedin-btn {
  color: #007bb6; }

.pinterest-btn {
  color: #cb2027; }

.socials-btns-big {
  margin-bottom: 1.5rem; }
  .socials-btns-big .social-btn {
    color: #ffffff;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 1.75rem;
    margin: 0 10px;
    transition: all 0.25s ease 0s; }
    .socials-btns-big .social-btn:hover {
      background-color: #ec1a2e; }

.envelope-btn-bg:hover {
  background-color: #000000 !important;
  color: #ffffff; }

.rss-btn-bg:hover {
  background-color: #fa9b39 !important;
  color: #ffffff; }

.youtube-btn-bg:hover {
  background-color: #bb0000 !important;
  color: #ffffff; }

.youtube-btn-bg-hover:hover {
  background-color: #bb0000 !important;
  color: #ffffff; }

.gplus-btn-bg:hover {
  background-color: #dd4b39 !important;
  color: #ffffff; }

.twitter-btn-bg:hover {
  background-color: #000000 !important;
  color: #ffffff; }

.twitter-btn-bg-hover:hover {
  background-color: #00a6d4 !important;
  color: #ffffff; }

.facebook-btn-bg:hover {
  background-color: #5168a4 !important;
  color: #ffffff; }

.facebook-btn-bg-hover:hover {
  background-color: #5168a4 !important;
  color: #ffffff; }

.instagram-btn-bg {
  background-color: #a63f1f !important;
  color: #ffffff; }

.instagram-btn-bg-hover:hover {
  background-color: #a63f1f !important;
  color: #ffffff; }

.google-plus-btn-bg-hover:hover {
  background-color: #dd4b39 !important;
  color: #ffffff; }

.snapchat-btn-bg {
  background-color: #fffc00 !important;
  color: #ffffff; }
  .snapchat-btn-bg:hover {
    background-color: #fffc00;
    color: #ffffff; }

.telegram-btn-bg {
  background-color: #0088cc !important;
  color: #ffffff; }
  .telegram-btn-bg .icon-telegram1:before {
    color: #ffffff; }
  .telegram-btn-bg:hover {
    background-color: #0088cc;
    color: #ffffff; }

.linkedin-btn-bg:hover {
  background-color: #007bb6 !important;
  color: #ffffff; }

.pinterest-btn-bg:hover {
  background-color: #cb2027 !important;
  color: #ffffff; }

.whatsapp-btn-bg:hover {
  background-color: #2bb37c !important;
  color: #ffffff; }

/* ------- Socials Btns Styles ------- END -------- */
#return-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #ec1a2e;
  opecity: 0.7;
  width: 50px;
  height: 50px;
  display: block;
  text-decoration: none;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  border-radius: 35px;
  display: none;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  text-align: center;
  z-index: 10; }

#return-to-top i {
  color: #fff;
  margin: 0;
  position: relative;
  left: 0;
  top: 13px;
  font-size: 19px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease; }

#return-to-top:hover {
  background: rgba(0, 0, 0, 0.9); }

#return-to-top:hover i {
  color: #fff;
  top: 5px; }

/* -------- Final say ---------------- End ------------------------*/
.article-v2,
.article-v2 * {
  font-family: Yekan, "Yekan Numbers" !important; }

.article-v3,
.article-v3 * {
  font-family: "Noto Naskh Arabic" !important; }

.persian-theme .entry-media .caption-field p {
  font-size: 1rem; }

.arabic-theme .nav-container > .menu > li.menu-6921 a {
  text-align: center;
  padding: 8px 16px;
  border: 3px solid #ec1a2e; }
  .arabic-theme .nav-container > .menu > li.menu-6921 a:hover {
    color: #ec1a2e; }

.arabic-theme .nav-container > .menu > li.active a {
  color: #ffffff; }
  .arabic-theme .nav-container > .menu > li.active a:hover {
    color: #ffffff; }

.indy-tv main {
  padding: 0.5rem 0 0; }

.indy-tv .bg-gray {
  background-color: #222;
  color: white; }
  .indy-tv .bg-gray .article-item-title a {
    color: white; }

.indy-tv .logo-tv a {
  margin: 0 auto;
  height: 107px;
  background-size: 38%;
  border-bottom: 0.5px solid #bdbdbd;
  display: block;
  text-indent: -999em;
  overflow: hidden;
  background-image: url(../img/logotv.png?ffd);
  background-repeat: no-repeat;
  background-position: 50% 50%; }

.indy-tv .slick-track {
  margin-left: 0;
  margin-right: 0; }

.indy-tv .currentVideo > h6 {
  font-weight: 300; }
  .indy-tv .currentVideo > h6 span {
    font-weight: 700; }

.indy-tv .upNextVideos > h6 {
  font-weight: 700;
  padding: 30px 0 10px; }

.indy-tv .media-video .article-item-img-tv {
  position: relative; }
  .indy-tv .media-video .article-item-img-tv:before {
    width: 23px;
    height: 23px;
    line-height: 23px;
    font-size: 16px;
    background: rgba(236, 26, 46, 0.5);
    color: #ffffff;
    border-radius: 50%;
    cursor: pointer;
    text-align: center;
    content: "\e90b";
    position: absolute;
    font-family: "icomoon";
    z-index: 1;
    bottom: 0;
    text-indent: -3px;
    margin: 0.5rem; }
  .indy-tv .media-video .article-item-img-tv p.duration {
    position: absolute;
    bottom: 0;
    right: 0;
    left: auto;
    border-radius: 2px;
    margin: 0.5rem 0.3px;
    color: #fff;
    background: #222;
    font-size: 12px;
    line-height: 13px;
    padding: 2px 4px; }

.indy-tv .objects-list.has-separator .object-item {
  border-bottom: 0.5px solid #bdbdbd; }
  .indy-tv .objects-list.has-separator .object-item .media-object.media-video .media-object-img:before,
  .indy-tv .objects-list.has-separator .object-item .media-object.media-video .article-item-img-tv:before {
    width: 23px;
    height: 23px;
    line-height: 23px;
    font-size: 16px;
    background: rgba(236, 26, 46, 0.5); }
  .indy-tv .objects-list.has-separator .object-item .media-object.media-video .media-object-img p.duration,
  .indy-tv .objects-list.has-separator .object-item .media-object.media-video .article-item-img-tv p.duration {
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 2px;
    margin: 0.5rem 0.3px;
    color: #fff;
    background: #222;
    font-size: 12px;
    line-height: 13px;
    padding: 2px 4px; }

.indy-tv .float-left {
  float: left; }

.indy-tv .float-right {
  float: right; }

.indy-tv .tv-slider .article-item {
  margin-right: 15px; }

.indy-tv .tv-slider .slick-prev,
.indy-tv .tv-slider .slick-next {
  top: 38%; }

.indy-tv .tv-slider .slick-next {
  right: 25px;
  left: auto; }

.indy-tv .playlistSlabAndTitle {
  border-bottom: 0.5px solid #bdbdbd; }
  .indy-tv .playlistSlabAndTitle > h4 {
    padding-left: 40px;
    background-image: url(../img/smalltvlogo.png?sss);
    background-repeat: no-repeat;
    background-position: left;
    background-size: 30px 30px; }
    .indy-tv .playlistSlabAndTitle > h4 a {
      font-weight: 300;
      color: #000; }

/*------ Podcast black---*/
.podcast-items {
  color: #888888;
  padding-bottom: 2rem; }
  .podcast-items .items {
    border: 1px solid #e5e8ea;
    margin: 2rem 0; }
    .podcast-black .podcast-items .items {
      border-color: #636363; }
    .podcast-items .items .article-item-img {
      position: relative; }
      .podcast-items .items .article-item-img .tagged {
        padding: 0.5rem;
        position: absolute;
        bottom: 10px;
        right: 15px;
        width: 90px;
        z-index: 1; }
        .podcast-items .items .article-item-img .tagged a {
          box-shadow: -5px 0px 0 7px #00b5f6, 5px 0px 0 7px #00b5f6, 0 0 0 7px #00b5f6;
          box-decoration-break: clone;
          background-color: #00b5f6;
          color: #fff;
          line-height: 2; }
      .podcast-items .items .article-item-img .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #000000;
        opacity: 0.5; }
      .podcast-items .items .article-item-img .align-center-middle {
        color: #ffffff;
        font-size: 2rem;
        font-weight: bold;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 42%;
        line-height: 1.5; }
        .podcast-items .items .article-item-img .align-center-middle a {
          color: #ffffff; }
        .podcast-items .items .article-item-img .align-center-middle:first-line,
        .podcast-items .items .article-item-img .align-center-middle .small-link {
          font-size: 18px;
          display: block; }
  .podcast-items .info {
    margin: 0 1rem;
    position: relative;
    height: 100%; }
    .podcast-items .info .caption {
      margin: 1rem 0;
      font-size: 1.1rem;
      line-height: 1.8; }
    .podcast-items .info .actions {
      position: absolute;
      bottom: 30px; }
      .podcast-items .info .actions a img {
        max-width: 145px;
        height: 37px; }

.podcast-black .pdt0 {
  padding-top: 0 !important; }

.podcast-black .mb0 {
  margin-bottom: 0 !important; }

.podcast-black .breadcrumbs li a {
  color: #888888; }

.podcast-black .breadcrumbs li:not(:last-child)::after {
  color: #888888; }

.podcast-black .breadcrumbs li .active {
  color: #ec1a2e; }

.podcast-black main {
  background-color: #000000;
  margin-bottom: 0; }

.podcast-black #main-footer {
  margin-top: 0; }
  .podcast-black #main-footer .footer-menu a {
    color: #281E1E; }
    .podcast-black #main-footer .footer-menu a:hover {
      color: #ec1a2e; }

.podcast-black h3,
.podcast-black h4,
.podcast-black a {
  color: #ffffff; }
  .podcast-black h3:hover,
  .podcast-black h4:hover,
  .podcast-black a:hover {
    color: #ec1a2e; }

.podcast-black .section-title {
  color: #ffffff; }
  .podcast-black .section-title a:not(.see-all) {
    color: #ffffff; }
    .podcast-black .section-title a:not(.see-all):hover {
      color: #ec1a2e; }

@media only screen and (max-width: 768px) {
  .podcast-black .actions {
    position: relative !important;
    bottom: 0 !important;
    margin-bottom: 10px; }
    .podcast-black .actions a {
      margin: 10px 0;
      display: inline-block; } }

.date1 {
  font-size: 0.8rem;
  color: #333333; }

.headtxt {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-family: "Independent Sans"; }

.imgComment {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }

.MainHeroImg {
  display: flex;
  width: 50%;
  justify-content: center;
  padding: 0;
  margin-bottom: 1rem; }
  @media screen and (max-width: 47.9375em) {
    .MainHeroImg {
      width: 100%; } }
  @media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .MainHeroImg {
      height: 400px; } }
  .MainHeroImg.grid-x {
    width: 100%; }

.four-articles1 .article-item-content.OverText {
  position: absolute; }
  @media screen and (max-width: 47.9375em) {
    .four-articles1 .article-item-content.OverText {
      position: inherit; } }

@media screen and (max-width: 47.9375em) {
  .MainHeroSmall {
    display: none; } }

.form-registration .form-registration-img {
  margin-left: 11rem;
  display: flex; }
  .form-registration .form-registration-img .form-registration-img-caption {
    position: absolute;
    padding: 0.5rem;
    text-align: center;
    width: 100%;
    bottom: 0;
    background: -webkit-gradient(linear, left bottom, left top, color-stop(1, rgba(255, 255, 255, 0.3)), color-stop(0, white)); }

.form-registration-group {
  padding: 0px;
  display: flex; }
  .form-registration-group .form-registration-input:focus {
    border: 2px solid;
    box-shadow: 0 0 5px; }
  .form-registration-group .form-registration-submit-button {
    text-transform: uppercase;
    margin-bottom: 0;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 1rem 0;
    font-family: inherit;
    padding: 0.85em 1em;
    -webkit-appearance: none;
    border: 1px solid transparent;
    border-radius: 0;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    font-size: 0.9rem;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    background-color: #ec1a2e;
    color: #000000;
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    padding: 0 10px;
    margin-left: 12px;
    border: none;
    border-radius: 4px;
    flex-shrink: 0;
    font-size: 13px;
    line-height: 20px;
    background: #999596;
    color: #fff;
    appearance: none;
    text-transform: uppercase;
    transition: color 0.4s linear;
    cursor: pointer;
    width: 30%; }
    [data-whatinput='mouse'] .form-registration-group .form-registration-submit-button {
      outline: 0; }
    .form-registration-group .form-registration-submit-button:hover, .form-registration-group .form-registration-submit-button:focus {
      background-color: #c21021;
      color: #000000; }
  .form-registration-group .form-registration-social-button {
    text-transform: uppercase;
    font-size: 16px;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 1rem 0;
    font-family: inherit;
    padding: 0.85em 1em;
    -webkit-appearance: none;
    border: 1px solid transparent;
    border-radius: 0;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    font-size: 0.9rem;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    background-color: auto;
    color: #ffffff;
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    border-radius: 2px;
    width: 57%; }
    [data-whatinput='mouse'] .form-registration-group .form-registration-social-button {
      outline: 0; }
    .form-registration-group .form-registration-social-button:hover, .form-registration-group .form-registration-social-button:focus {
      background-color: hollow;
      color: #ffffff; }
    .form-registration-group .form-registration-social-button .fa {
      vertical-align: text-top;
      margin-right: 0.5rem; }
  .form-registration-group .form-registration-member-signin {
    text-align: center;
    font-weight: bold;
    font-size: 14px; }
    .form-registration-group .form-registration-member-signin a {
      margin-left: 0.25rem; }
  .form-registration-group .form-registration-terms {
    text-align: center;
    font-size: 12px; }
    .form-registration-group .form-registration-terms a:first-child {
      margin-right: 0.25rem; }
    .form-registration-group .form-registration-terms a:last-child {
      margin-left: 0.25rem; }
  .form-registration-group .or-divider {
    position: relative;
    margin: 1.5rem 0px;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    height: 0px;
    border: 1px solid #dadfe1; }
    .form-registration-group .or-divider span {
      color: #333333;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -12px;
      padding: 0 8px; }

.imglogo {
  height: 15%; }

.button.hollow.button {
  margin-right: 1rem;
  /* border-radius: 6px; */
  border-color: #337e81;
  border: 2px px solid #337e81;
  border-radius: 6px;
  color: #337e81;
  /* font-weight: bold; */
  border: 2px solid;
  padding-left: 10px;
  font-weight: bold;
  /* align-items: center; */
  /* justify-content: center; */
  box-sizing: border-box;
  font-size: medium;
  border: 2px solid #337e81;
  border-radius: 6px;
  color: #337e81;
  /* display: none; */
  font-family: Indy Sans, sans-serif;
  /* font-weight: 500; */
  /* margin-right: 16px; */
  margin-left: 0;
  width: 102px;
  height: 40px;
  line-height: 16px;
  /* padding-top: 2px;*/ }
  [dir="rtl"] .button.hollow.button {
    margin-left: 1rem; }

.header1 {
  margin: 0 auto;
  box-sizing: border-box;
  background: #fff;
  position: relative;
  display: flex;
  justify-content: space-between;
  grid-gap: 2px; }

.header__text {
  padding-top: 12px;
  flex: 1;
  margin-bottom: -91px; }

.svg {
  flex-shrink: 0;
  display: block; }

.header__title {
  margin-top: 16px;
  font-family: "Independent Serif";
  font-size: 20px;
  font-weight: 700;
  line-height: 18px;
  color: #222222; }

.image-container {
  width: 135px;
  height: 135px;
  margin-left: 6px; }
  @media screen and (max-width: 47.9375em) {
    .image-container {
      display: none; } }

.section-newsletter-signup__form {
  position: relative;
  margin-top: 20px;
  padding-right: 12px; }

.section-newsletter-signup__input {
  display: flex; }
  @media screen and (max-width: 47.9375em) {
    .section-newsletter-signup__input {
      display: grid;
      margin-bottom: 1rem; } }

.input[type="text"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  flex-grow: 3;
  width: 100%;
  height: 38px;
  border-radius: 4px;
  border: solid 1px #bdbdbd;
  padding-left: 12px;
  padding-right: 12px;
  color: #4e4e4e;
  outline: none;
  font-size: 16px; }

input#offers {
  height: 30px;
  width: 30px; }

.submit-button {
  background-color: #bdbdbd;
  padding: 0 10px;
  margin-left: 12px;
  border: none;
  border-radius: 4px;
  flex-shrink: 0;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  background: #bdbdbd;
  color: #fff;
  appearance: none;
  text-transform: uppercase;
  transition: color 0.4s linear;
  cursor: pointer;
  width: 33%;
  height: 37px; }

.offer {
  display: flex;
  align-items: center;
  margin-top: 0px;
  font-family: "Independent Sans", sans-serif;
  font-size: 10px;
  line-height: 12px;
  color: #4e4e4e; }

.offers-label .logo {
  display: none; }

.offers-label {
  display: block;
  margin: 0;
  font-size: 11px;
  font-weight: normal;
  line-height: 1.8;
  margin-left: 0.5rem;
  color: #000000; }

.section-newsletter-signup {
  padding: 0 0 12px 12px;
  margin: 0 auto;
  border: 1px solid #d3d3d3;
  box-sizing: border-box;
  background: #fff;
  margin-bottom: 1rem; }

.checkbox {
  height: 33px;
  position: relative;
  flex-shrink: 0; }

.form-registration-submit-button {
  padding: 0 10px;
  margin-left: 12px;
  border: none;
  border-radius: 4px;
  flex-shrink: 0;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  background: #bdbdbd;
  color: #fff;
  appearance: none;
  text-transform: uppercase;
  transition: color 0.4s linear;
  cursor: pointer;
  height: 38px; }
  @media screen and (max-width: 47.9375em) {
    .form-registration-submit-button {
      margin-left: 0px; } }

input#email-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  flex-grow: 3;
  width: 100%;
  height: 38px;
  border-radius: 4px;
  border: solid 1px #bdbdbd;
  padding-left: 12px;
  padding-right: 12px;
  color: #4e4e4e;
  outline: none;
  font-size: 16px; }

label {
  font-family: "Independent Sans", sans-serif;
  font-size: 10px;
  line-height: 12px;
  color: #4e4e4e; }
  label a {
    text-decoration: underline;
    color: #4e4e4e; }

@media screen and (max-width: 47.9375em) {
  .cell.medium-6.large-6 {
    width: 110%; } }

.cell.small-6.medium-3 {
  margin-bottom: 0.5rem; }

.audio-player {
  margin-bottom: 1rem; }
  .podcast-floater .audio-player {
    margin-bottom: 0; }
  .audio-player audio {
    width: 100%; }

.podcast-floater {
  background-color: #ffffff;
  z-index: 99;
  padding: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0; }
  @media print, screen and (min-width: 64em) {
    .podcast-floater {
      padding: 1rem 4rem; } }
  .podcast-floater .btn-share-wrap .btn-share {
    margin: 0 10px; }
  .podcast-floater .close-btn {
    cursor: pointer; }
    @media screen and (max-width: 47.9375em) {
      .podcast-floater .close-btn {
        padding-top: 10px;
        padding-right: 13px;
        padding-left: 0;
        padding-bottom: 10px;
        border-right: 1px solid #e0e0e0;
        color: #90a3a2;
        font-size: 12px; } }

@media screen and (max-width: 47.9375em) {
  .podcast-floater-item {
    padding: 10px 0; } }

.podcast-floater-item a {
  color: #281e1e; }

.podcast-floater-item img {
  width: 50px; }
  @media screen and (max-width: 47.9375em) {
    .podcast-floater-item img {
      display: none; } }

.podcast-floater-item h6 {
  margin-bottom: 0; }
  @media screen and (max-width: 47.9375em) {
    .podcast-floater-item h6 {
      font-size: 11px; } }

.page-logo {
  margin: 0 auto;
  border-bottom: 0.5px solid #bdbdbd;
  padding-bottom: 2rem;
  justify-content: center; }
  .page-logo img {
    width: 300px; }
    @media print, screen and (min-width: 64em) {
      .page-logo img {
        width: 400px; } }

.subscribe-box {
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 0 auto 16px; }
  @media print, screen and (min-width: 48em) {
    .subscribe-box {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 60px;
      max-width: 740px;
      padding: 40px 0; } }
  .subscribe-box__img {
    display: flex;
    justify-content: center; }
    .subscribe-box__img img {
      width: 80px; }
      @media print, screen and (min-width: 48em) {
        .subscribe-box__img img {
          width: 180px; } }
  .subscribe-box__desc {
    text-align: center; }
    @media print, screen and (min-width: 48em) {
      .subscribe-box__desc {
        text-align: start;
        max-width: 440px; } }
    .subscribe-box__desc h2 {
      color: #ee3e3e;
      font-size: 37px;
      margin: 0; }
      @media print, screen and (min-width: 48em) {
        .subscribe-box__desc h2 {
          font-size: 45px; } }
    .subscribe-box__desc p {
      font-size: 12px; }
      @media print, screen and (min-width: 48em) {
        .subscribe-box__desc p {
          font-size: 16px;
          margin-bottom: 1.5rem; } }
  .subscribe-box__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px; }
    @media print, screen and (min-width: 48em) {
      .subscribe-box__form {
        flex-direction: row;
        gap: 10px; } }
    .subscribe-box__form input[type="text"],
    .subscribe-box__form input[type="text"] {
      background: #efefef;
      border-radius: 20px;
      width: 181px;
      padding: 5px 15px;
      border: 0;
      box-shadow: none;
      height: auto;
      font-size: 14px;
      margin: 0; }
      @media print, screen and (min-width: 48em) {
        .subscribe-box__form input[type="text"],
        .subscribe-box__form input[type="text"] {
          font-size: 15px;
          padding: 7px 15px;
          width: 300px; } }
    .subscribe-box__form button,
    .subscribe-box__form input[type="submit"] {
      border: 0;
      background: #ee3e3e;
      border-radius: 15px;
      color: #ffffff;
      font-weight: bold;
      padding: 5px 20px;
      font-size: 10px;
      cursor: pointer;
      transition: all 0.3s ease; }
      .subscribe-box__form button:hover,
      .subscribe-box__form input[type="submit"]:hover {
        background: rgba(0, 0, 0, 0.9);
        transition: all 0.3s ease; }
      @media print, screen and (min-width: 48em) {
        .subscribe-box__form button,
        .subscribe-box__form input[type="submit"] {
          font-size: 14px;
          padding: 7px 20px; } }
