$font-path: "../fonts";

@import 'icomoon';

@font-face {
  font-family: "Independent Serif";
  src: url("#{$font-path}/Independent-Serif-Regular.woff2") format("woff2"), url("#{$font-path}/Independent-Serif-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal
}

@font-face {
  font-family: "Independent Serif";
  src: url("#{$font-path}/Independent-Serif-Regular-Italic.woff2") format("woff2"), url("#{$font-path}/Independent-Serif-Regular-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic
}

@font-face {
  font-family: "Independent Serif";
  src: url("#{$font-path}/Independent-Serif-Medium.woff2") format("woff2"), url("#{$font-path}/Independent-Serif-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal
}

@font-face {
  font-family: "Independent Serif";
  src: url("#{$font-path}/Independent-Serif-Medium-Italic.woff2") format("woff2"), url("#{$font-path}/Independent-Serif-Medium-Italic.woff") format("woff");
  font-weight: 500;
  font-style: italic
}

@font-face {
  font-family: "Independent Serif";
  src: url("#{$font-path}/Independent-Serif-Bold.woff2") format("woff2"), url("#{$font-path}/Independent-Serif-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal
}

@font-face {
  font-family: "Independent Serif";
  src: url("#{$font-path}/Independent-Serif-Bold-Italic.woff2") format("woff2"), url("#{$font-path}/Independent-Serif-Bold-Italic.woff") format("woff");
  font-weight: 700;
  font-style: italic
}

@font-face {
  font-family: "Independent Serif";
  src: url("#{$font-path}/Independent-Serif-Light.woff2") format("woff2"), url("#{$font-path}/Independent-Serif-Light.woff") format("woff");
  font-style: normal;
  font-weight: 300
}

@font-face {
  font-family: "Independent Serif";
  src: url("#{$font-path}/Independent-Serif-Light-Italic.woff2") format("woff2"), url("#{$font-path}/Independent-Serif-Light-Italic.woff") format("woff");
  font-style: italic;
  font-weight: 400
}

@font-face {
  font-family: "Independent Sans";
  src: url("#{$font-path}/Independent-Sans-Regular.woff2") format("woff2"), url("#{$font-path}/Independent-Sans-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal
}

@font-face {
  font-family: "Independent Sans";
  src: url("#{$font-path}/Independent-Sans-Light.woff2") format("woff2"), url("#{$font-path}/Independent-Sans-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal
}

@font-face {
  font-family: "Independent Sans";
  src: url("#{$font-path}/Independent-Sans-Medium.woff2") format("woff2"), url("#{$font-path}/Independent-Sans-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal
}

@font-face {
  font-family: "Independent Sans";
  src: url("#{$font-path}/Independent-Sans-Bold.woff2") format("woff2"), url("#{$font-path}/Independent-Sans-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal
}

@font-face {
  font-family: "Independent Sans";
  src: url("#{$font-path}/Independent-Sans-Black.woff2") format("woff2"), url("#{$font-path}/Independent-Sans-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal
}
