.headline-slider {
  height: 390px;
  overflow: hidden;
  position: relative;
  margin-bottom: 1rem;
  font-family: $font-secondary;
  @include breakpoint(medium) {
    height: 450px;
  }
  @include breakpoint(large) {
    height: 390px;
  }
  &.slick-dotted {
    margin-bottom: 0.75rem;
  }
}

.headline-slider:before {
  /*left: 0;
  bottom: 0;
  width: 100%;
  height: 37px;
  content: '';
  z-index: 2;
  background: #e3e3e3;
  position: absolute;*/
}

.headline-slider-item.son_dakika_sol .image, .headline-slider-item.son_dakika_ust .image, .headline-slider-item.son_durum .image, .headline-slider-item.yeni_haber .image {
  position: relative
}

.headline-slider-item.son_dakika_sol .image:after, .headline-slider-item.son_dakika_ust .image:after, .headline-slider-item.son_durum .image:after, .headline-slider-item.yeni_haber .image:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  position: absolute
}

/*.headline-slider-item.son_dakika_ust .image:after {
  background: url(../images/headline-animation/breaking-news-1.gif)
}

.headline-slider-item.son_dakika_sol .image:after {
  background: url(../images/headline-animation/breaking-news-2.gif)
}

.headline-slider-item.son_durum .image:after {
  background: url(../images/headline-animation/last-news.gif)
}

.headline-slider-item.yeni_haber .image:after {
  background: url(../images/headline-animation/new-news.gif)
}*/

.headline-slider .slick-dots {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  left: 0;
  bottom: 0;
  z-index: 3;
  height: 37px;
  background: #E3E3E3;
  -webkit-box-shadow: inset 1px 0 0 #C7C7C7, inset -1px 0 0 #C7C7C7, inset 0 -1px 0 #C7C7C7;
  box-shadow: inset 1px 0 0 #C7C7C7, inset -1px 0 0 #C7C7C7, inset 0 -1px 0 #C7C7C7;
  @include breakpoint(small only) {
    display: none !important;
  }
}

.headline-slider .slick-dots li, .headline-slider .slick-dots span {
  flex: 1;
  width: auto;
  height: auto;
  margin: 0;
  text-align: center
}

.headline-slider .slick-dots li + li, .headline-slider .slick-dots li + span, .headline-slider .slick-dots span + li, .headline-slider .slick-dots span + span {
  -webkit-box-shadow: inset 1px 0 0 #c7c7c7;
  box-shadow: inset 1px 0 0 #c7c7c7
}

.headline-slider .slick-dots li a, .headline-slider .slick-dots span a {
  width: auto;
  height: 37px;
  color: #444;
  display: block;
  font-size: 13px;
  line-height: 37px;
}

.headline-slider .slick-dots span, .headline-slider .slick-slide img {
  display: inline-block;
}

.headline-slider .slick-slide {
  .image {
    height: 390px;
    position: relative;
    @include breakpoint(medium) {
      height: 413px;
    }
    @include breakpoint(large) {
      height: 353px;
    }
  }
  img {
    @extend .fill-content;
  }
}

.headline-slider .slick-dots li.slick-active a, .headline-slider .slick-dots span.slick-active a {
  color: #fff;
  background-color: #D8242F
}

.ie9 .headline-slider .slick-dots {
  z-index: 999
}

.headline-slider .slick-slide {
  text-align: center
}

.headline-slider .slick-slide .title, .small-headline-slider .slick-slide .title {
  display: block;
  bottom: 0;
  position: absolute;
  padding: 10px;
  text-align: center;
  width: 100%;
  background: rgba(0, 0 ,0,0.6);
  line-height: 1.2;
  color: $white;
  font-size: 1.25rem;
}

.headline-slider .title {
  color: #faf8f8
}

.slider-arrow {
  top: 50%;
  width: 20px;
  height: 35px;
  z-index: 1;
  margin-top: -12px;
  color: rgba(255, 255, 255, .45);
  position: absolute;
  cursor: pointer;
}

.slider-arrow:hover {
  color: rgba(255, 255, 255, .9);
  opacity: 0.7;
}

.slider-arrow.prev {
  left: 20px;
  background: url("../img/svg/chevron-left.svg?zmxcfzxmc") top left no-repeat;
}

.slider-arrow.next {
  right: 20px;
  background: url("../img/svg/chevron-right.svg?zmxcfzxmc") top left no-repeat;
}

.headline-slider .slider-arrow {
  width: 23px;
  height: 41px;
  margin-top: -39px;
  background-size: contain;
}

.headline-slider .slider-arrow:hover {
  color: rgba(255, 255, 255, .55)
}

.ie9 .headline-slider .slider-arrow {
  z-index: 1000;
}

.small-headline-slider .slider-arrow {
  margin-top: -54px;
}

/* ------------------ */

.small-headline {
  font-family: $font-secondary;
  max-width: 320px;
  margin: 0 auto;
}

.small-headline-header {
  color: #ECECEC;
  height: 40px;
  line-height: 1;
  background: #222;
  overflow-x: auto;
  overflow-y: hidden;
  //text-align: center;
  border-top: 2px solid #D72530;
  //display: flex;
  //flex-direction: row;
  //align-items: center;
  //justify-content: space-between;
  white-space: nowrap;
  width: 100%;
  @include breakpoint(large) {
    overflow: hidden;
  }
}

.small-headline-header:after, .small-headline-header:before {
  content: "";
  display: table
}

.small-headline-header_item {
  font-family: $font-secondary;
  font-weight: normal;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  margin: 0;
  //flex: 1;
  padding: 0 0.5rem;
  line-height: 40px;
  display: inline-block;
}

.small-headline-header_item.isActive {
  background: #D72530
}

.small-headline-slider {
  height: 350px;
  overflow: hidden;
  display: none;
  position: relative
}

.small-headline-slider:before {
  /*left: 0;
  bottom: 0;
  width: 100%;
  height: 37px;
  content: '';
  z-index: 2;
  background: #222;
  position: absolute;*/
}

.small-headline-slider.isActive {
  display: block
}

.small-headline-slider_item {
  position: relative;
  height: 313px;
  a > img {
    @extend .fill-content;
  }
  a.author-img {
    display: block;
    position: absolute;
    left: 0.5rem;
    top: 0.5rem;
    width: 90px;
    height: 90px;
    z-index: 1;
    overflow: hidden;
    border-radius: 50%;
    background-color: rgba(0,0,0,.2);
  }
}

.small-headline-slider .slick-dots {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  left: 0;
  z-index: 3;
  bottom: 0;
  height: 37px;
  background: #222;
  -webkit-box-shadow: inset 1px 0 0 #353535, inset -1px 0 0 #353535, inset 0 -1px 0 #353535;
  box-shadow: inset 1px 0 0 #353535, inset -1px 0 0 #353535, inset 0 -1px 0 #353535;
  @include breakpoint(small only) {
    display: none !important;
  }
}

.small-headline-slider .slick-dots li {
  flex: 1;
  width: auto;
  height: auto;
  margin: 0;
  text-align: center
}

.small-headline-slider .slick-dots li + li {
  -webkit-box-shadow: inset 1px 0 0 #353535;
  box-shadow: inset 1px 0 0 #353535
}

.small-headline-slider .slick-dots li a {
  width: auto;
  height: 37px;
  color: #B8B8B8;
  display: block;
  font-size: 13px;
  line-height: 37px;
}

.small-headline-slider .slick-dots li.slick-active a {
  color: #fff;
  background-color: #D8242F
}

.ie9 .small-headline-slider .slick-dots {
  z-index: 999
}

.small-headline-slider .slick-slide {
  text-align: center
}

.small-headline-slider .slick-slide img {
  display: inline-block
}

.small-headline-slider .slick-slide .title {
  //display: none
}

.small-headline.ekonomi .small-headline-header {
  border-top-color: #008eae
}

.small-headline.ekonomi .small-headline-header_item.isActive, .small-headline.ekonomi .small-headline-slider .slick-dots li.slick-active a {
  background-color: #008eae
}

.small-headline.spor .small-headline-header {
  border-top-color: #8fc249
}

.small-headline.spor .small-headline-header_item.isActive, .small-headline.spor .small-headline-slider .slick-dots li.slick-active a {
  background-color: #8fc249
}

.small-headline.dunya .small-headline-header {
  border-top-color: #FF7F00
}

.small-headline.dunya .small-headline-header_item.isActive, .small-headline.dunya .small-headline-slider .slick-dots li.slick-active a {
  background-color: #FF7F00
}

.small-headline-header_item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none
}
